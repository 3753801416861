import { inject, Injectable } from '@angular/core';

import { firstValueFrom, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { FirestoreOrga, FirestoreOrgaUser, OrgaUser, Timer, User } from 'commons';
import { FIRESTORE_SERVICE, ORGA_FIRESTORE_SERVICE } from './firestore.service';
import { where } from '@angular/fire/firestore';
import { SessionStateService } from './session-state.service';

// @ts-ignore
// noinspection JSUnusedLocalSymbols
const EMPTY_USER_SETTINGS: User = {
  firstName: '',
  lastName: '',
  //emailVerified: false,
  //autoChooseOrga: false
};

@Injectable({
  providedIn: 'root',
})
export class OrgaUserService {
  private readonly TIMER_PATH = 'timer/timer';

  private rootFirestoreService = inject(FIRESTORE_SERVICE);
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);
  private sessionState = inject(SessionStateService);

  getAllOrgaUsers(): Observable<FirestoreOrgaUser[]> {
    return this.orgaFirestoreService.getDocs<OrgaUser>('orgaUser');
  }

  getOrgaUserByUserId(userId: string): Observable<FirestoreOrgaUser> {
    return this.orgaFirestoreService
      .getDocs<OrgaUser>('orgaUser', where('userId', '==', userId))
      .pipe(
        tap((orgas) => console.log(orgas)),
        map((orgas) => orgas[0])
      );
  }

  async createOrgaUser(orgaUser: OrgaUser) {
    const loggedInOrga = await firstValueFrom(
      this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga))
    );
    const hydratedOrgaUser: OrgaUser = {
      ...orgaUser,
      orgaId: loggedInOrga.id,
      orgaName: loggedInOrga.data.name,
      email: orgaUser.email.toLowerCase(),
    };
    return this.orgaFirestoreService.createDoc<OrgaUser>('orgaUser', hydratedOrgaUser);
  }

  deleteOrgaUser(orgaUser: FirestoreOrgaUser) {
    return this.rootFirestoreService.deleteDoc(orgaUser.docRef.path);
  }

  getTimer(): Observable<Timer> {
    return this.sessionState
      .getOrgaUser()
      .pipe(filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser))
      .pipe(
        switchMap((orgaUser) =>
          this.orgaFirestoreService
            .getDoc<Timer>(`orgaUser/${orgaUser.id}/${this.TIMER_PATH}`)
            .pipe(map((firestoreTimer) => firestoreTimer.data))
        )
      );
  }

  async updateTimer(timer: Partial<Timer>) {
    const orgaUser = await firstValueFrom(
      this.sessionState
        .getOrgaUser()
        .pipe(filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser))
    );
    return this.orgaFirestoreService.updateDoc<Timer>(
      `orgaUser/${orgaUser.id}/${this.TIMER_PATH}`,
      timer
    );
  }
}
