<div class="bg-white overflow-hidden sm:rounded-b-md">
  <ul role="list" class="divide-y divide-gray-200" *ngIf="(documents?.length || 0) > 0; else empty">
    <ng-content></ng-content>
  </ul>
  <ng-template #empty>
    <div class="m-20 flex flex-col items-center text-sm text-gray-500 min-h-">
      Keine Dokumente vorhanden
    </div>
  </ng-template>
</div>
