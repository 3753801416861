import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { firstValueFrom, map, Observable, Subscription } from 'rxjs';
import { SlideOverService } from '../../../components/slide-overs/slide-over/slide-over-service';
import {
  ArchiveFolder,
  Category,
  Document,
  FirestoreCustomer,
  FirestoreDocument,
  FirestoreOrga,
} from 'commons';
import { CustomerService } from '../../../services/customer.service';
import { format, parseISO } from 'date-fns';
import { DocumentService } from '../../../services/document.service';
import { cloneDeep, toLower } from 'lodash';
import { SlideOverFooterComponent } from '../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { HistoryComponent } from '../../history/history.component';
import { CommentSectionComponent } from '../../comments/comment-section/comment-section.component';
import { HideWhenNotInPlanDirective } from '../../../directives/hide-when-not-in-plan.directive';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { CurrencyInputComponent } from '../../../components/input/currency-input.component';
import { SelectAllDirective } from '../../../directives/selectAll.directive';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { ToggleButtonComponent } from '../../../components/toggle-button-group/toggle-button/toggle-button.component';
import { ToggleButtonGroupComponent } from '../../../components/toggle-button-group/toggle-button-group.component';
import { InputComponent } from '../../../components/input/input.component';
import { TabOldDirective } from '../../../components/tabs/tab.directive';
import { TabsComponent } from '../../../components/tabs/tabs.component';
import { SlideOverContentComponent } from '../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { SlideOverHeaderComponent } from '../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CenterContentComponent } from '../../../components/slide-overs/slide-over/center-content/center-content.component';
import { SlideOverComponent } from '../../../components/slide-overs/slide-over/slide-over.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { filter } from 'rxjs/operators';
import { HideWhenNotInRoleDirective } from '../../../directives/hide-when-not-in-role.directive';

export const expenseRevenueValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const type = control.get('type')?.value;
  const amount = control.get('amount')?.value;
  const valueDate = control.get('valueDate')?.value;

  return type !== 'other' && !(amount && valueDate) ? { expenseRevenueWrong: true } : null;
};

@Injectable({
  providedIn: 'root',
})
export class DocumentSlideOverService extends SlideOverService<FirestoreDocument[], void, void> {}

@Component({
  selector: 'app-document-slide-over',
  templateUrl: './document-slide-over.component.html',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    SlideOverComponent,
    CenterContentComponent,
    PdfViewerModule,
    SlideOverHeaderComponent,
    NgIconWrapperComponent,
    SlideOverContentComponent,
    TabsComponent,
    TabOldDirective,
    InputComponent,
    ToggleButtonGroupComponent,
    ToggleButtonComponent,
    TailwindInputDirective,
    SelectAllDirective,
    CurrencyInputComponent,
    NgSelectModule,
    TailwindButtonDirective,
    HideWhenNotInPlanDirective,
    CommentSectionComponent,
    HistoryComponent,
    SlideOverFooterComponent,
    AsyncPipe,
    HideWhenNotInRoleDirective,
  ],
})
export class DocumentSlideOverComponent implements OnDestroy, OnInit {
  private subscription = new Subscription();
  title!: string;

  document!: FirestoreDocument;
  documentId!: string;
  downloadUrl!: string;
  documentForm!: FormGroup;

  archiveFolder$!: Observable<ArchiveFolder[]>;
  contentType?: string;
  customers$!: Observable<FirestoreCustomer[]>;

  categories!: Observable<Category[]>;

  numberOfDocuments = 0;
  currentDocumentIndex = 0;
  fireStoreDocuments: FirestoreDocument[] = [];
  isInternalInvoice = false;
  showPaymentFields = false;

  recognizedCompanyNotFound = false;
  recognizedCompanyFound = false;

  constructor(
    private documentService: DocumentService,
    private customerService: CustomerService,
    private sessionState: SessionStateService,
    public documentSlideOver: DocumentSlideOverService
  ) {
    this.categories = this.sessionState.getOrga().pipe(
      filter((orga): orga is FirestoreOrga => !!orga),
      map((orga) => orga.data.categories ?? {}),
      map((categories) => Object.values(categories))
    );

    this.customers$ = this.customerService.getAllCustomers();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async ngOnInit() {
    this.archiveFolder$ = this.documentService.getArchive().pipe(
      map((archive) =>
        Object.values(archive.data.folders)
          .filter((folder) => folder.active)
          .sort(
            (a, b) => archive.data.folderSort.indexOf(a.id) - archive.data.folderSort.indexOf(b.id)
          )
      )
    );

    this.fireStoreDocuments = this.documentSlideOver.getEntity();
    this.numberOfDocuments = this.fireStoreDocuments.length;
    await this.initDocument(this.fireStoreDocuments[this.currentDocumentIndex]);
  }

  async initDocument(fireStoreDocument: FirestoreDocument) {
    this.recognizedCompanyNotFound = false;
    this.recognizedCompanyFound = false;
    this.documentId = fireStoreDocument.id;
    this.document = cloneDeep(fireStoreDocument);
    try {
      this.downloadUrl = await this.documentService.getDocumentUrl(this.document.data);
      this.contentType = (
        await this.documentService.getDocumentMetaData(this.document.data)
      ).contentType;
    } catch (e) {
      console.error('storage object not found', e);
    }

    await this.initForm();
  }

  async initForm() {
    const archiveFolders = await firstValueFrom(this.archiveFolder$);

    const doc = this.document.data;

    const customer = Object.values(doc.linkedEntities || {}).find(
      (linkEntity) =>
        linkEntity.entity === 'customer-individual' || linkEntity.entity === 'customer-company'
    )?.id;

    this.documentForm = new FormGroup({
      type: new FormControl(doc.type, []),
      name: new FormControl(doc.name, [Validators.required, Validators.maxLength(500)]),
      comment: new FormControl(doc.comment, [Validators.maxLength(1000)]),
      customer: new FormControl(customer),
      documentDate: new FormControl(
        doc.documentDate ? format(parseISO(doc.documentDate), 'yyyy-MM-dd') : '',
        [Validators.required]
      ),
      accountRelevant: new FormControl(doc.accounting?.relevant, []),
      accountProcessed: new FormControl(doc.accounting?.processed, []),
      archived: new FormControl(doc.folder !== null, []),
      archiveFolder: new FormControl(doc.folder?.id ?? archiveFolders[0]?.id, []),
      amount: new FormControl('', [Validators.required]),
      valueDate: new FormControl('', [Validators.required]),
      financialYear: new FormControl('', [Validators.required]),
      paid: new FormControl('', []),
      category: new FormControl('', []),
    });

    if (doc.type === 'expense' || doc.type === 'revenue') {
      this.documentForm.patchValue({
        amount: doc.amountInCents ? doc.amountInCents / 100 : null,
        valueDate: doc.valueDate ? format(parseISO(doc.valueDate), 'yyyy-MM-dd') : null,
        financialYear: doc.financialYear ?? 2000,
        paid: doc.paid || false,
        category: doc.category || null,
      });
    }

    this.enableDisableFields();

    this.subscription.add(
      this.documentForm.get('type')?.valueChanges.subscribe(() => this.enableDisableFields())
    );
    this.documentForm
      .get('valueDate')
      ?.valueChanges.subscribe((value) =>
        this.documentForm.patchValue({ financialYear: new Date(value).getFullYear() })
      );

    const curatedValues = doc.documentAI?.curated;
    if (curatedValues && !doc.curatedValuesReviewed) {
      if (curatedValues.total_amount) {
        this.documentForm.patchValue({ type: 'expense', amount: curatedValues.total_amount });
      }
      if (curatedValues.due_date) {
        this.documentForm.patchValue({ type: 'expense', valueDate: curatedValues.due_date });
      }
      if (curatedValues.invoice_date) {
        this.documentForm.patchValue({ documentDate: curatedValues.invoice_date });
      }
      if (curatedValues.supplier_name) {
        const customers = await firstValueFrom(this.customerService.getAllCustomers());
        const supplierName = toLower(curatedValues.supplier_name);
        const filteredCustomer = customers.filter(
          (customer) =>
            supplierName.includes(toLower(customer.data.displayName)) ||
            toLower(customer.data.displayName).includes(supplierName)
        );

        if (filteredCustomer.length > 0) {
          this.documentForm.patchValue({ customer: filteredCustomer[0].id });
        } else {
          this.recognizedCompanyNotFound = true;
        }
      }
    }
  }

  enableDisableFields() {
    if (this.documentForm.get('type')?.value === 'other') {
      this.documentForm.get('type')?.enable({ emitEvent: false });
      this.documentForm.get('amount')?.disable();
      this.documentForm.get('valueDate')?.disable();
      this.documentForm.get('financialYear')?.disable();
      this.documentForm.get('paid')?.disable();
      this.documentForm.get('category')?.disable();
      this.showPaymentFields = false;
      this.isInternalInvoice = false;
      this.documentForm.patchValue({ accountRelevant: this.document.data.accounting.relevant });
    } else {
      this.documentForm.get('type')?.enable({ emitEvent: false });
      this.documentForm.get('amount')?.enable();
      this.documentForm.get('valueDate')?.enable();
      this.documentForm.get('financialYear')?.enable();
      this.documentForm.get('paid')?.enable();
      this.documentForm.get('category')?.enable();
      this.documentForm.patchValue({ accountRelevant: true });

      this.showPaymentFields = true;
      this.isInternalInvoice = false;
    }
    if (this.document.data.type === 'revenue' && this.document.data.internalInvoice) {
      this.documentForm.get('type')?.disable({ emitEvent: false });
      this.documentForm.get('amount')?.disable();
      this.documentForm.get('valueDate')?.disable();
      this.documentForm.get('financialYear')?.disable();
      this.documentForm.get('paid')?.disable();
      this.documentForm.get('category')?.disable();
      this.documentForm.get('customer')?.disable();
      this.showPaymentFields = true;
      this.isInternalInvoice = true;
    }
  }

  async update() {
    this.documentForm.markAllAsTouched();
    if (!this.documentForm.valid) {
      return;
    }

    const value = this.documentForm.value;
    const archiveFolders = await firstValueFrom(this.archiveFolder$);
    //remove comments from updating
    const { comments, ...updateDocument }: Document = { ...this.document.data, type: value.type };

    if (
      !this.isInternalInvoice &&
      (updateDocument.type === 'expense' || updateDocument.type === 'revenue')
    ) {
      updateDocument.amountInCents = Math.round(value.amount * 100);
      updateDocument.valueDate = value.valueDate;
      updateDocument.financialYear = value.financialYear;
      updateDocument.paid = value.paid ?? false;
      updateDocument.category = value.category ?? null;
    }

    if (this.isInternalInvoice) {
      updateDocument.type = 'revenue';
    }

    const linkedEntities = Object.values(this.document.data.linkedEntities || {}).filter(
      (linkedEntity) =>
        linkedEntity.entity !== 'customer-company' && linkedEntity.entity !== 'customer-individual'
    );
    const customer = (await firstValueFrom(this.customers$)).find(
      (customer) => customer.id === value.customer
    );
    if (customer) {
      linkedEntities.push({
        entity: customer.data.type === 'company' ? 'customer-company' : 'customer-individual',
        id: customer.id,
        name: customer.data.displayName,
      });
    }

    console.log(updateDocument);
    this.documentService
      .updateDocument(this.documentId, {
        ...updateDocument,
        name: value.name,
        comment: value.comment,
        documentDate: value.documentDate,
        accounting: {
          relevant: !!value.accountRelevant,
          processed: !!value.accountRelevant && !!value.accountProcessed,
        },
        linkedEntities: linkedEntities.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),

        folder: value.archived
          ? {
              entity: 'folder',
              id: value.archiveFolder,
              name: archiveFolders[archiveFolders.findIndex((x) => x.id === value.archiveFolder)]
                .name,
            }
          : null,
        curatedValuesReviewed: true,
      })
      .then(() => {
        if (this.currentDocumentIndex === this.numberOfDocuments - 1) {
          this.documentSlideOver.closeSlideOver();
        } else {
          this.currentDocumentIndex++;
          this.initDocument(this.fireStoreDocuments[this.currentDocumentIndex]);
        }
      });
  }

  async createCustomer(customerName: string) {
    const docRef = await this.customerService.createCustomer({
      type: 'company',
      displayName: customerName,
      company: {
        displayName: customerName,
        legalName: customerName,
      },
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        countryCode: 'CH',
        zipCode: '',
      },
    });
    const newCustomer = await firstValueFrom(this.customerService.getCustomer(docRef.id));
    this.documentForm.patchValue({ customer: newCustomer.id });

    this.recognizedCompanyNotFound = false;
  }

  closeSlideOver() {
    this.documentSlideOver.closeSlideOver();
  }

  deleteDocument() {
    this.documentService.deleteDocument(this.document);
    this.closeSlideOver();
  }

  downloadDocument() {
    this.documentService.downloadDocument(this.document);
  }
}
