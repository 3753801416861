// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  useEmulators: false,
  production: false,
  firebase: {
    apiKey: 'AIzaSyA60NS7LPT8jFZglFrHq9LvnrHCFYw5osI',
    authDomain: 'jai-dev-steph.firebaseapp.com',
    databaseURL: 'https://jai-dev-steph.firebaseio.com',
    projectId: 'jai-dev-steph',
    storageBucket: 'jai-dev-steph.appspot.com',
    messagingSenderId: '2343144490',
    appId: '1:2343144490:web:d3739a4f9eba269c8c8753',
    measurementId: 'G-DJL4GNY5LL',
  },
  appCheckSiteKey: '6Lf4ThgpAAAAALmzdC9tS_D_UfwdON2jZDng7OS0',
  googleMapsApiKey: 'AIzaSyDfHvt8Zp8Gra2q9CLn2fK47KetQVnVo08',
  productLinks: {
    starter: 'https://buy.stripe.com/test_8wM5mmfij7RQc3m8wy',
    business: {
      '1': 'https://buy.stripe.com/test_5kA3ee6LNege1oI4gj',
      '2': 'https://buy.stripe.com/test_aEUeWWc671tsd7q28g',
      '3': 'https://buy.stripe.com/test_bIYcOO7PR2xw7N6cMV',
    },
    enterprise: {
      '5': 'https://buy.stripe.com/test_5kA4iifij7RQebu5ko',
      '6': 'https://buy.stripe.com/test_3cs166gmn0po0kE5kp',
      '7': 'https://buy.stripe.com/test_fZeaGG3zB5JIebu3ci',
      '8': 'https://buy.stripe.com/test_14kg104DF9ZY8RaeV1',
    },
  },
  customerPortalLink: 'https://billing.stripe.com/p/login/test_fZecNp1Ny0Lc7eg6oo',
};

export const actionCodeSettings = {
  url: 'http://localhost:4200/user/login/emaillandingpage',
  handleCodeInApp: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
