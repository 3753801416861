<ng-container *ngIf="documents$ | async as documents">
  <div
    class="bg-white shadow overflow-hidden sm:rounded-md"
    *ngIf="documents.length > 0; else noDocuments">
    <app-document-list [documents]="documents">
      <li
        *ngFor="let document of documents; let last = last"
        app-document-list-item
        [id]="document.id"
        [name]="document.data.name"
        [documentDate]="document.data.documentDate!"
        [accounting]="document.data.accounting.relevant"
        [accountingProcessed]="document.data.accounting.processed"
        [archiveFolder]="document.data.folder"
        (editDocument)="editDocument(document)"></li>
    </app-document-list>
  </div>
  <ng-template #noDocuments>
    <app-empty message="Noch keine Dokumente mit diesem Projekt verknüpft"></app-empty>
  </ng-template>
</ng-container>
