<div class="overflow-hidden rounded-lg bg-white shadow">
  <div class="flex flex-1">
    <div class="flex flex-col w-full gap-4 px-4 pt-5 pb-1 pr-2 sm:p-6 sm:pb-2 sm:pr-2">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <div class="flex flex-wrap gap-x-2">
            <p class="font-bold text-gray-900">{{ position.name }}</p>
            <div class="flex gap-1">
              <app-position-type-badge
                [positionType]="position.positionType"></app-position-type-badge>
              <span
                *ngIf="!position.billable"
                class="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-800">
                Nicht verrechenbar
              </span>
            </div>
          </div>
          <div class="mt-1 flex gap-2" *ngIf="position.positionType === 'timeAndMaterial'">
            <div class="flex text-sm text-gray-500" *ngIf="position.timeAndMaterial.hourlyRate">
              <app-ng-icon class="text-sm" name="heroCurrencyDollarMini"></app-ng-icon>
              {{ position.timeAndMaterial.hourlyRate | number: '1.2-2' : 'de-CH' || '-' }}
            </div>
            <div
              class="flex items-center text-sm text-gray-500"
              *ngIf="position.timeAndMaterial.expectedWorkMinutes">
              <app-ng-icon class="text-sm" name="heroClockMini"></app-ng-icon>
              {{ (position.timeAndMaterial.expectedWorkMinutes | formatWork) || '-' }}
            </div>
          </div>
        </div>
        <div
          class="flex shrink-0 flex-col text-right"
          *ngIf="position.positionType === 'timeAndMaterial'">
          <div class="font-bold">{{ position.totals.totalWorkMinutes | formatWork }} h</div>
          <div class="text-xs text-gray-500">{{ totalWork | number: '1.2-2' : 'de-CH' }} CHF</div>
        </div>
        <div
          class="flex shrink-0 flex-col text-right"
          *ngIf="position.positionType === 'fixedPrice'">
          <div class="font-bold">
            {{ position.fixedPrice.amount | number: '1.2-2' : 'de-CH' }} CHF
          </div>
          <div class="text-xs text-gray-500">
            {{ position.totals.totalWorkMinutes | formatWork }} h
          </div>
        </div>
      </div>
      <p class="text-gray-700 whitespace-pre-wrap" [innerHTML]="position.description"></p>

      <div class="flex justify-between">
        <div class="flex items-center text-sm text-gray-500">
          <div *ngIf="false">
            <!--            <icon-user-sm></icon-user-sm>-->
            todo usernames...
          </div>
        </div>
        <a
          appTailwindButton
          color="link"
          [routerLink]="['/project/details/', projectId, 'activity']"
          [queryParams]="{ positionId: position.id }"
          [relativeTo]="null"
          >Stunden</a
        >
      </div>
    </div>

    <app-dropdown-minimal-menu>
      <app-dropdown-button>
        <app-ng-icon
          name="heroEllipsisVerticalSolid"
          class="text-3xl"
          strokeWidth="3"></app-ng-icon>
      </app-dropdown-button>
      <app-dropdown-item (menuClick)="modifyPosition.emit(position)">
        Bearbeiten
      </app-dropdown-item>
      <app-dropdown-item (menuClick)="createWork.emit(position)">
        Neue Arbeit erfassen
      </app-dropdown-item>
    </app-dropdown-minimal-menu>
  </div>
</div>
