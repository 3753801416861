import { Component, Input, OnChanges } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import { Entities, FirestoreUser, getEntityDisplayName, HistoryEventType } from 'commons';
import { HistoryService } from '../../services/history.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { SessionStateService } from '../../services/session-state.service';

interface HistoryView {
  entity: Entities;
  type: HistoryEventType;
  timestamp: string;
  user: string;
  entityDisplayName: string;
  userInitials: string;
  userId: string;
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  standalone: true,
  imports: [NgIf, NgClass, NgFor, NgSwitch, NgSwitchCase, AsyncPipe, DatePipe],
})
export class HistoryComponent implements OnChanges {
  @Input()
  forId!: string;

  @Input() set noCard(value: any) {
    this._noCard = coerceBooleanProperty(value);
  }

  _noCard = false;

  history$!: Observable<HistoryView[]>;
  loggedInUserId!: string;

  constructor(
    private historyService: HistoryService,
    private sessionState: SessionStateService
  ) {}

  async ngOnChanges() {
    this.loggedInUserId = await firstValueFrom(
      this.sessionState.getUser().pipe(
        filter((user): user is FirestoreUser => !!user),
        map((user) => user.id)
      )
    );
    this.history$ = this.historyService.getHistory(this.forId).pipe(
      map((historyEntries) =>
        historyEntries.sort(
          (a, b) => new Date(b.data.timestamp).getTime() - new Date(a.data.timestamp).getTime()
        )
      ),
      map((historyEntries) => {
        return historyEntries.map((he) => {
          let entityDisplayName;

          switch (he.data.eventType) {
            case 'update':
            case 'create':
              entityDisplayName = getEntityDisplayName(he.data.after, he.data.entity);
              break;
            case 'delete':
              entityDisplayName = getEntityDisplayName(he.data.before, he.data.entity);
              break;
          }

          return {
            entity: he.data.entity,
            type: he.data.eventType,
            timestamp: he.data.timestamp,
            user: he.data.lastEditor.userDisplayName,
            entityDisplayName,
            userInitials: he.data.lastEditor.userInitials,
            userId: he.data.lastEditor.userId,
          };
        });
      })
    );
  }
}
