<div>
  <label class="block text-sm font-medium text-gray-700">{{ label }}</label>
  <div class="mt-1 relative rounded-md shadow-sm">
    <ng-content></ng-content>
    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <span class="text-gray-500 sm:text-sm" id="price-currency"> CHF </span>
    </div>
    <div
      class="pointer-events-none absolute inset-y-0 right-0 -mr-[22px] flex items-center"
      *ngIf="hasErrors">
      <app-ng-icon class="text-red-500 text-lg" name="heroExclamationCircleSolid"></app-ng-icon>
    </div>
    <div
      class="pointer-events-none absolute inset-y-0 right-0 -mr-[22px] flex items-center text-blue-600"
      *ngIf="enhanced && !hasErrors">
      <app-ng-icon class="text-blue-600" name="heroSparkles"></app-ng-icon>
    </div>
  </div>
</div>
