import { inject, Injectable } from '@angular/core';
import { FirestoreProject, FirestoreUser, Position, Project } from 'commons';
import { firstValueFrom, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { flattenObject } from './helper';
import { LastEditorService } from './last-editor.service';
import { ORGA_FIRESTORE_SERVICE } from './firestore.service';
import { deleteField, where } from '@angular/fire/firestore';
import { SessionStateService } from './session-state.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);
  private sessionState = inject(SessionStateService);
  private lastEditor = inject(LastEditorService);

  getProject(projectId: string): Observable<FirestoreProject> {
    return this.orgaFirestoreService.getDoc<Project>(`project/${projectId}`);
  }

  getActiveProjects(): Observable<FirestoreProject[]> {
    return this.orgaFirestoreService.getDocs<Project>('project', where('isActive', '==', true));
  }

  getInactiveProjects(): Observable<FirestoreProject[]> {
    return this.orgaFirestoreService.getDocs<Project>('project', where('isActive', '==', false));
  }

  getAllProjects(): Observable<FirestoreProject[]> {
    return this.orgaFirestoreService.getDocs<Project>('project');
  }

  getAllProjectsForCustomer(customerId: string): Observable<FirestoreProject[]> {
    return this.orgaFirestoreService.getDocs<Project>(
      'project',
      where('customerId', '==', customerId)
    );
  }

  async createProject(project: Partial<Project>) {
    return this.orgaFirestoreService.createDoc('project', await this.lastEditor.hydrate(project));
  }

  async updateProject(projectId: string, project: Partial<Project>) {
    return this.orgaFirestoreService.updateDoc<Project>(
      `project/${projectId}`,
      await this.lastEditor.hydrate(project)
    );
  }

  async createPosition(projectId: string, position: Position) {
    const positionPath = `positions.${position.id}`;
    return this.orgaFirestoreService.updateDoc<Project>(
      `project/${projectId}`,
      // @ts-ignore
      await this.lastEditor.hydrate({
        [positionPath]: position,
      })
    );
  }

  async updatePosition(
    projectId: string,
    positionId: string,
    position: Partial<Position>
  ): Promise<void> {
    const positionPath = `positions.${positionId}`;
    const flattenPosition = flattenObject(position, positionPath);
    return this.orgaFirestoreService.updateDoc<Project>(
      `project/${projectId}`,
      // @ts-ignore
      await this.lastEditor.hydrate(flattenPosition)
    );
  }

  async toggleFavorite(project: FirestoreProject, activated: boolean) {
    const userId = (
      await firstValueFrom(
        this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user))
      )
    ).id;
    // @ts-ignore
    await this.updateProject(project.id, {
      [`favorite.${userId}`]: activated ? true : deleteField(),
    });
  }

  getAllBillablePositions(projectId: string): Observable<Position[]> {
    return this.orgaFirestoreService.getDoc<Project>(`project/${projectId}`).pipe(
      map((project) => (project.data.positions ? Object.values(project.data.positions) : [])),
      map((positions) => positions.filter((pos) => pos.billable))
    );
  }
}
