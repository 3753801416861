<app-settings-card heading="Bankverbindung" (save)="saveSettings()" [disabled]="disabled">
  <form [formGroup]="bankDetailForm">
    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-6">
        <app-input label="Name der Bank">
          <input type="text" name="name" appTailwindInput [formControlName]="'name'" />
        </app-input>
      </div>
      <div class="sm:col-span-6">
        <app-input label="IBAN Nummer">
          <input type="text" name="name" appTailwindInput [formControlName]="'iban'" />
        </app-input>
      </div>
      <div class="sm:col-span-1">
        <app-input label="Plz">
          <input type="text" name="name" appTailwindInput [formControlName]="'zipCode'" />
        </app-input>
      </div>
      <div class="sm:col-span-5">
        <app-input label="Ort">
          <input type="text" name="name" appTailwindInput [formControlName]="'city'" />
        </app-input>
      </div>
    </div>
  </form>
</app-settings-card>
