import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { FirestoreOrgaUser, OrgaUserPermission } from 'commons';
import { firstValueFrom } from 'rxjs';
import { SessionStateService } from '../services/session-state.service';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[appHideWhenNotInRole]',
  standalone: true,
})
export class HideWhenNotInRoleDirective implements OnChanges {
  @Input('appHideWhenNotInRole')
  rolesInWhichTheDirectiveShowsTheHost: OrgaUserPermission[] = [];
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sessionState: SessionStateService
  ) {}

  private async shouldDisplay(roles: OrgaUserPermission[]) {
    const orgaUser = await firstValueFrom(
      this.sessionState
        .getOrgaUser()
        .pipe(filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser))
    );
    return this.rolesInWhichTheDirectiveShowsTheHost.includes(orgaUser.data.role);
  }

  async ngOnChanges() {
    if (await this.shouldDisplay(this.rolesInWhichTheDirectiveShowsTheHost)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
