import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FirestoreInvoice } from 'commons';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { InvoiceListEntryComponent } from './invoice-list-entry/invoice-list-entry.component';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-invoices',
    templateUrl: './invoices.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        InvoiceListEntryComponent,
        NgClass,
        EmptyComponent,
    ],
})
export class InvoicesComponent {
  @Input()
  invoices: FirestoreInvoice[] = [];
  @Output()
  editInvoice = new EventEmitter<FirestoreInvoice>();
  @Output()
  deleteInvoice = new EventEmitter<FirestoreInvoice>();
  @Output()
  downloadInvoice = new EventEmitter<FirestoreInvoice>();
}
