<td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
  {{ projectName }}
</td>
<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
  {{ totalMinutes | formatMinutes }}
</td>
<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
  {{ billableMinutes | formatMinutes }}
</td>
<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
  {{ unbillableMinutes | formatMinutes }}
</td>
