import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { map, Observable } from 'rxjs';
import { CustomerService } from '../../services/customer.service';
import { CustomerType, FirestoreCustomer } from 'commons';
import { CustomerSlideOverService } from './customer-slide-over/customer-slide-over.component';
import { EmptyComponent } from '../../components/empty/empty.component';
import { CustomerListEntryComponent } from './customer-list-entry/customer-list-entry.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { DropdownItemComponent } from '../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { TailwindButtonDirective } from '../../components/button/tailwind-button.directive';
import { DropdownButtonComponent } from '../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { HeaderMetaActionComponent } from '../../components/container/header-meta-action/header-meta-action.component';
import { ContainerComponent } from '../../components/container/container.component';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    standalone: true,
    imports: [
        ContainerComponent,
        HeaderMetaActionComponent,
        DropdownMinimalMenuComponent,
        DropdownButtonComponent,
        TailwindButtonDirective,
        DropdownItemComponent,
        NgIf,
        NgFor,
        CustomerListEntryComponent,
        NgClass,
        EmptyComponent,
        AsyncPipe,
    ],
})
export class CustomersComponent {
  customers$: Observable<FirestoreCustomer[]>;

  constructor(
    private customerService: CustomerService,
    private router: Router,
    private customerSlideOverService: CustomerSlideOverService
  ) {
    this.customers$ = this.customerService
      .getAllCustomers()
      .pipe(
        map((customers) =>
          customers.sort((a, b) => a.data.displayName.localeCompare(b.data.displayName))
        )
      );
  }

  createCustomer(type: CustomerType): void {
    this.customerSlideOverService.new({ customerType: type });
  }

  viewCustomer(customer: FirestoreCustomer) {
    this.router.navigate(['/customer/view', { customerId: customer.id }]);
  }

  editCustomer(customer: FirestoreCustomer) {
    this.customerSlideOverService.edit(customer);
  }
}
