import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { addMonths, addYears, isThisMonth, isThisYear, startOfMonth, startOfYear } from 'date-fns';
import { formatDate, NgClass, NgIf } from '@angular/common';
import { DateRange } from 'commons';
import { DropdownItemComponent } from '../dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { DropdownButtonComponent } from '../dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../dropdown-minimal-menu/dropdown-minimal-menu.component';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
    selector: 'app-date-filter-pill',
    templateUrl: './date-filter-pill.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgIconWrapperComponent,
        DropdownMinimalMenuComponent,
        DropdownButtonComponent,
        DropdownItemComponent,
    ],
})
export class DateFilterPillComponent implements OnInit, OnChanges {
  @Input() filter: DateRange = {
    type: 'notSet',
  };

  @Output() filterChanged = new EventEmitter<DateRange>();

  //currentFilter!: DateFilter;
  isTodayInCurrentPeriod = false;
  displayText = '';

  currentPeriodButtonText = '';

  constructor(@Inject(LOCALE_ID) private locale: string, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.setupFilter();
  }

  ngOnChanges() {
    this.setupFilter();
  }

  setupFilter() {
    switch (this.filter.type) {
      case 'notSet':
        break;
      case 'year':
        this.isTodayInCurrentPeriod = isThisYear(this.filter.year);
        this.displayText = formatDate(this.filter.year, 'yyyy', this.locale);
        this.currentPeriodButtonText = 'zum aktuellen Jahr';
        break;
      case 'month':
        this.isTodayInCurrentPeriod = isThisMonth(this.filter.month);
        this.displayText = formatDate(this.filter.month, 'MMMM yyyy', this.locale);
        this.currentPeriodButtonText = 'zum aktuellen Monat';
        break;
    }
    this.cdr.markForCheck();
  }

  increment() {
    this.changeInterval(1);
  }

  decrement() {
    this.changeInterval(-1);
  }

  changeInterval(amount: number) {
    switch (this.filter.type) {
      case 'month':
        this.filterChanged.emit({
          type: this.filter.type,
          month: addMonths(this.filter.month, amount),
        });
        break;
      case 'year':
        this.filterChanged.emit({
          type: this.filter.type,
          year: addYears(this.filter.year, amount),
        });
        break;
    }
  }

  enableFilter(filterType: 'month' | 'year') {
    switch (filterType) {
      case 'month':
        this.filterChanged.emit({
          type: filterType,
          month: startOfMonth(new Date()),
        });
        break;
      case 'year':
        this.filterChanged.emit({
          type: filterType,
          year: startOfYear(new Date()),
        });
        break;
    }
  }

  resetFilter() {
    this.filterChanged.emit({
      type: 'notSet',
    });
  }

  goToCurrentPeriod() {
    switch (this.filter.type) {
      case 'month':
        this.filterChanged.emit({
          type: this.filter.type,
          month: startOfMonth(new Date()),
        });
        break;
      case 'year':
        this.filterChanged.emit({
          type: this.filter.type,
          year: startOfYear(new Date()),
        });
        break;
    }
  }
}
