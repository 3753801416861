import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, Injectable, Optional, Inject, NgModule } from '@angular/core';
import * as i2 from '@angular/fire';
import { VERSION } from '@angular/fire';
import * as i1 from '@angular/fire/compat';
import { ɵcacheInstance as _cacheInstance, ɵlazySDKProxy as _lazySDKProxy, ɵapplyMixins as _applyMixins } from '@angular/fire/compat';
import { isSupported } from 'firebase/analytics';
import { of, EMPTY } from 'rxjs';
import { observeOn, switchMap, map, shareReplay } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import { ɵscreenViewEvent as _screenViewEvent } from '@angular/fire/analytics';
import * as i3 from '@angular/platform-browser';
import * as i2$2 from '@angular/router';
import * as i2$1 from '@angular/fire/compat/auth';

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
// Export a null object with the same keys as firebase/compat/analytics, so Proxy can work with proxy-polyfill in Internet Explorer
const proxyPolyfillCompat = {
  app: null,
  logEvent: null,
  setCurrentScreen: null,
  setUserId: null,
  setUserProperties: null,
  setAnalyticsCollectionEnabled: null
};
const COLLECTION_ENABLED = new InjectionToken('angularfire2.analytics.analyticsCollectionEnabled');
const APP_VERSION = new InjectionToken('angularfire2.analytics.appVersion');
const APP_NAME = new InjectionToken('angularfire2.analytics.appName');
const DEBUG_MODE = new InjectionToken('angularfire2.analytics.debugMode');
const CONFIG = new InjectionToken('angularfire2.analytics.config');
const APP_NAME_KEY = 'app_name';
const APP_VERSION_KEY = 'app_version';
const DEBUG_MODE_KEY = 'debug_mode';
const GTAG_CONFIG_COMMAND = 'config';
const GTAG_FUNCTION_NAME = 'gtag'; // TODO rename these
const DATA_LAYER_NAME = 'dataLayer';
const SEND_TO_KEY = 'send_to';
class AngularFireAnalytics {
  measurementId;
  analyticsInitialized = new Promise(() => undefined);
  async updateConfig(config) {
    await this.analyticsInitialized;
    window[GTAG_FUNCTION_NAME](GTAG_CONFIG_COMMAND, this.measurementId, {
      ...config,
      update: true
    });
  }
  constructor(app, analyticsCollectionEnabled, providedAppVersion, providedAppName, debugModeEnabled, providedConfig,
  // eslint-disable-next-line @typescript-eslint/ban-types
  platformId, zone, schedulers) {
    if (isPlatformBrowser(platformId)) {
      window[DATA_LAYER_NAME] = window[DATA_LAYER_NAME] || [];
      // It turns out we can't rely on the measurementId in the Firebase config JSON
      // this identifier is not stable. firebase/analytics does a call to get a fresh value
      // falling back on the one in the config. Rather than do that ourselves we should listen
      // on our gtag function for a analytics config command
      // e.g, ['config', measurementId, { origin: 'firebase', firebase_id }]
      const parseMeasurementId = (...args) => {
        if (args[0] === 'config' && args[2].origin === 'firebase') {
          this.measurementId = args[1];
          return true;
        } else {
          return false;
        }
      };
      const patchGtag = fn => {
        window[GTAG_FUNCTION_NAME] = (...args) => {
          if (fn) {
            fn(...args);
          }
          // Inject app_name and app_version into events
          // TODO(jamesdaniels): I'm doing this as documented but it's still not
          //   showing up in the console. Investigate. Guessing it's just part of the
          //   whole GA4 transition mess.
          if (args[0] === 'event' && args[2][SEND_TO_KEY] === this.measurementId) {
            if (providedAppName) {
              args[2][APP_NAME_KEY] = providedAppName;
            }
            if (providedAppVersion) {
              args[2][APP_VERSION_KEY] = providedAppVersion;
            }
          }
          if (debugModeEnabled && typeof console !== 'undefined') {
            // eslint-disable-next-line no-console
            console.info(...args);
          }
          /**
           * According to the gtag documentation, this function that defines a custom data layer cannot be
           * an arrow function because 'arguments' is not an array. It is actually an object that behaves
           * like an array and contains more information then just indexes. Transforming this into arrow function
           * caused issue #2505 where analytics no longer sent any data.
           */
          (function (..._args) {
            window[DATA_LAYER_NAME].push(arguments);
          })(...args);
        };
      };
      // Unclear if we still need to but I was running into config/events I passed
      // to gtag before ['js' timestamp] weren't getting parsed, so let's make a promise
      // that resolves when firebase/analytics has configured gtag.js that we wait on
      // before sending anything
      const firebaseAnalyticsAlreadyInitialized = window[DATA_LAYER_NAME].some(parseMeasurementId);
      if (firebaseAnalyticsAlreadyInitialized) {
        this.analyticsInitialized = Promise.resolve();
        patchGtag();
      } else {
        this.analyticsInitialized = new Promise(resolve => {
          patchGtag((...args) => {
            if (parseMeasurementId(...args)) {
              resolve();
            }
          });
        });
      }
      if (providedConfig) {
        this.updateConfig(providedConfig);
      }
      if (debugModeEnabled) {
        this.updateConfig({
          [DEBUG_MODE_KEY]: 1
        });
      }
    } else {
      this.analyticsInitialized = Promise.resolve();
    }
    const analytics = of(undefined).pipe(observeOn(schedulers.outsideAngular), switchMap(isSupported), switchMap(supported => supported ? zone.runOutsideAngular(() => import('firebase/compat/analytics')) : EMPTY), map(() => {
      return _cacheInstance(`analytics`, 'AngularFireAnalytics', app.name, () => {
        const analytics = app.analytics();
        if (analyticsCollectionEnabled === false) {
          analytics.setAnalyticsCollectionEnabled(false);
        }
        return analytics;
      }, [app, analyticsCollectionEnabled, providedConfig, debugModeEnabled]);
    }), shareReplay({
      bufferSize: 1,
      refCount: false
    }));
    return _lazySDKProxy(this, analytics, zone);
  }
  static ɵfac = function AngularFireAnalytics_Factory(t) {
    return new (t || AngularFireAnalytics)(i0.ɵɵinject(i1.FirebaseApp), i0.ɵɵinject(COLLECTION_ENABLED, 8), i0.ɵɵinject(APP_VERSION, 8), i0.ɵɵinject(APP_NAME, 8), i0.ɵɵinject(DEBUG_MODE, 8), i0.ɵɵinject(CONFIG, 8), i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i2.ɵAngularFireSchedulers));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AngularFireAnalytics,
    factory: AngularFireAnalytics.ɵfac,
    providedIn: 'any'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularFireAnalytics, [{
    type: Injectable,
    args: [{
      providedIn: 'any'
    }]
  }], () => [{
    type: i1.FirebaseApp
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [COLLECTION_ENABLED]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [APP_VERSION]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [APP_NAME]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [DEBUG_MODE]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [CONFIG]
    }]
  }, {
    type: Object,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.NgZone
  }, {
    type: i2.ɵAngularFireSchedulers
  }], null);
})();
_applyMixins(AngularFireAnalytics, [proxyPolyfillCompat]);
class UserTrackingService {
  initialized;
  disposables = [];
  // TODO a user properties injector
  constructor(analytics,
  // eslint-disable-next-line @typescript-eslint/ban-types
  platformId, auth, zone) {
    firebase.registerVersion('angularfire', VERSION.full, 'compat-user-tracking');
    if (!isPlatformServer(platformId)) {
      let resolveInitialized;
      this.initialized = zone.runOutsideAngular(() => new Promise(resolve => resolveInitialized = resolve));
      this.disposables = [auth.authState.subscribe(user => {
        analytics.setUserId(user?.uid);
        resolveInitialized();
      }), auth.credential.subscribe(credential => {
        if (credential) {
          const method = credential.user.isAnonymous ? 'anonymous' : credential.additionalUserInfo.providerId;
          if (credential.additionalUserInfo.isNewUser) {
            analytics.logEvent('sign_up', {
              method
            });
          }
          analytics.logEvent('login', {
            method
          });
        }
      })];
    } else {
      this.initialized = Promise.resolve();
    }
  }
  ngOnDestroy() {
    this.disposables.forEach(it => it.unsubscribe());
  }
  static ɵfac = function UserTrackingService_Factory(t) {
    return new (t || UserTrackingService)(i0.ɵɵinject(AngularFireAnalytics), i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(i2$1.AngularFireAuth), i0.ɵɵinject(i0.NgZone));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: UserTrackingService,
    factory: UserTrackingService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserTrackingService, [{
    type: Injectable
  }], () => [{
    type: AngularFireAnalytics
  }, {
    type: Object,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i2$1.AngularFireAuth
  }, {
    type: i0.NgZone
  }], null);
})();
const SCREEN_VIEW_EVENT = 'screen_view';
class ScreenTrackingService {
  disposable;
  constructor(analytics, router, title, componentFactoryResolver, zone, userTrackingService) {
    firebase.registerVersion('angularfire', VERSION.full, 'compat-screen-tracking');
    if (!router || !analytics) {
      return this;
    }
    zone.runOutsideAngular(() => {
      this.disposable = _screenViewEvent(router, title, componentFactoryResolver).pipe(switchMap(async params => {
        if (userTrackingService) {
          await userTrackingService.initialized;
        }
        return await analytics.logEvent(SCREEN_VIEW_EVENT, params);
      })).subscribe();
    });
  }
  ngOnDestroy() {
    if (this.disposable) {
      this.disposable.unsubscribe();
    }
  }
  static ɵfac = function ScreenTrackingService_Factory(t) {
    return new (t || ScreenTrackingService)(i0.ɵɵinject(AngularFireAnalytics), i0.ɵɵinject(i2$2.Router, 8), i0.ɵɵinject(i3.Title, 8), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(UserTrackingService, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ScreenTrackingService,
    factory: ScreenTrackingService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScreenTrackingService, [{
    type: Injectable
  }], () => [{
    type: AngularFireAnalytics
  }, {
    type: i2$2.Router,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i3.Title,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i0.ComponentFactoryResolver
  }, {
    type: i0.NgZone
  }, {
    type: UserTrackingService,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
class AngularFireAnalyticsModule {
  constructor(analytics,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  screenTracking,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userTracking) {
    firebase.registerVersion('angularfire', VERSION.full, 'analytics-compat');
    // calling anything on analytics will eagerly load the SDK
    analytics.app.then(() => undefined);
  }
  static ɵfac = function AngularFireAnalyticsModule_Factory(t) {
    return new (t || AngularFireAnalyticsModule)(i0.ɵɵinject(AngularFireAnalytics), i0.ɵɵinject(ScreenTrackingService, 8), i0.ɵɵinject(UserTrackingService, 8));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AngularFireAnalyticsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [AngularFireAnalytics]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularFireAnalyticsModule, [{
    type: NgModule,
    args: [{
      providers: [AngularFireAnalytics]
    }]
  }], () => [{
    type: AngularFireAnalytics
  }, {
    type: ScreenTrackingService,
    decorators: [{
      type: Optional
    }]
  }, {
    type: UserTrackingService,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { APP_NAME, APP_VERSION, AngularFireAnalytics, AngularFireAnalyticsModule, COLLECTION_ENABLED, CONFIG, DEBUG_MODE, ScreenTrackingService, UserTrackingService };
