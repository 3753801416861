import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TailwindInputDirective } from '../input/tailwind-input.directive';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-editable-label',
    templateUrl: './editable-label.component.html',
    styleUrls: ['./editable-label.component.scss'],
    standalone: true,
    imports: [NgIf, TailwindInputDirective],
})
export class EditableLabelComponent {
  @ViewChild('focusable')
  textField!: ElementRef;

  @Input()
  labelText!: string;

  @Output()
  labelChanged = new EventEmitter<string>();

  isEditable = false;

  edit() {
    this.isEditable = true;
    setTimeout(() => {
      this.textField.nativeElement.focus();
      this.textField.nativeElement.select();
    }, 10);
  }

  unedit() {
    this.isEditable = false;
  }

  updateLabelText($event: Event) {
    // @ts-ignore
    this.labelText = $event.target.value;
    // @ts-ignore
    this.labelChanged.emit($event.target.value);
  }
}
