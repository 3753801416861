<div class="flex">
  <div
    class="p-4 sm:py-6 flex-grow block focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
    <div class="flex items-center gap-4">
      <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <div class="text-sm leading-5 font-medium text-blue-600 truncate">
            #{{ invoice.data.invoiceNumber }}
            <span class="ml-1 font-normal text-gray-500">
              {{ invoice.data.customerDisplayName }}
            </span>
          </div>
        </div>
        <div>
          <div
            class="text-sm leading-5 font-medium text-gray-600 truncate"
            [class.text-red-600]="!invoice.data.valueDate">
            {{ invoice.data.totalPrice | number : '1.2-2' : 'de-CH' }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-dropdown-minimal-menu>
    <app-dropdown-button
      ><app-ng-icon name="heroEllipsisVerticalSolid" class="text-3xl" strokeWidth="3"></app-ng-icon
    ></app-dropdown-button>
    <app-dropdown-item (menuClick)="downloadInvoice.emit(invoice)"
      >Rechnung herunterladen
    </app-dropdown-item>
    <app-dropdown-item (menuClick)="editInvoice.emit(invoice)"
      >Rechnung bearbeiten
    </app-dropdown-item>
    <app-dropdown-item
      *ngIf="invoice.data.state === 'created'"
      (menuClick)="deleteInvoice.emit(invoice)">
      Rechnung löschen</app-dropdown-item
    >
  </app-dropdown-minimal-menu>
</div>
