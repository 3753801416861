import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import firebase from 'firebase/compat';
import { formatDate } from '@angular/common';
import Timestamp = firebase.firestore.Timestamp;

@Pipe({
    name: 'formatTimestamp',
    standalone: true,
})
export class FormatTimestampPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(timestamp: Timestamp, ...args: unknown[]): string {
    return formatDate(timestamp.toDate(), 'd. MMMM y HH:mm', this.locale);
  }
}
