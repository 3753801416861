import { Component } from '@angular/core';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'app-empty-state',
  standalone: true,
  imports: [NgIconWrapperComponent],
  templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent {}
