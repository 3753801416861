import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, filter, firstValueFrom, Observable, share, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { FirestoreProject, FirestoreWork, WorkFilter } from 'commons';
import { AsyncPipe } from '@angular/common';
import { WorkTableComponent } from './work-table/work-table.component';
import { WorkTableFilterComponent } from './work-table-filter/work-table-filter.component';
import { ProjectService } from '../../../../services/project.service';
import { OrgaUserService } from '../../../../services/orga-user.service';
import { WorkService } from '../../../../services/work.service';
import { WorkSlideOverService } from '../../slide-overs/work-slide-over.component';

@Component({
  selector: 'app-work-view',
  templateUrl: './work-view.component.html',
  standalone: true,
  imports: [WorkTableFilterComponent, WorkTableComponent, AsyncPipe],
})
export class WorkViewComponent implements OnChanges {
  @Input()
  projectId: string | null = null;

  @Input()
  positionId: string | null = null;

  @Input()
  userId: string | null = null;

  @Input()
  showFilter: ('project' | 'position' | 'user')[] = ['project', 'position', 'user'];

  works$: Observable<FirestoreWork[]>;
  positions: Observable<{ id: string; displayText: string }[]>;
  projects: Observable<{ id: string; displayText: string }[]>;
  users: Observable<{ id: string; displayText: string }[]>;

  sum$!: Observable<number>;
  filter = new BehaviorSubject<WorkFilter>({
    project: null,
    position: null,
    user: null,
    date: { type: 'month', month: new Date() },
  });

  constructor(
    private projectService: ProjectService,
    private orgaUserService: OrgaUserService,
    private workService: WorkService,
    private workSlideOver: WorkSlideOverService
  ) {
    this.works$ = this.filter.pipe(
      filter((filter): filter is WorkFilter => !!filter),
      switchMap((filter) => {
        return this.workService.getWorkByFilter(filter);
      }),
      share()
    );
    this.sum$ = this.works$.pipe(
      map((works) =>
        works.map((work) => work.data.workMinutes).reduce((acc, value) => acc + value, 0)
      )
    );
    this.projects = this.projectService
      .getAllProjects()
      .pipe(
        map((projects) =>
          projects.map((project) => ({ id: project.id, displayText: project.data.name }))
        )
      );
    this.positions = this.filter.pipe(
      filter((filter): filter is WorkFilter => !!filter && !!filter.project?.id),
      switchMap((filter: WorkFilter) => this.projectService.getProject(filter.project?.id!)),
      map((project: FirestoreProject) =>
        project.data.positions
          ? Object.values(project.data.positions).map((position) => ({
              id: position.id,
              displayText: position.name,
            }))
          : []
      )
    );
    this.users = this.orgaUserService.getAllOrgaUsers().pipe(
      map((orgaUsers) =>
        orgaUsers.map((orgaUser) => ({
          id: orgaUser.data.userId,
          displayText: orgaUser.data.userDisplayName || 'No Displayname',
        }))
      )
    );
  }

  filterChanged($event: WorkFilter) {
    this.filter.next($event);
  }

  modifyWork(work: FirestoreWork) {
    this.workSlideOver.edit(work);
  }

  deleteWork(work: FirestoreWork) {
    this.workService.deleteWork(work).then(() => console.log('deleted ' + work));
  }

  async ngOnChanges(change: SimpleChanges) {
    if (change.projectId && this.projectId) {
      const project = this.showFilter.includes('project')
        ? (await firstValueFrom(this.projects)).find(
            (project) => project.id === this.projectId
          ) || { id: this.projectId, displayText: '' }
        : { id: this.projectId, displayText: '' };
      this.filter.next({
        ...this.filter.value,
        project,
      });
    }
    if (change.positionid && this.positionId) {
      const position = this.showFilter.includes('position')
        ? (await firstValueFrom(this.positions)).find(
            (position) => position.id === this.positionId
          ) || { id: this.positionId, displayText: '' }
        : { id: this.positionId, displayText: '' };
      this.filter.next({
        ...this.filter.value,
        position,
      });
    }
    if (change.userId && this.userId) {
      const user = this.showFilter.includes('user')
        ? (await firstValueFrom(this.users)).find((user) => user.id === this.userId) || {
            id: this.userId,
            displayText: '',
          }
        : { id: this.userId, displayText: '' };
      this.filter.next({
        ...this.filter.value,
        user,
      });
    }
    if (change.positionId) {
      const position =
        (await firstValueFrom(this.positions)).find((pos) => pos.id === this.positionId) ?? null;
      this.filter.next({
        ...this.filter.value,
        position,
      });
    }
  }
}
