import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { formatISO } from 'date-fns';
import { BehaviorSubject, combineLatest, firstValueFrom, from, Observable, switchMap } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { ProjectService } from 'src/app/services/project.service';
import { createBaseWork, WorkService } from '../../../services/work.service';
import { createUserDisplayName, FirestoreProject, FirestoreUser, Position } from 'commons';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { DividerButtonComponent } from '../../../components/divider-button/divider-button.component';
import { ChooseCardsComponent } from './choose-cards/choose-cards.component';
import { StepItemComponent } from '../../../components/step/step-item.component';
import { StepComponent } from '../../../components/step/step.component';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { AsyncPipe, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { ProjectSlideOverService } from '../../projects/slide-overs/project-slide-over.component';
import { PositionSlideOverService } from '../../projects/components/positions/position-slide-over/position-slide-over.component';
import { hoursOrMinutesAreRequired } from '../../projects/slide-overs/work-slide-over.component';

export type Step = 'chooseProject' | 'choosePosition' | 'chooseTimeDescription';

@Component({
  selector: 'app-create-work',
  templateUrl: './create-work.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgIconWrapperComponent,
    StepComponent,
    StepItemComponent,
    NgSwitch,
    NgSwitchCase,
    NgFor,
    ChooseCardsComponent,
    DividerButtonComponent,
    ReactiveFormsModule,
    TailwindInputDirective,
    TailwindButtonDirective,
    AsyncPipe,
  ],
})
export class CreateWorkComponent implements OnInit {
  @Input()
  date!: Date;

  @Input()
  workMinutes!: number;

  @Input()
  description!: string;

  @Output()
  closeForm = new EventEmitter();

  @HostListener('document:keydown.escape', ['$event'])
  onEscKeydownHandler() {
    this.closeForm.emit();
  }

  currentStep$ = new BehaviorSubject<Step>('chooseProject');

  projects$!: Observable<FirestoreProject[]>;
  positions$!: Observable<Position[]>;

  showAll = new BehaviorSubject(false);

  selectedProject = new BehaviorSubject<FirestoreProject | null>(null);
  selectedPosition = new BehaviorSubject<Position | null>(null);

  workForm!: FormGroup;

  constructor(
    private projectService: ProjectService,
    private workService: WorkService,
    private sessionState: SessionStateService,
    public projectSlideOver: ProjectSlideOverService,
    public positionSlideOver: PositionSlideOverService
  ) {}

  async ngOnInit() {
    this.workForm = new FormGroup(
      {
        description: new FormControl(this.description, [Validators.maxLength(500)]),
        hours: new FormControl(this.workMinutes ? Math.floor(this.workMinutes / 60) : '', [
          Validators.max(10000),
        ]),
        minutes: new FormControl(this.workMinutes ? this.workMinutes % 60 : '', [
          Validators.max(59),
        ]),
      },
      { validators: hoursOrMinutesAreRequired }
    );

    const userId = await firstValueFrom(
      this.sessionState.getUser().pipe(
        filter((user): user is FirestoreUser => !!user),
        map((user) => user.id)
      )
    );

    this.projects$ = this.showAll.pipe(
      mergeMap((showAll) =>
        this.projectService.getActiveProjects().pipe(
          map((projects) =>
            projects.filter(({ data }) =>
              showAll ? true : !!data.favorite && userId in data.favorite && data.favorite[userId]
            )
          ),
          map((projects) => projects.sort((a, b) => a.data.name.localeCompare(b.data.name)))
        )
      )
    );
    this.positions$ = this.selectedProject.pipe(
      filter((project): project is FirestoreProject => !!project),
      switchMap((project) => this.projectService.getProject(project.id)),
      map((project) => (project.data?.positions ? Object.values(project.data?.positions) : [])),
      map((positions) => positions.sort((a, b) => a.name.localeCompare(b.name)))
    );
  }

  goToStep(step: Step) {
    switch (step) {
      case 'chooseProject':
        this.selectedProject.next(null);
        this.selectedPosition.next(null);
        this.currentStep$.next(step);
        break;
      case 'choosePosition':
        this.selectedPosition.next(null);
        this.currentStep$.next(step);
        break;
    }
  }

  selectProject(project: FirestoreProject | null): void {
    this.selectedProject.next(project);
    setTimeout(() => this.currentStep$.next('choosePosition'), 250);
  }

  selectPosition(position: Position | null) {
    this.selectedPosition.next(position);
    setTimeout(() => this.currentStep$.next('chooseTimeDescription'), 250);
  }

  submitForm(form: any) {
    const position = this.selectedPosition.value;
    const project = this.selectedProject.value;
    if (project && position) {
      combineLatest([
        this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user)),
        this.sessionState.getOrgaId(),
      ])
        .pipe(
          first(),
          map(([fsUser, orgaId]) => ({
            orgaId,
            userId: fsUser.id,
            userDisplayName: createUserDisplayName(fsUser.data.firstName, fsUser.data.lastName),
          })),
          map((user) =>
            createBaseWork(
              {
                description: form.description,
                hours: Number(form.hours),
                minutes: Number(form.minutes),
              },
              user,
              user.orgaId,
              project,
              position
            )
          ),
          map((baseWork) =>
            from(
              this.workService.createWorks([
                {
                  ...baseWork,
                  date: formatISO(this.date, { representation: 'date' }),
                },
              ])
            )
          )
        )
        .subscribe(() => this.closeForm.emit());
    }
  }

  createProject() {
    this.projectSlideOver.new();
  }

  createPosition() {
    if (this.selectedProject.value) {
      this.positionSlideOver.new({ project: this.selectedProject.value });
    }
  }

  showAllProjects() {
    this.showAll.next(true);
  }
}
