<app-slide-over>
  <app-slide-over-header
    (closeSlideOver)="closeSlideOver()"
    [title]="title"
    [formDirty]="workForm.dirty">
  </app-slide-over-header>

  <app-slide-over-content>
    <form class="form-container pl-3" [formGroup]="workForm" autocomplete="off" novalidate>
      <div class="px-4 divide-y divide-gray-200 sm:px-6">
        <div class="space-y-1 pt-6 pb-5">
          <div class="flex justify-end">
            <app-toggle
              [displayShort]="true"
              (valueChange)="showExtendedForm($event)"
              *ngIf="isShowExtendedFormEnabled"></app-toggle>
          </div>
          <div class="space-y-6">
            <ng-container *ngIf="!extendedForm; else rangeDate">
              <div>
                <app-input
                  label="Datum"
                  class="mt-4"
                  [errors]="
                    workForm.controls['date']?.touched ? workForm.controls['date']?.errors : null
                  "
                  ><input appTailwindInput formControlName="date" type="date"
                /></app-input>
              </div>
            </ng-container>
            <ng-template #rangeDate>
              <div>
                <app-input
                  label="Von"
                  class="mt-4"
                  [errors]="
                    workForm.controls['startDate']?.touched
                      ? workForm.controls['startDate']?.errors
                      : null
                  "
                  ><input appTailwindInput formControlName="startDate" type="date"
                /></app-input>
              </div>
              <div class="space-y-1">
                <app-input
                  label="Bis"
                  class="mt-4"
                  [errors]="
                    workForm.controls['endDate']?.touched
                      ? workForm.controls['endDate']?.errors
                      : null
                  "
                  ><input appTailwindInput formControlName="endDate" type="date"
                /></app-input>
              </div>
              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="createWorkForWeekendDays"
                    type="checkbox"
                    formControlName="createWorkForWeekendDays"
                    class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="createWorkForWeekendDays" class="font-medium text-gray-700"
                    >Wochenende</label
                  >
                  <p class="text-gray-500">Erstelle Arbeiten auch Samstags und Sonntags</p>
                </div>
              </div>
            </ng-template>

            <div class="space-y-1">
              <app-input
                label="Beschreibung"
                [errors]="
                  workForm.controls['description']?.touched
                    ? workForm.controls['description']?.errors
                    : null
                ">
                <textarea
                  appTailwindInput
                  id="description"
                  rows="4"
                  class="form-input block w-full sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                  formControlName="description"></textarea>
              </app-input>
            </div>
            <div>
              <app-input
                label="Arbeitszeit"
                [errors]="
                  (workForm.controls['hours']?.touched || workForm.controls['minutes']?.touched) &&
                  (workForm.getError('eitherHoursOrMinutesAreMissing') ||
                    workForm.controls['hours'].errors ||
                    workForm.controls['minutes'].errors)
                    ? {
                        t: workForm.getError('eitherHoursOrMinutesAreMissing'),
                        h: workForm.controls['hours'].errors,
                        m: workForm.controls['minutes'].errors
                      }
                    : null
                ">
                <div class="flex gap-2">
                  <input
                    placeholder="Stunden"
                    appTailwindInput
                    formControlName="hours"
                    type="text"
                    pattern="[0-9]*$" />
                  <input
                    placeholder="Minuten"
                    appTailwindInput
                    formControlName="minutes"
                    type="text"
                    pattern="[0-9]*$" />
                </div>
              </app-input>
            </div>
            <div class="relative flex items-start">
              <div class="flex items-center h-5">
                <input
                  id="billed"
                  type="checkbox"
                  formControlName="billed"
                  class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <label for="billed" class="font-medium text-gray-700">Verrechnet</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </app-slide-over-content>

  <app-slide-over-footer>
    <app-dropdown-minimal-menu *ngIf="!workForm.get('billed')?.value">
      <app-dropdown-button><app-ng-icon name="heroTrash"></app-ng-icon></app-dropdown-button>
      <app-dropdown-item (menuClick)="deleteWork()"> Löschen </app-dropdown-item>
    </app-dropdown-minimal-menu>
    <span class="inline-flex rounded-md shadow-sm">
      <button appTailwindButton color="secondary" type="button" (click)="closeSlideOver()">
        Abbrechen
      </button>
    </span>
    <span class="inline-flex rounded-md shadow-sm">
      <button
        appTailwindButton
        type="button"
        [disabled]="!workForm.valid"
        (click)="submitForm(workForm.value)">
        Speichern
      </button>
    </span>
  </app-slide-over-footer>
</app-slide-over>
