import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FirestoreUser, OrgaInfo, OrgaUserPermission } from 'commons';
import { Router, RouterLink } from '@angular/router';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-choose-orga',
  templateUrl: './choose-orga.component.html',
  standalone: true,
  imports: [NgIf, NgFor, NgIconWrapperComponent, TailwindButtonDirective, RouterLink, AsyncPipe],
})
export class ChooseOrgaComponent implements OnInit {
  selectedOrganisationId?: string;
  organisations$?: Observable<{ id: string; name: string; role: OrgaUserPermission }[]>;

  loggedInUserEmail$ = this.sessionState.getUser().pipe(
    filter((user): user is FirestoreUser => !!user),
    map((user) => user.data.email)
  );

  constructor(
    private sessionState: SessionStateService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.organisations$ = this.sessionState.getOrganisations().pipe(
      filter((orgas): orgas is Record<string, OrgaInfo> => !!orgas),
      map((orgas) => Object.entries(orgas).map(([key, value]) => ({ id: key, ...value })))
    );
  }

  async onSubmit() {
    if (this.selectedOrganisationId) {
      await this.authService.setLoggedInOrgaId(this.selectedOrganisationId);
      await this.router.navigate(['/']);
    }
  }

  setOrganisation(id: string) {
    this.selectedOrganisationId = id;
  }
}
