import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SidebarNavigationEntryComponent } from '../../components/container/sidebar-navigation/sidebar-navigation-entry/sidebar-navigation-entry.component';
import { SidebarNavigationComponent } from '../../components/container/sidebar-navigation/sidebar-navigation.component';
import { HeaderMetaActionComponent } from '../../components/container/header-meta-action/header-meta-action.component';
import { ContainerComponent } from '../../components/container/container.component';

@Component({
    selector: 'app-finance',
    templateUrl: './finance.component.html',
    standalone: true,
    imports: [
        ContainerComponent,
        HeaderMetaActionComponent,
        SidebarNavigationComponent,
        SidebarNavigationEntryComponent,
        RouterLink,
        RouterOutlet,
    ],
})
export class FinanceComponent {}
