import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIconWrapperComponent } from '../../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'li[app-calendar-work-entry]',
    templateUrl: './calendar-work-entry.component.html',
    standalone: true,
    imports: [NgIf, NgIconWrapperComponent],
})
export class CalendarWorkEntryComponent {
  @Input() workHours!: string;
  @Input() userDisplayName!: string;
  @Input() companyName!: string;
  @Input() projectId!: string;
  @Input() projectName!: string;
  @Input() positionName!: string;

  @Input() description!: string;
  @Input() billable!: boolean;
  @Input() billed!: boolean;

  @Output() deleteWork = new EventEmitter();
  @Output() modifyWork = new EventEmitter();
}
