type Mode = 'new' | 'edit';

export class SlideOverService<Entity, NewMetaData, EditMetaData> {
  private entity!: Entity;
  private newMeta!: NewMetaData;
  private editMeta!: EditMetaData;

  private _isOpen = false;
  private mode!: Mode;

  new(meta: NewMetaData) {
    this.mode = 'new';
    this.newMeta = meta;
    this._isOpen = true;
  }

  edit(entity: Entity, meta: EditMetaData) {
    this.mode = 'edit';
    this.editMeta = meta;
    this.entity = entity;
    this._isOpen = true;
  }

  getNewMeta(): NewMetaData {
    return this.newMeta;
  }

  getEditMeta(): EditMetaData {
    return this.editMeta;
  }

  getEntity(): Entity {
    return this.entity;
  }

  getMode() {
    return this.mode;
  }

  closeSlideOver() {
    this._isOpen = false;
  }

  isOpen() {
    return this._isOpen;
  }
}
