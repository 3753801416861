import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { add, addMonths, lastDayOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { groupBy } from 'lodash';
import { FirestoreUser, FirestoreWork } from 'commons';
import { WorkService } from '../../../services/work.service';
import { NgIconWrapperComponent } from '../../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { TailwindButtonDirective } from '../../button/tailwind-button.directive';
import { CalendarWorkEntryComponent } from './calendar-work-entry.component';
import { MonthCalendarComponent } from '../month-calendar/month-calendar.component';
import { AsyncPipe, DatePipe, NgFor, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { WorkSlideOverService } from '../../../pages/projects/slide-overs/work-slide-over.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styles: [':host { @apply relative}'],
  standalone: true,
  imports: [
    NgIf,
    MonthCalendarComponent,
    NgFor,
    CalendarWorkEntryComponent,
    TailwindButtonDirective,
    NgIconWrapperComponent,
    AsyncPipe,
    DatePipe,
  ],
})
export class CalendarComponent implements OnInit {
  @Output()
  createWork = new EventEmitter<Date>();

  currentMonth$ = new BehaviorSubject<Date>(startOfMonth(new Date()));
  months$!: Observable<Date[]>;
  selectedDate = new BehaviorSubject<Date>(new Date());

  work$!: Observable<Map<string, number>>;

  selectedDateWorkItems$!: Observable<FirestoreWork[]>;
  selectedDateWorkItemsSum$!: Observable<string>;

  constructor(
    private workService: WorkService,
    private sessionState: SessionStateService,
    private workSlideOver: WorkSlideOverService
  ) {}

  ngOnInit(): void {
    this.months$ = this.currentMonth$.pipe(
      map((currentMonth) => [
        addMonths(startOfMonth(currentMonth), -2),
        addMonths(startOfMonth(currentMonth), -1),
        startOfMonth(currentMonth),
      ])
    );

    this.work$ = combineLatest([
      this.sessionState.getUser().pipe(
        filter((user): user is FirestoreUser => !!user),
        map((user) => user.id)
      ),
      this.months$,
    ]).pipe(
      switchMap(([userId, months]) =>
        this.workService.getAllWorkForUser(
          userId,
          months[0],
          lastDayOfMonth(months[months.length - 1])
        )
      ),
      map((works) => groupBy(works, (work) => startOfDay(new Date(work.data.date)).toDateString())),
      map((workMap) => {
        const w = new Map<string, number>();
        // @ts-ignore
        for (const [key, value] of Object.entries(workMap)) {
          w.set(
            key,
            value.reduce(
              (previousValue, currentValue) => previousValue + currentValue.data.workMinutes,
              0
            ) / 60
          );
        }
        return w;
      })
    );
    this.selectedDateWorkItems$ = combineLatest([
      this.sessionState.getUser().pipe(
        filter((user): user is FirestoreUser => !!user),
        map((user) => user.id)
      ),
      this.selectedDate,
    ]).pipe(switchMap(([userId, date]) => this.workService.getAllWorkForUser(userId, date, date)));

    this.selectedDateWorkItemsSum$ = this.selectedDateWorkItems$.pipe(
      map((items) => items.reduce((prev, curr) => prev + curr.data.workMinutes, 0)),
      map((sum) => (sum / 60).toFixed(2))
    );
  }

  nextMonth() {
    this.currentMonth$.next(add(this.currentMonth$.value, { months: 1 }));
  }

  previousMonth() {
    this.currentMonth$.next(add(this.currentMonth$.value, { months: -1 }));
  }

  selectDate(date: Date) {
    this.selectedDate.next(date);
  }

  async deleteWork(work: FirestoreWork) {
    try {
      await this.workService.deleteWork(work);
    } catch (e) {
      console.error(e);
    }
  }

  modifyWork(work: FirestoreWork) {
    this.workSlideOver.edit(work);
  }
}
