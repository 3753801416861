<div class="max-w-sm md:p-8 p-5 bg-white rounded-t" style="min-height: 400px; min-width: 336px">
  <div class="px-4">
    <p tabindex="0" class="focus:outline-none text-base font-bold text-gray-800">
      {{ monthTitle }}
    </p>
    <p class="text-sm text-gray-600">{{ totalWorkMonth }}h</p>
  </div>
  <div class="flex items-center justify-between pt-10 overflow-x-auto">
    <table class="w-full">
      <thead>
        <tr>
          <th>
            <div class="w-full flex justify-center">
              <p class="text-base font-medium text-center text-gray-800">Mo</p>
            </div>
          </th>
          <th>
            <div class="w-full flex justify-center">
              <p class="text-base font-medium text-center text-gray-800">Di</p>
            </div>
          </th>
          <th>
            <div class="w-full flex justify-center">
              <p class="text-base font-medium text-center text-gray-800">Mi</p>
            </div>
          </th>
          <th>
            <div class="w-full flex justify-center">
              <p class="text-base font-medium text-center text-gray-800">Do</p>
            </div>
          </th>
          <th>
            <div class="w-full flex justify-center">
              <p class="text-base font-medium text-center text-gray-800">Fr</p>
            </div>
          </th>
          <th>
            <div class="w-full flex justify-center">
              <p class="text-base font-medium text-center text-gray-800">Sa</p>
            </div>
          </th>
          <th>
            <div class="w-full flex justify-center">
              <p class="text-base font-medium text-center text-gray-800">So</p>
            </div>
          </th>
          <th>
            <div class="w-full flex justify-end">
              <p class="text-sm font-medium text-center text-gray-500">Σ</p>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let week of weeks; first as isFirstWeek">
          <ng-container *ngFor="let day of week.days">
            <td [class.pt-6]="isFirstWeek" (click)="selectDate.emit(day.date)">
              <ng-container *ngIf="day.isInCurrentMonth">
                <ng-container *ngIf="!day.isSelected && !day.isCurrentDate; else selectedOrCurrent">
                  <div
                    class="cursor-pointer flex justify-center"
                    [class]="
                      day.work > 7.8
                        ? 'bg-green-400'
                        : day.work > 6
                          ? 'bg-green-300'
                          : day.work > 3
                            ? 'bg-green-200'
                            : day.work > 0
                              ? 'bg-green-100'
                              : ''
                    ">
                    <div class="flex items-center justify-center rounded-full w-8 h-8">
                      <p class="text-base text-gray-500 font-medium">
                        {{ day.dayOfMonth }}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-template #selectedOrCurrent>
                  <ng-container *ngIf="day.isSelected; else currentDate">
                    <div class="w-full h-full">
                      <div
                        class="flex items-center justify-center w-full rounded-full cursor-pointer">
                        <p
                          class="text-base w-8 h-8 flex items-center justify-center font-medium text-white bg-blue-700 rounded-full">
                          {{ day.dayOfMonth }}
                        </p>
                      </div>
                    </div>
                  </ng-container>

                  <ng-template #currentDate>
                    <div class="cursor-pointer flex justify-center">
                      <div
                        class="flex items-center justify-center rounded-full w-8 h-8 border-2 border-blue-600"
                        [class]="
                          day.work > 7.8
                            ? 'bg-green-400'
                            : day.work > 6
                              ? 'bg-green-300'
                              : day.work > 3
                                ? 'bg-green-200'
                                : day.work > 0
                                  ? 'bg-green-100'
                                  : ''
                        ">
                        <p class="text-base text-gray-500 font-medium">
                          {{ day.dayOfMonth }}
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </ng-container>
            </td>
          </ng-container>
          <td [class.pt-6]="isFirstWeek">
            <div class="flex justify-end content-end w-10">
              <p class="text-xs text-gray-400 truncate">
                {{ week.work }}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
