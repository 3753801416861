<app-settings-card heading="Rechnungsinformationen" (save)="saveSettings()" [disabled]="disabled">
  <form [formGroup]="invoiceDetailForm">
    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-6">
        <app-input label="Mehrwertsteuernummer">
          <input type="text" name="vatNumber" appTailwindInput [formControlName]="'vatNumber'" />
        </app-input>
      </div>
      <div class="sm:col-span-2">
        <app-input label="Mehrwertsteuersatz">
          <input type="number" name="defaultVatRate" appTailwindInput [formControlName]="'defaultVatRate'" />
        </app-input>
      </div>
      <div class="sm:col-span-2">
        <app-input label="Zahlungsdauer in Tage">
          <input
            type="number"
            name="defaultPaymentDurationDays"
            appTailwindInput
            [formControlName]="'defaultPaymentDurationDays'" />
        </app-input>
      </div>
      <div class="sm:col-span-2">
        <app-input label="Aktuelle Rechnungsnummer">
          <input
            type="number"
            name="currentInvoiceNumber"
            appTailwindInput
            [formControlName]="'currentInvoiceNumber'" />
        </app-input>
      </div>
    </div>
  </form>
</app-settings-card>
