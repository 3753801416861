import { Component } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ProjectService } from '../../../services/project.service';
import { FirestoreProject } from 'commons';
import { SessionService } from '../../../services/session.service';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ProjectListEntryComponent } from '../components/project-list-entry/project-list-entry.component';
import { ProjectSlideOverService } from '../slide-overs/project-slide-over.component';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.page.html',
  standalone: true,
  imports: [NgIf, NgClass, NgFor, ProjectListEntryComponent, EmptyComponent, AsyncPipe],
})
export class ProjectListPage {
  projects$: Observable<FirestoreProject[]>;

  filter$ = new BehaviorSubject<'all' | 'inactive' | 'active'>('active');

  constructor(
    private projectService: ProjectService,
    private sessionService: SessionService,
    private router: Router,
    private projectSlideOver: ProjectSlideOverService
  ) {
    this.projects$ = this.filter$.pipe(
      switchMap((filter) => {
        switch (filter) {
          case 'active':
            return this.projectService.getActiveProjects();
          case 'inactive':
            return this.projectService.getInactiveProjects();
          case 'all':
            return this.projectService.getAllProjects();
        }
      }),
      map((projects) => projects.sort((a, b) => a.data.name.localeCompare(b.data.name)))
    );
  }

  createProject() {
    this.projectSlideOver.new();
  }
}
