import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../services/session.service';
import { filter, first } from 'rxjs/operators';

@Component({
    selector: 'app-maintanance',
    templateUrl: './maintenance.component.html',
    standalone: true,
})
export class MaintenanceComponent {
  constructor(private sessionService: SessionService, private router: Router) {
    this.sessionService
      .isSystemOpen()
      .pipe(
        filter((isOpen) => isOpen === true),
        first()
      )
      .subscribe(() => this.router.navigateByUrl('/').then(() => window.location.reload()));
  }
}
