<button
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="menuOpen($event)"
  class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600"
  aria-label="Options"
  id="options-menu"
  aria-haspopup="true"
  aria-expanded="true">
  <ng-content select="app-dropdown-button"></ng-content>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="isOpen = false"
  (detach)="isOpen = false">
  <div>
    <div class="mt-2 w-56 rounded-md shadow-lg z-50 max-h-80 overflow-y-auto">
      <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
        <ng-container *ngIf="dropdownHeader">
          <div class="px-4 py-3">
            <p class="text-sm leading-5 font-medium text-gray-900 truncate">
              {{ dropdownHeader }}
            </p>
          </div>
          <div class="border-t border-gray-100"></div>
        </ng-container>

        <div *ngIf="hasSearch">
          <input
            appSelectAll
            appTailwindInput
            class="m-2 p-2 w-4/5"
            placeholder="suche..."
            (input)="searchValue($event)" />
        </div>

        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <ng-content select="app-dropdown-item"></ng-content>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</ng-template>
