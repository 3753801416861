import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';
import { icons } from '../../icon.config';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [NgIconWrapperComponent, NgIf],
  templateUrl: './dialog.component.html',
})
export class DialogComponent {
  @Input() icon: keyof typeof icons = 'heroCheckSolid';

  @Input() color: 'red' | 'green' | 'blue' | 'orange' = 'green';
  @Input() title!: string;
  @Input() description!: string;
  @Input() actionText!: string;
  @Output() actionClicked = new EventEmitter();
}
