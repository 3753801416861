<app-work-table-filter
  [showFilter]="showFilter"
  [filter]="(filter | async)!"
  [projectFilterValues]="showFilter.includes('project') ? (projects | async)! : []"
  [positionFilterValues]="showFilter.includes('position') ? (positions | async)! : []"
  [userFilterValues]="showFilter.includes('user') ? (users | async)! : []"
  (filterChanged)="filterChanged($event)"></app-work-table-filter>

<app-work-table
  [columns]="showFilter"
  [works]="(works$ | async)!"
  [sum]="sum$ | async"
  (deleteWork)="deleteWork($event)"
  (modifyWork)="modifyWork($event)"></app-work-table>
