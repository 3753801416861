<div class="flex justify-end lg:-mt-[60px]">
  <app-date-filter-pill
    [filter]="(dateFilter | async)!"
    (filterChanged)="dateFilter.next($event)"></app-date-filter-pill>
</div>

<div class="flex flex-col gap-8 mt-4">
  <app-card>
    <app-card-content>
      <h2 class="text-lg leading-6 font-medium text-gray-900">Projekte</h2>
      <app-two-col-description-list>
        <app-description-list-entry
          label="Total"
          [value]="totalMinutes | formatMinutes"></app-description-list-entry>
        <app-description-list-entry
          label="Total verrechenbar"
          [value]="billableMinutes | formatMinutes"></app-description-list-entry>
        <app-description-list-entry
          label="Total nicht verrechenbar"
          [value]="unbillableMinutes | formatMinutes"></app-description-list-entry>
      </app-two-col-description-list>

      <app-project-stats-table>
        <ng-container *ngFor="let project of projectStats">
          <tr
            app-project-stats-row
            [projectName]="project.projectName"
            [totalMinutes]="project.totalMinutes"
            [billableMinutes]="project.billableMinutes"
            [unbillableMinutes]="project.unbillableMinutes"></tr>

          <tr
            app-position-stats-row
            title="Position"
            *ngFor="let position of project.positionStats"
            [positionName]="position.positionName"
            [totalMinutes]="position.totalMinutes"
            [billableMinutes]="position.billableMinutes"
            [unbillableMinutes]="position.unbillableMinutes"></tr>
        </ng-container>
      </app-project-stats-table>
    </app-card-content>
  </app-card>

  <h2 class="text-lg leading-6 font-medium text-gray-900">Mitarbeiter</h2>

  <ng-container *ngFor="let user of userStats">
    <app-card>
      <app-card-content>
        <h2 class="text-sm leading-6 font-medium text-gray-900">
          {{ user.displayName }}
        </h2>

        <app-two-col-description-list>
          <app-description-list-entry
            label="Total"
            [value]="user.totalMinutes | formatMinutes"></app-description-list-entry>
          <app-description-list-entry
            label="Total verrechenbar"
            [value]="user.billableMinutes | formatMinutes"></app-description-list-entry>
          <app-description-list-entry
            label="Total nicht verrechenbar"
            [value]="user.unbillableMinutes | formatMinutes"></app-description-list-entry>
        </app-two-col-description-list>
        <app-project-stats-table>
          <ng-container *ngFor="let project of user.projectsStats">
            <tr
              app-project-stats-row
              [projectName]="project.projectName"
              [totalMinutes]="project.totalMinutes"
              [billableMinutes]="project.billableMinutes"
              [unbillableMinutes]="project.unbillableMinutes"></tr>
            <tr
              app-position-stats-row
              title="Position"
              *ngFor="let position of project.positionStats"
              [positionName]="position.positionName"
              [totalMinutes]="position.totalMinutes"
              [billableMinutes]="position.billableMinutes"
              [unbillableMinutes]="position.unbillableMinutes"></tr>
          </ng-container>
        </app-project-stats-table>
      </app-card-content>
    </app-card>
  </ng-container>
</div>
