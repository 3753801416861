"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEntityDisplayName = exports.ENTITIES_DE = void 0;
exports.ENTITIES_DE = {
    archive: 'Archiv',
    comment: 'Kommentar',
    position: 'Position',
    project: 'Projekt',
    work: 'Arbeit',
    orga: 'Organisation',
    user: 'User',
    ['customer-company']: 'Firmenkunde',
    ['customer-individual']: 'Privatkunde',
    document: 'Dokument',
    invoice: 'Rechnung',
    history: 'History',
    permission: 'Berechtigung',
    orgaUser: 'Organisation User',
    folder: 'Dokumentenordner',
};
function getEntityDisplayName(data, entity) {
    var _a;
    switch (entity) {
        case 'customer-company':
        case 'customer-individual':
            return data.displayName;
        case 'comment':
            return data.commentText.slice(0, 20);
        case 'document':
            return data.name;
        case 'invoice':
            return `${exports.ENTITIES_DE['invoice']} ${data.invoiceNumber}`;
        case 'history':
            return '';
        case 'permission':
            return '';
        case 'position':
            return data.name;
        case 'project':
            return data.name;
        case 'orga':
            return data.name;
        case 'orgaUser':
            return (_a = data.userDisplayName) !== null && _a !== void 0 ? _a : 'no display name';
        case 'user':
            return data.firstName + data.lastName;
        case 'folder':
            return data.name;
        case 'work':
            const work = data;
            return work.workMinutes + 'minutes from ' + work.userDisplayName + ' on ' + work.date;
        case 'archive':
            return `Archive`;
    }
}
exports.getEntityDisplayName = getEntityDisplayName;
