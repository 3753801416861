import { Component } from '@angular/core';
import { DocumentService } from './services/document.service';
import { Router, RouterOutlet } from '@angular/router';
import { FileDragDropDirective } from './directives/file-drag-drop.directive';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [NgIf, RouterOutlet, FileDragDropDirective],
})
export class AppComponent {
  showScreenshotFrame = false;

  constructor(
    private documentService: DocumentService,
    private router: Router
  ) {}

  async uploadFile(event: FileList | Event) {
    let fileList: FileList | null;

    if (event instanceof Event) {
      fileList = (event.target as HTMLInputElement).files;
    } else {
      fileList = event;
    }
    if (fileList) {
      for (const file of Array.from(fileList)) {
        await this.documentService.uploadDocument(file);
      }
      this.router.navigate(['document', 'inbox']);
    }
  }
}
