<header>
  <div class="space-y-1 py-6 px-4 bg-blue-700 sm:px-6">
    <div class="flex items-center justify-between space-x-3">
      <h2 class="text-lg leading-7 font-medium text-white min-w-0 truncate">
        {{ title }}
      </h2>
      <div class="h-7 flex items-center gap-4">
        <ng-content></ng-content>
        <button
          aria-label="Close panel"
          type="button"
          class="text-blue-200 hover:text-white transition ease-in-out duration-150"
          (click)="closeSlideOverClicked()">
          <app-ng-icon class="text-2xl" name="heroXMark"></app-ng-icon>
        </button>
      </div>
    </div>
    <div>
      <p class="text-sm leading-5 text-blue-300" [innerHTML]="leadText"></p>
    </div>
  </div>
  <div *ngIf="showConfirmation" class="relative rounded-md bg-yellow-50 p-4 m-4 shadow-xl">
    <h3 class="text-sm font-medium text-yellow-800">Ungespeicherte Änderungen</h3>
    <div class="mt-2 text-sm text-yellow-700">
      <p>Du hast ungespeicherte Änderungen. Willst du diese verwerfen oder weiterbearbeiten?</p>
    </div>
    <div class="mt-4">
      <div class="-mx-2 -my-1.5 flex">
        <button
          type="button"
          (click)="showConfirmation = false"
          class="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-green-50">
          Weiterarbeiten
        </button>
        <button
          type="button"
          (click)="closeSlideOver.emit()"
          class="ml-3 rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-green-50">
          Verwerfen
        </button>
      </div>
    </div>
  </div>
</header>
