<div
  class="flex w-60 rounded-full border bg-white p-2 text-gray-400 relative justify-between h-[42px]"
  [ngClass]="filter ? 'border-green-600 text-gray-600' : 'border-gray-300'">
  <div *ngIf="filter?.displayText" class="ml-4 text-gray-600 pr-4 truncate">
    {{ filter?.displayText }}
  </div>
  <div *ngIf="!filter?.displayText" class="ml-4">{{ label }}</div>
  <button (click)="resetFilter()" *ngIf="filter; else noFilter">
    <app-ng-icon class="text-2xl" strokeWidth="2" name="heroXCircle"></app-ng-icon>
  </button>
  <ng-template #noFilter>
    <app-dropdown-minimal-menu class="mr-1">
      <app-dropdown-button>
        <app-ng-icon class="text-xl" name="heroFunnelSolid"></app-ng-icon>
      </app-dropdown-button>
      <app-dropdown-item *ngFor="let val of filterValues" (menuClick)="enableFilter(val)"
        >{{ val.displayText }}
      </app-dropdown-item>
    </app-dropdown-minimal-menu>
  </ng-template>
</div>
