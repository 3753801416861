<ng-container [ngSwitch]="progress">
  <!-- Completed Step -->
  <button
    *ngSwitchCase="'completed'"
    (click)="stepClicked.emit($event)"
    class="w-full group pl-4 py-2 flex flex-col border-l-4 border-blue-600 hover:border-blue-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
    <span
      class="text-xs text-blue-600 font-semibold tracking-wide uppercase group-hover:text-blue-800"
      >{{ stepTitle }}</span
    >
    <span class="text-sm font-medium">{{ stepSubTitle }}</span>
  </button>

  <!-- Current Step -->
  <button
    *ngSwitchCase="'current'"
    (click)="stepClicked.emit($event)"
    class="w-full pl-4 py-2 flex flex-col border-l-4 border-blue-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
    aria-current="step">
    <span class="text-xs text-blue-600 font-semibold tracking-wide uppercase">{{ stepTitle }}</span>
    <span class="text-sm font-medium">{{ stepSubTitle }}</span>
  </button>

  <!-- Upcoming Step -->
  <div
    *ngSwitchCase="'upcoming'"
    class="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4">
    <span
      class="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700"
      >{{ stepTitle }}</span
    >
    <span class="text-sm font-medium">{{ stepSubTitle }}</span>
  </div>
</ng-container>
