import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-header-meta-action',
    templateUrl: './header-meta-action.component.html',
    standalone: true,
})
export class HeaderMetaActionComponent {
  @Input()
  title!: string;
  @Input()
  subTitle!: string;
}
