<app-settings-card
  heading="Adresse"
  description="Die Adresse wird beispielsweise in den erzeugten Rechnungen verwendet."
  (save)="saveSettings()"
  [disabled]="disabled">
  <form [formGroup]="addressForm">
    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-6">
        <app-input label="Adresszeile 1">
          <input
            type="text"
            name="addressLine1"
            appTailwindInput
            [formControlName]="'addressLine1'" />
        </app-input>
      </div>
      <div class="sm:col-span-6">
        <app-input label="Adresszeile 2">
          <input
            type="text"
            name="addressLine2"
            appTailwindInput
            [formControlName]="'addressLine2'" />
        </app-input>
      </div>
      <div class="sm:col-span-1">
        <app-input label="Plz">
          <input type="text" name="zipCode" appTailwindInput [formControlName]="'zipCode'" />
        </app-input>
      </div>
      <div class="sm:col-span-5">
        <app-input label="Ort">
          <input type="text" name="city" appTailwindInput [formControlName]="'city'" />
        </app-input>
      </div>
      <div class="sm:col-span-1">
        <app-input label="Land (Code)" [errors]="addressForm.get('countryCode')?.errors">
          <input
            type="text"
            name="countryCode"
            appTailwindInput
            [formControlName]="'countryCode'" />
        </app-input>
      </div>
    </div>
  </form>
</app-settings-card>
