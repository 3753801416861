import { Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { addSeconds, differenceInSeconds } from 'date-fns';
import { OrgaUserService } from '../../../services/orga-user.service';
import { Timer, TimerRunner } from 'commons';
import { Timestamp } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class WorkTimerService {
  private timer$: Observable<Timer>;
  private uiTimer$: Observable<UiTimer[]>;

  constructor(private orgaUserService: OrgaUserService) {
    this.timer$ = this.orgaUserService.getTimer().pipe(shareReplay(1));
    this.uiTimer$ = this.timer$.pipe(
      map((timer) => mapUiTimerArray(timer?.allTimers, timer?.running))
    );
  }

  getAllTimer() {
    return this.uiTimer$;
  }

  getRunningTimer(): Observable<UiTimer | undefined> {
    return this.uiTimer$.pipe(map((timer) => timer.find((r) => r.isRunning)));
  }

  async start(index: number) {
    await this.pause();
    const timer = await firstValueFrom(this.timer$);
    const currentRunner = timer.allTimers[index];

    currentRunner.startTime = Timestamp.fromDate(
      addSeconds(new Date(), -currentRunner.cumulatedSeconds)
    );
    currentRunner.stopTime = null;

    timer.running = index;

    await this.update(timer);
  }

  async pause() {
    const timer = await firstValueFrom(this.timer$);
    if (timer.running < 0) {
      return;
    }
    const currentRunner = timer.allTimers[timer.running];
    const currentTimestamp = Timestamp.fromDate(new Date());

    currentRunner.stopTime = currentTimestamp;
    currentRunner.cumulatedSeconds = differenceInSeconds(
      currentTimestamp.toDate(),
      currentRunner.startTime.toDate()
    );
    timer.running = -1;
    await this.update(timer);
  }

  static emptyDescription = 'Beschreibung (zum Anpassen klicken)';

  async addTimer() {
    const timer: Timer = (await firstValueFrom(this.timer$)) ?? { allTimers: [], running: -1 };
    const currentTimestamp = Timestamp.fromDate(new Date());
    if (!timer.allTimers) {
      timer.allTimers = [];
      timer.running = -1;
    }
    timer.allTimers.push({
      creationTime: currentTimestamp,
      startTime: currentTimestamp,
      stopTime: currentTimestamp,
      cumulatedSeconds: 0,
      description: WorkTimerService.emptyDescription,
    });
    await this.update(timer);
    await this.start(timer.allTimers.length - 1);
  }

  async delete(index: number) {
    const timer = await firstValueFrom(this.timer$);
    let running = timer.running;
    if (running > index) {
      running = running - 1;
    } else if (running === index) {
      index = -1;
    }
    const timers = timer.allTimers;
    timers.splice(index, 1);

    await this.orgaUserService.updateTimer({
      running,
      allTimers: timers,
    });
  }

  async update(timer: Timer) {
    await this.orgaUserService.updateTimer(timer);
  }

  async updateDescription(index: number, description: string) {
    const timer = await firstValueFrom(this.timer$);
    const timers = timer.allTimers;
    timers[index].description = description || WorkTimerService.emptyDescription;
    await this.orgaUserService.updateTimer({
      allTimers: timers,
    });
  }
}

function mapUiTimerArray(timerRunner: TimerRunner[] | undefined, runningIndex: number | undefined) {
  const allUiTimer: UiTimer[] = [];
  timerRunner?.forEach((timerRunner, index) =>
    allUiTimer.push(mapUiTimer(timerRunner, runningIndex === index))
  );
  return allUiTimer;
}

function mapUiTimer(timerRunner: TimerRunner, isRunning: boolean) {
  return { ...timerRunner, isRunning } as UiTimer;
}

export interface UiTimer extends TimerRunner {
  isRunning: boolean;
}
