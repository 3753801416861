import { inject, InjectionToken } from '@angular/core';
import {
  collection,
  collectionGroup,
  collectionSnapshots,
  deleteDoc,
  doc,
  docSnapshots,
  DocumentData,
  Firestore,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  setDoc,
  updateDoc,
  WithFieldValue,
} from '@angular/fire/firestore';
import { firstValueFrom, map, Observable, of } from 'rxjs';
import { FirestoreEntity } from 'commons';
import { hydrate } from './helper';
import { switchMap } from 'rxjs/operators';
import {
  getDownloadURL,
  getMetadata,
  ref,
  Storage,
  uploadBytes,
  UploadMetadata,
} from '@angular/fire/storage';
import { SessionStateService } from './session-state.service';

export const FIRESTORE_SERVICE = new InjectionToken<FirestoreService>('FirebaseService');
export const ORGA_FIRESTORE_SERVICE = new InjectionToken<FirestoreService>('OrgaFirebaseService');

export const firestoreServiceFactory = () =>
  new FirestoreService({
    getPath(path: string) {
      console.log('root service: ', path);
      return of(path);
    },
  });

export const orgaFirestoreServiceFactory = () => {
  const sessionState = inject(SessionStateService);
  return new FirestoreService({
    getPath(path: string): Observable<string> {
      return sessionState.getOrgaId().pipe(
        map((orgaId) => {
          const hydratedPath = `orga/${orgaId}/${path}`;
          console.log('orga service: ', hydratedPath);
          return hydratedPath;
        })
      );
    },
  });
};

export interface PathProvider {
  getPath(path: string): Observable<string>;
}

export class FirestoreService {
  firestore = inject(Firestore);
  storage = inject(Storage);

  constructor(private pathProvider: PathProvider) {}

  getDocs<T extends DocumentData>(
    collectionPath: string,
    ...queryConstraints: QueryConstraint[]
  ): Observable<FirestoreEntity<T>[]> {
    return this.pathProvider
      .getPath(collectionPath)
      .pipe(
        switchMap((path) =>
          collectionSnapshots(query(collection(this.firestore, path), ...queryConstraints)).pipe(
            map((docs) => docs.map((doc) => hydrate<T>(doc as QueryDocumentSnapshot<T>)))
          )
        )
      );
  }

  getDoc<T extends DocumentData>(docPath: string): Observable<FirestoreEntity<T>> {
    return this.pathProvider
      .getPath(docPath)
      .pipe(
        switchMap((path) =>
          docSnapshots(doc(this.firestore, path)).pipe(
            map((doc) => hydrate<T>(doc as QueryDocumentSnapshot<T>))
          )
        )
      );
  }

  async createDoc<T extends WithFieldValue<DocumentData>>(collectionPath: string, data: T) {
    const resolvedPath = await firstValueFrom(this.pathProvider.getPath(collectionPath));
    const docRef = doc(collection(this.firestore, resolvedPath));
    await setDoc(docRef, data);
    return docRef;
  }

  async setDoc<T extends WithFieldValue<DocumentData>>(docPath: string, data: T) {
    const resolvedPath = await firstValueFrom(this.pathProvider.getPath(docPath));
    const docRef = doc(this.firestore, resolvedPath);
    await setDoc(docRef, data);
    return docRef;
  }

  async updateDoc<T>(path: string, change: Partial<T>) {
    const resolvedPath = await firstValueFrom(this.pathProvider.getPath(path));
    return updateDoc(doc(this.firestore, resolvedPath), change);
  }

  async deleteDoc(path: string) {
    const resolvedPath = await firstValueFrom(this.pathProvider.getPath(path));
    return deleteDoc(doc(this.firestore, resolvedPath));
  }

  getDocsByCollectionGroup<T extends DocumentData>(
    collectionId: string,
    ...queryConstraints: QueryConstraint[]
  ): Observable<FirestoreEntity<T>[]> {
    return collectionSnapshots(
      query(collectionGroup(this.firestore, collectionId), ...queryConstraints)
    ).pipe(map((docs) => docs.map((doc) => hydrate<T>(doc as QueryDocumentSnapshot<T>))));
  }

  async uploadDocument(file: File, storagePath: string, metadata: UploadMetadata | undefined) {
    const path = await firstValueFrom(this.pathProvider.getPath(storagePath));
    const fileRef = ref(this.storage, `${path}/${file.name}`);
    return uploadBytes(fileRef, file, metadata);
  }

  async getDownloadURL(storagePath: string) {
    const path = await firstValueFrom(this.pathProvider.getPath(storagePath));
    const fileRef = ref(this.storage, `${path}`);
    return getDownloadURL(fileRef);
  }

  async getMetadata(storagePath: string) {
    const path = await firstValueFrom(this.pathProvider.getPath(storagePath));
    const fileRef = ref(this.storage, `${path}`);
    return getMetadata(fileRef);
  }
}
