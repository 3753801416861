import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-divider-button',
    templateUrl: './divider-button.component.html',
    standalone: true,
})
export class DividerButtonComponent {
  @Input()
  buttonText!: string;

  @Output()
  buttonClick = new EventEmitter();
}
