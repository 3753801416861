import { Component } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { DocumentType, FirestoreDocument } from 'commons';
import { DocumentService } from '../../../services/document.service';
import { map, tap } from 'rxjs/operators';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DocumentCardComponent } from './document-card/document-card.component';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  standalone: true,
  imports: [NgIf, DocumentCardComponent, NgIconWrapperComponent, AsyncPipe],
})
export class InboxComponent {
  documents$: Observable<FirestoreDocument[]>;

  searchValue = new BehaviorSubject<string | null>(null);
  limitRows = new BehaviorSubject<boolean>(true);
  typeFilter = new BehaviorSubject<DocumentType[]>(['revenue', 'expense', 'other']);

  constructor(private documentService: DocumentService) {
    this.documents$ = combineLatest([
      combineLatest([this.limitRows, this.typeFilter]).pipe(
        switchMap(([limitRows, typeFilter]) =>
          this.documentService.getDocumentsInArchive(null, false, typeFilter, limitRows ? 10 : 0)
        )
      ),
      this.searchValue.pipe(
        tap((x) => {
          if (x) {
            this.limitRows.next(false);
          }
        })
      ),
    ]).pipe(
      map(([documents, searchValue]) => {
        return searchValue
          ? documents.filter((document) => {
              return (
                document.data.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                (document.data.documentText
                  ? document.data.documentText.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
                  : false)
              );
            })
          : documents;
      })
    );
  }
}
