import { Component } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { FirestoreDocument } from 'commons';
import { DocumentService } from '../../../services/document.service';
import { map, tap } from 'rxjs/operators';
import { DocumentCardComponent } from './document-card/document-card.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-accounting',
    templateUrl: './accounting.component.html',
    standalone: true,
    imports: [
        NgIf,
        DocumentCardComponent,
        AsyncPipe,
    ],
})
export class AccountingComponent {
  documents$: Observable<FirestoreDocument[]>;

  searchValue = new BehaviorSubject<string | null>(null);
  limitRows = new BehaviorSubject<boolean>(true);

  constructor(private documentService: DocumentService) {
    this.documents$ = combineLatest([
      this.documentService.getDocumentsNotProcessedByAccountant(),
      this.searchValue.pipe(
        tap((x) => {
          if (x) {
            this.limitRows.next(false);
          }
        })
      ),
    ]).pipe(
      map(([documents, searchValue]) => {
        return searchValue
          ? documents.filter((document) => {
              return (
                document.data.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                (document.data.documentText
                  ? document.data.documentText.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
                  : false)
              );
            })
          : documents;
      })
    );
  }
}
