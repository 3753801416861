<app-settings-card
  heading="Archivordner"
  description="Organisiere deine Dokumente mithilfe von Ordner. Nur leere Ordner können gelöscht werden."
  [showSaveButton]="false"
  [disabled]="disabled">
  <div
    class="mt-4 overflow-hidden rounded-md border border-gray-300 bg-white"
    *ngIf="folders.length > 0">
    <ul
      role="list"
      class="divide-y divide-gray-300"
      cdkDropList
      (cdkDropListDropped)="drop($event)">
      <li
        class="flex justify-between items-center"
        [class.italic]="!folder.active"
        [class.text-gray-600]="!folder.active"
        [class.bg-gray-50]="!folder.active"
        *ngFor="let folder of folders"
        cdkDrag
        cdkDragLockAxis="y">
        <div class="px-6 py-4 w-full flex flex-row justify-between items-center">
          <div>
            <p *ngIf="folder.id !== editId">
              {{ folder.name }}
            </p>
            <app-input
              style="margin: -10px; min-width: 100%"
              *ngIf="folder.id === editId"
              class="block"
              ><input
                appTailwindInput
                type="text"
                [(ngModel)]="folder.name"
                (blur)="editFolder(null)"
                autofocus
            /></app-input>
          </div>
          <div class="text-gray-900 text-xs mr-4" *ngIf="folder.documentCount > 0">
            {{ folder.documentCount }}
            <span class="text-gray-600">{{
              folder.documentCount === 1 ? 'Dokument' : 'Dokumente'
            }}</span>
          </div>
        </div>

        <app-dropdown-minimal-menu *ngIf="!disabled">
          <app-dropdown-button>
            <app-ng-icon
              name="heroEllipsisVerticalSolid"
              class="text-3xl"
              strokeWidth="3"></app-ng-icon>
          </app-dropdown-button>
          <app-dropdown-item (menuClick)="editFolder(folder.id)"> Umbennen </app-dropdown-item>
          <app-dropdown-item
            (menuClick)="deleteFolder(folder.id)"
            *ngIf="folder.documentCount === 0">
            Löschen
          </app-dropdown-item>
          <app-dropdown-item (menuClick)="folderState(folder.id, false)" *ngIf="folder.active">
            Ausblenden
          </app-dropdown-item>
          <app-dropdown-item (menuClick)="folderState(folder.id, false)" *ngIf="!folder.active">
            Einblenden
          </app-dropdown-item>
        </app-dropdown-minimal-menu>
      </li>
    </ul>
  </div>
  <button
    appTailwindButton
    (click)="addNewFolder()"
    color="white"
    [size]="'xs'"
    class="mt-2"
    [disabled]="disabled">
    Ordner erstellen
  </button>
</app-settings-card>
