import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { TabOldDirective } from './tab.directive';
import { NgIf, NgFor, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgTemplateOutlet,
    ],
})
export class TabsComponent implements AfterContentInit {
  @Input()
  activeTab = 0;

  @ContentChildren(TabOldDirective, { read: TemplateRef })
  templateRefChildren!: QueryList<TemplateRef<any>>;
  @ContentChildren(TabOldDirective) titleChildren!: QueryList<TabOldDirective>;

  ngAfterContentInit(): void {}

  get titles(): string[] {
    return this.titleChildren.map((templateTitle) => templateTitle.title);
  }

  get templates(): TemplateRef<any>[] {
    return this.templateRefChildren.toArray();
  }
}
