import { Component, OnInit } from '@angular/core';
import { firstValueFrom, Observable, shareReplay } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { FirestoreProject, Position, PositionType } from 'commons';
import { PositionSlideOverService } from '../positions/position-slide-over/position-slide-over.component';
import { AsyncPipe, DecimalPipe, NgFor, NgIf } from '@angular/common';
import { EmptyComponent } from '../../../../components/empty/empty.component';
import { PositionCardComponent } from '../positions/position-card/position-card.component';
import { DividerButtonComponent } from '../../../../components/divider-button/divider-button.component';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { FormatWorkPipe } from '../../../../pipes/format-work.pipe';
import { ProjectService } from '../../../../services/project.service';
import { WorkSlideOverService } from '../../slide-overs/work-slide-over.component';

@Component({
  selector: 'app-project-structure',
  templateUrl: './project-structure.component.html',
  standalone: true,
  imports: [
    NgIf,
    EmptyComponent,
    NgFor,
    PositionCardComponent,
    DividerButtonComponent,
    NgIconWrapperComponent,
    AsyncPipe,
    DecimalPipe,
    FormatWorkPipe,
  ],
})
export class ProjectStructureComponent implements OnInit {
  project$!: Observable<FirestoreProject>;
  positions$!: Observable<Position[]>;

  totals$!: Observable<{ totalWorkedMinutes: number; total: number; totalExclVat: number }>;
  totalBilled!: Observable<number>;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private positionSlideOver: PositionSlideOverService,
    private workSlideOver: WorkSlideOverService
  ) {}

  async ngOnInit() {
    this.project$ = this.projectService
      .getProject(this.route.parent?.snapshot.params['projectId'])
      .pipe(shareReplay(1));

    this.positions$ = this.project$.pipe(
      map((project) => (project.data.positions ? Object.values(project.data.positions) : [])),
      map((positions) => positions.sort((a, b) => a.name.localeCompare(b.name)))
    );
    this.totals$ = this.positions$.pipe(
      map((positions) => {
        const totalWorkedMinutes = positions.reduce(
          (prev, curr) => prev + curr.totals.totalWorkMinutes,
          0
        );
        const total = positions.reduce((prev, curr) => {
          switch (curr.positionType) {
            case PositionType.perPiece:
              return prev;
            case PositionType.fixedPrice:
              return prev + addVat(curr.fixedPrice.amount, curr.vatRate);
            case PositionType.timeAndMaterial:
              return (
                prev +
                addVat(
                  curr.timeAndMaterial.hourlyRate * (curr.totals.totalWorkMinutes / 60),
                  curr.vatRate
                )
              );
          }
        }, 0);
        const totalExclVat = positions.reduce((prev, curr) => {
          switch (curr.positionType) {
            case PositionType.perPiece:
              return prev;
            case PositionType.fixedPrice:
              return prev + curr.fixedPrice.amount;
            case PositionType.timeAndMaterial:
              return prev + curr.timeAndMaterial.hourlyRate * (curr.totals.totalWorkMinutes / 60);
          }
        }, 0);

        return {
          totalWorkedMinutes,
          total,
          totalExclVat,
        };
      })
    );
    this.totalBilled = this.project$.pipe(map((project) => project.data.totalBilled ?? 0));
  }

  async modifyPosition(position: Position) {
    this.positionSlideOver.edit(position, {
      project: await firstValueFrom(this.project$),
    });
  }

  async createWork(position: Position) {
    this.workSlideOver.new({
      project: await firstValueFrom(this.project$),
      position: position,
    });
  }

  async createPosition() {
    this.positionSlideOver.new({
      project: await firstValueFrom(this.project$),
    });
  }
}

function addVat(amount: number, vat: number) {
  return amount + (amount * vat) / 100;
}
