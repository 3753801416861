<div class="flex flex-col">
  <app-input class="relative">
    <textarea
      appTailwindInput
      rows="5"
      class="resize-none"
      placeholder="neuer Kommentar..."
      [(ngModel)]="comment"
      type="text"
      [mentionConfig]="{ items: userList, labelKey: 'displayName' }">
    </textarea>
    <button
      type="button"
      class="absolute bottom-0 right-0 mb-3 mr-2"
      appTailwindButton
      color="secondary"
      (click)="create()"
      [disabled]="!comment">
      <app-ng-icon class="text-lg" strokeWidth="2" name="heroPaperAirplane"></app-ng-icon>
    </button>
  </app-input>
</div>
