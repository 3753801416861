import { Component, Input } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { icons } from '../../../icon.config';

/*
 * delete this when angular is updated so we can use the global setting in ng-icon itself
 * */
@Component({
  selector: 'app-ng-icon',
  template: `<div class="flex justify-center items-center">
    <ng-icon [name]="name" [strokeWidth]="strokeWidth"></ng-icon>
  </div>`,
  standalone: true,
  providers: [provideIcons(icons)],
  imports: [NgIconComponent],
})
export class NgIconWrapperComponent {
  @Input() name!: keyof typeof icons;
  @Input() strokeWidth = '1.5';
}
