import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from '../../../services/customer.service';
import { Customer, CustomerType, FirestoreCustomer } from 'commons';
import { SlideOverService } from '../../../components/slide-overs/slide-over/slide-over-service';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { SlideOverFooterComponent } from '../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { InputComponent } from '../../../components/input/input.component';
import { SlideOverContentComponent } from '../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { SlideOverHeaderComponent } from '../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { SlideOverComponent } from '../../../components/slide-overs/slide-over/slide-over.component';
import { NgIf } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class CustomerSlideOverService extends SlideOverService<
  FirestoreCustomer,
  { customerType: CustomerType },
  void
> {}

@Component({
    selector: 'app-customer-slide-over',
    templateUrl: './customer-slide-over.component.html',
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        SlideOverComponent,
        SlideOverHeaderComponent,
        SlideOverContentComponent,
        InputComponent,
        TailwindInputDirective,
        SlideOverFooterComponent,
        TailwindButtonDirective,
    ],
})
export class CustomerSlideOverComponent implements OnInit {
  customerForm!: FormGroup;
  customerAddressForm!: FormGroup;

  customerType!: CustomerType;
  errors?: any;

  // for the future: use https://brandfetch.com/ to fetch logos

  constructor(
    private customerService: CustomerService,
    public customerSlideOverService: CustomerSlideOverService
  ) {}

  ngOnInit(): void {
    this.customerType =
      this.customerSlideOverService.getMode() === 'new'
        ? this.customerSlideOverService.getNewMeta().customerType
        : this.customerSlideOverService.getEntity().data.type;
    this.initForm();
  }

  initForm() {
    switch (this.customerType) {
      case 'company':
        this.customerAddressForm = this.getAddressFormGroup();
        this.customerForm = new FormGroup({
          type: new FormControl(this.customerType, [Validators.required]),
          company: new FormGroup({
            displayName: new FormControl(
              this.customerSlideOverService.getMode() === 'edit'
                ? this.customerSlideOverService.getEntity().data.displayName
                : '',
              [Validators.required]
            ),
            legalName: new FormControl(
              this.customerSlideOverService.getMode() === 'edit'
                ? this.customerSlideOverService.getEntity().data.company.legalName
                : '',
              []
            ),
          }),
          address: this.customerAddressForm,
        });
        break;
      case 'individual':
        this.customerAddressForm = this.getAddressFormGroup();
        this.customerForm = new FormGroup({
          type: new FormControl(this.customerType, []),
          individual: new FormGroup({
            firstName: new FormControl(
              this.customerSlideOverService.getMode() === 'edit'
                ? this.customerSlideOverService.getEntity().data.individual.firstName
                : '',
              [Validators.required]
            ),
            lastName: new FormControl(
              this.customerSlideOverService.getMode() === 'edit'
                ? this.customerSlideOverService.getEntity().data.individual.lastName
                : '',
              [Validators.required]
            ),
          }),
          address: this.customerAddressForm,
        });
        break;
    }
  }

  private getAddressFormGroup() {
    return new FormGroup({
      addressLine1: new FormControl(
        this.customerSlideOverService.getMode() === 'edit'
          ? this.customerSlideOverService.getEntity().data.address.addressLine1
          : '',
        []
      ),
      addressLine2: new FormControl(
        this.customerSlideOverService.getMode() === 'edit'
          ? this.customerSlideOverService.getEntity().data.address.addressLine2
          : '',
        []
      ),
      zipCode: new FormControl(
        this.customerSlideOverService.getMode() === 'edit'
          ? this.customerSlideOverService.getEntity().data.address.zipCode
          : '',
        []
      ),
      city: new FormControl(
        this.customerSlideOverService.getMode() === 'edit'
          ? this.customerSlideOverService.getEntity().data.address.city
          : '',
        []
      ),
      countryCode: new FormControl(
        this.customerSlideOverService.getMode() === 'edit'
          ? this.customerSlideOverService.getEntity().data.address.countryCode
          : 'CH',
        [Validators.required, Validators.maxLength(3)]
      ),
    });
  }

  createOrUpdateCustomer(value: Customer) {
    this.customerForm.markAllAsTouched();
    if (this.customerForm.invalid) {
      return;
    }

    value = {
      ...value,
      displayName:
        value.type === 'company'
          ? value.company.displayName
          : value.individual.firstName + ' ' + value.individual.lastName,
    };
    if (this.customerSlideOverService.getMode() === 'new') {
      this.customerService.createCustomer(value);
    } else {
      this.customerService.updateCustomer(this.customerSlideOverService.getEntity().id, value);
    }
    this.closeSlideOver();
  }

  closeSlideOver() {
    this.customerSlideOverService.closeSlideOver();
  }
}
