import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Position, PositionType } from 'commons';
import { RouterLink } from '@angular/router';
import { DecimalPipe, NgIf } from '@angular/common';
import { PositionTypeBadgeComponent } from '../position-type-badge/position-type-badge.component';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { TailwindButtonDirective } from '../../../../../components/button/tailwind-button.directive';
import { DropdownMinimalMenuComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { DropdownButtonComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownItemComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { FormatWorkPipe } from '../../../../../pipes/format-work.pipe';

@Component({
  selector: 'app-position-card',
  templateUrl: './position-card.component.html',
  standalone: true,
  imports: [
    PositionTypeBadgeComponent,
    NgIf,
    NgIconWrapperComponent,
    TailwindButtonDirective,
    RouterLink,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    DropdownItemComponent,
    DecimalPipe,
    FormatWorkPipe,
  ],
})
export class PositionCardComponent implements OnChanges {
  positionType = PositionType;

  @Input()
  position!: Position;

  @Input()
  projectId!: string;

  @Input()
  showAction = true;

  @Output()
  modifyPosition = new EventEmitter<Position>();

  @Output()
  createWork = new EventEmitter<Position>();

  @Output()
  showDetails = new EventEmitter<Position>();

  totalWork = 0;

  ngOnChanges(changes: SimpleChanges) {
    switch (this.position.positionType) {
      case PositionType.fixedPrice:
        break;
      case PositionType.perPiece:
        break;
      case PositionType.timeAndMaterial:
        this.totalWork =
          (this.position.totals.totalWorkMinutes / 60) * this.position.timeAndMaterial.hourlyRate;
        break;
    }
  }
}
