import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { InputComponent } from '../../../components/input/input.component';
import { AuthService } from '../../../services/auth.service';
import { AlertComponent } from '../../../components/alert/alert.component';
import { DialogComponent } from '../../../components/dialog/dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    TailwindInputDirective,
    TailwindButtonDirective,
    NgIf,
    NgIconWrapperComponent,
    AlertComponent,
    DialogComponent,
  ],
})
export class LoginComponent {
  showWaitDialog = false;
  showErrorDialog = false;

  disableButton = false;

  error = '';

  mailAddress = new FormControl('', [Validators.required]);
  loginForm = new FormGroup({
    mailAddress: this.mailAddress,
  });

  constructor(private authService: AuthService) {}

  async login() {
    this.loginForm.markAllAsTouched();
    if (!this.loginForm.valid) {
      return;
    }
    this.disableButton = true;
    const email = this.loginForm.value.mailAddress;

    try {
      await this.authService.signInEmail(email!, true);
      this.showWaitDialog = true;
    } catch (e) {
      this.showWaitDialog = false;

      // @ts-ignore
      this.error = e.message;
      this.showErrorDialog = true;
      console.error(e);
    }
  }

  closeModal() {
    this.showWaitDialog = false;
    this.showErrorDialog = false;
    this.disableButton = false;
  }
}
