import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Orga } from 'commons';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { InputComponent } from '../../../components/input/input.component';
import { SettingsCardComponent } from '../settings-card/settings-card.component';

@Component({
    selector: 'app-orga-address-card',
    templateUrl: './orga-address-card.component.html',
    standalone: true,
    imports: [
        SettingsCardComponent,
        ReactiveFormsModule,
        InputComponent,
        TailwindInputDirective,
    ],
})
export class OrgaAddressCardComponent implements OnInit {
  @Input()
  orga!: Orga;

  @Input()
  disabled = false;

  @Output()
  addressChanged = new EventEmitter<Partial<Orga>>();

  addressForm!: FormGroup;

  ngOnInit(): void {
    this.addressForm = new FormGroup({
      addressLine1: new FormControl(this.orga.address?.addressLine1),
      addressLine2: new FormControl(this.orga.address?.addressLine2),
      zipCode: new FormControl(this.orga.address?.zipCode),
      city: new FormControl(this.orga.address?.city),
      countryCode: new FormControl(this.orga.address?.countryCode, [Validators.maxLength(3)]),
    });
  }

  saveSettings() {
    if (!this.addressForm.invalid) {
      this.addressChanged.emit({ address: this.addressForm.value });
    }
  }
}
