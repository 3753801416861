import { inject, Injectable } from '@angular/core';
import { FirestoreUser, Permission, User } from 'commons';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FIRESTORE_SERVICE } from './firestore.service';
import { where } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private firestoreService = inject(FIRESTORE_SERVICE);

  constructor() {}

  getUser(userId: string): Observable<FirestoreUser> {
    return this.firestoreService.getDoc<User>(`user/${userId}`);
  }

  getUserByAuthUserUd(authUserId: string): Observable<FirestoreUser> {
    return this.firestoreService.getDocs<User>(`user`, where('authUserUid', '==', authUserId)).pipe(
      filter((users) => users && users.length > 0),
      map((users) => users[0])
    );
  }

  update(userId: string, change: Partial<User>): Promise<void> {
    return this.firestoreService.updateDoc<User>(`user/${userId}`, change);
  }

  getPermission(userId: string): Observable<Permission> {
    return this.firestoreService
      .getDoc<Permission>(`user/${userId}/permission/${userId}`)
      .pipe(map((firestorePermission) => firestorePermission.data));
  }
}
