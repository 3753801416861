import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SidebarNavigationEntryComponent } from '../../components/container/sidebar-navigation/sidebar-navigation-entry/sidebar-navigation-entry.component';
import { SidebarNavigationComponent } from '../../components/container/sidebar-navigation/sidebar-navigation.component';
import { TailwindButtonDirective } from '../../components/button/tailwind-button.directive';
import { HeaderMetaActionComponent } from '../../components/container/header-meta-action/header-meta-action.component';
import { ContainerComponent } from '../../components/container/container.component';
import { ProjectSlideOverService } from './slide-overs/project-slide-over.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  standalone: true,
  imports: [
    ContainerComponent,
    HeaderMetaActionComponent,
    TailwindButtonDirective,
    SidebarNavigationComponent,
    SidebarNavigationEntryComponent,
    RouterLink,
    RouterOutlet,
  ],
})
export class ProjectsComponent {
  constructor(private projectSlideOver: ProjectSlideOverService) {}

  createProject() {
    this.projectSlideOver.new();
  }
}
