<div *ngIf="titles.length > 1">
  <div class="block">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex">
        <ng-container *ngFor="let title of titles; index as i">
          <a
            *ngIf="i === activeTab; else notActive"
            class="whitespace-nowrap ml-8 py-4 px-1 border-b-2 border-blue-500 font-medium text-sm leading-5 text-blue-600 focus:outline-none focus:text-blue-800 focus:border-blue-700"
            aria-current="page"
            [innerHTML]="title">
          </a>
          <ng-template #notActive>
            <a
              (click)="activeTab = i"
              [innerHTML]="title"
              class="whitespace-nowrap ml-8 py-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 hover:cursor-pointer focus:outline-none focus:text-gray-700 focus:border-gray-300">
            </a>
          </ng-template>
        </ng-container>
      </nav>
    </div>
  </div>
</div>

<div class="min-h-0 flex-1 overflow-hidden">
  <!-- using an ng-container allows us to render the template without any additional wrapping -->
  <ng-container [ngTemplateOutlet]="templates[activeTab]"></ng-container>
</div>
