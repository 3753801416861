<app-container *ngIf="customer$ | async as customer" [subpage]="true">
  <app-header-meta-action
    [title]="customer.data.displayName"
    [subTitle]="customer.data.address.city">
    <button appTailwindButton color="secondary" (click)="editCustomer()">Bearbeiten</button>
  </app-header-meta-action>

  <div class="mt-4">
    <app-tabs>
      <ng-template appTabOld="Projekte">
        <ng-container *ngIf="projects$ | async as projects">
          <div
            class="mt-2 bg-white shadow overflow-hidden sm:rounded-md"
            *ngIf="projects.length > 0; else noProjects">
            <ul>
              <li
                app-project-list-entry
                [project]="project"
                [ngClass]="{ 'border-t border-gray-200': !isFirst }"
                *ngFor="let project of projects; first as isFirst"></li>
            </ul>
          </div>
          <ng-template #noProjects>
            <app-empty
              (buttonClick)="newProject()"
              message="Erstelle eine neues Projekt für diesen Kunden"></app-empty>
          </ng-template>
        </ng-container>
      </ng-template>
      <ng-template appTabOld="Dokumente">
        <ng-container *ngIf="documents$ | async as documents">
          <div
            class="mt-2 bg-white shadow overflow-hidden sm:rounded-md"
            *ngIf="documents.length > 0; else noDocuments">
            <app-document-list [documents]="documents">
              <li
                *ngFor="let document of documents"
                app-document-list-item
                [id]="document.id"
                [name]="document.data.name"
                [documentDate]="document.data.documentDate"
                [accounting]="document.data.accounting.relevant"
                [accountingProcessed]="document.data.accounting.processed"
                [archiveFolder]="document.data.folder"
                (editDocument)="editDocument(document)"></li>
            </app-document-list>
          </div>
          <ng-template #noDocuments>
            <app-empty message="Noch keine Dokumente mit diesem Kunden verknüpft"></app-empty>
          </ng-template>
        </ng-container>
      </ng-template>
      <ng-container *appHideWhenNotInPlan="['business', 'enterprise']">
        <ng-template
          appTabOld="Kommentare <small>({{ customer.data.comments?.count ?? 0 }})</small>">
          <div class="flex w-full justify-center">
            <app-comment-section
              class="bg-white shadow-md rounded-md flex-1 block max-w-5xl px-10 mt-4"
              [parentEntity]="customer"></app-comment-section>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *appHideWhenNotInPlan="['business', 'enterprise']">
        <ng-template appTabOld="Änderungen">
          <app-history [forId]="customer.id"></app-history>
        </ng-template>
      </ng-container>
    </app-tabs>
  </div>
</app-container>
