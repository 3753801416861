import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ToggleComponent,
    },
  ],
  standalone: true,
  imports: [NgIf],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() label!: string;
  @Input() additionalLabel!: string;
  @Input() displayShort = false;
  @Input() disabled = false;

  @Input() value = false;

  @Output() valueChange = new EventEmitter<boolean>();

  isDisabled = false;

  onChange = (isActive: boolean) => {};

  toggle(): void {
    this.value = !this.value;
    this.valueChange.emit(this.value);
    this.onChange(this.value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: any) {}

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(isActive: boolean) {
    this.value = isActive;
  }
}
