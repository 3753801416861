import { Component, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { toNumber } from 'lodash';
import { FirestoreOrga, FirestoreProject, Position, PositionType } from 'commons';
import { firstValueFrom } from 'rxjs';
import { NgIf } from '@angular/common';
import { filter } from 'rxjs/operators';
import { SlideOverService } from '../../../../../components/slide-overs/slide-over/slide-over-service';
import { SlideOverComponent } from '../../../../../components/slide-overs/slide-over/slide-over.component';
import { SlideOverHeaderComponent } from '../../../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { SlideOverContentComponent } from '../../../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { InputComponent } from '../../../../../components/input/input.component';
import { TailwindInputDirective } from '../../../../../components/input/tailwind-input.directive';
import { CurrencyInputComponent } from '../../../../../components/input/currency-input.component';
import { SlideOverFooterComponent } from '../../../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { TailwindButtonDirective } from '../../../../../components/button/tailwind-button.directive';
import { ProjectService } from '../../../../../services/project.service';
import { SessionStateService } from '../../../../../services/session-state.service';

@Injectable({
  providedIn: 'root',
})
export class PositionSlideOverService extends SlideOverService<
  Position,
  { project: FirestoreProject },
  { project: FirestoreProject }
> {}

@Component({
  selector: 'app-position-slide-over',
  templateUrl: './position-slide-over.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SlideOverComponent,
    NgIf,
    SlideOverHeaderComponent,
    SlideOverContentComponent,
    InputComponent,
    TailwindInputDirective,
    CurrencyInputComponent,
    SlideOverFooterComponent,
    TailwindButtonDirective,
  ],
})
export class PositionSlideOverComponent implements OnInit {
  positionType = PositionType;

  positionForm!: FormGroup;

  projectId!: string;
  positionId!: string;

  constructor(
    private projectService: ProjectService,
    public positionSlideOver: PositionSlideOverService,
    private sessionState: SessionStateService
  ) {}

  ngOnInit() {
    this.initForm();
    if (this.positionSlideOver.getMode() === 'edit') {
      this.initFormValues(this.positionSlideOver.getEntity());
    }
  }

  initForm() {
    this.positionForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      positionType: new FormControl(PositionType.timeAndMaterial, [Validators.required]),
      description: new FormControl('', [Validators.maxLength(500)]),
      billable: new FormControl(true),
      amount: new FormControl(''),
      hourlyRate: new FormControl(''),
      hours: new FormControl(''),
      minutes: new FormControl(''),
      closed: new FormControl(false),
    });
    // this.addValidators(this.positionForm.get('positionType')?.value);
    // this.subscriptions.add(
    //   this.positionForm.get('positionType')?.valueChanges.subscribe((positionType) => {
    //     setTimeout(() => this.addValidators(positionType), 10);
    //   })
    // );
  }

  private addValidators(positionType: PositionType) {
    const amount = this.positionForm.get('amount');
    const hourlyRate = this.positionForm.get('hourlyRate');

    this.positionForm.patchValue({
      amount: null,
      hourlyRate: null,
      hours: null,
      minutes: null,
    });

    if (positionType === PositionType.fixedPrice) {
      amount?.setValidators([Validators.required]);
      hourlyRate?.clearValidators();
    } else if (positionType === PositionType.timeAndMaterial) {
      amount?.clearValidators();
      hourlyRate?.setValidators([Validators.required]);
    }
  }

  private initFormValues(position: Position) {
    this.positionForm.setValue({
      name: position.name,
      positionType: position.positionType,
      description: position.description,
      billable: position.billable,
      amount: position.fixedPrice.amount,
      hourlyRate: position.timeAndMaterial.hourlyRate,
      hours: position.timeAndMaterial?.expectedWorkMinutes
        ? Math.floor(position.timeAndMaterial?.expectedWorkMinutes / 60)
        : null,
      minutes: position.timeAndMaterial?.expectedWorkMinutes
        ? position.timeAndMaterial?.expectedWorkMinutes % 60
        : null,
      closed: position.closed,
    });
  }

  closeSlideOver() {
    this.positionSlideOver.closeSlideOver();
  }

  createOrUpdatePosition(position: Position) {
    this.positionForm.markAllAsTouched();
    if (!this.positionForm.valid) {
      return;
    }
    if (this.positionSlideOver.getMode() === 'new') {
      this.createPosition(this.positionSlideOver.getNewMeta().project.id, position);
    } else {
      this.updatePosition(
        this.positionSlideOver.getEditMeta().project.id,
        this.positionSlideOver.getEntity().id,
        position
      );
    }
  }

  private async createPosition(projectId: string, formValue: any) {
    const position = await this.hydrateNewPosition(crypto.randomUUID(), formValue);
    this.projectService.createPosition(projectId, position).then(() => this.closeSlideOver());
  }

  private updatePosition(projectId: string, positionId: string, formValue: any) {
    this.projectService
      .updatePosition(projectId, positionId, this.hydratePosition(formValue))
      .then(() => this.closeSlideOver());
  }

  hydratePosition(formValue: any): Partial<Position> {
    return {
      name: formValue.name,
      positionType: formValue.positionType,
      description: formValue.description,
      closed: formValue.closed,
      billable: formValue.billable,
      fixedPrice: {
        amount: toNumber(formValue.amount),
      },
      timeAndMaterial: {
        hourlyRate: toNumber(formValue.hourlyRate),
        expectedAmount: this.getCalculatedAmount(),
        expectedWorkMinutes: this.getTotalMinutes(),
      },
    };
  }

  async hydrateNewPosition(positionId: string, formValue: any): Promise<Position> {
    const orga = await firstValueFrom(
      this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga))
    );
    return {
      id: positionId,
      name: formValue.name,
      positionType: formValue.positionType,
      description: formValue.description,
      closed: formValue.closed,
      billable: formValue.billable,
      fixedPrice: {
        amount: toNumber(formValue.amount),
      },
      timeAndMaterial: {
        hourlyRate: toNumber(formValue.hourlyRate),
        expectedAmount: this.getCalculatedAmount(),
        expectedWorkMinutes: this.getTotalMinutes(),
      },
      vatRate: orga.data.invoiceDetails.defaultVatRate,
      totals: {
        totalWorkMinutes: 0,
        totalBilledWorkMinutes: 0,
      },
    };
  }

  getCalculatedAmount(): number | null {
    const hourlyRate = toNumber(this.positionForm.get('hourlyRate')?.value);
    return hourlyRate && this.getTotalHours() ? hourlyRate * this.getTotalHours() : 0;
  }

  getTotalMinutes() {
    const hours = toNumber(this.positionForm.get('hours')?.value);
    const minutes = toNumber(this.positionForm.get('minutes')?.value) || 0;
    return hours * 60 + minutes;
  }

  getTotalHours() {
    return this.getTotalMinutes() / 60;
  }
}
