import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FirestoreInvoice } from 'commons';
import { NgIf, DecimalPipe } from '@angular/common';
import { DropdownItemComponent } from '../../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DropdownButtonComponent } from '../../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';

@Component({
    selector: 'li[app-invoice-list-entry]',
    templateUrl: './invoice-list-entry.component.html',
    standalone: true,
    imports: [
        DropdownMinimalMenuComponent,
        DropdownButtonComponent,
        NgIconWrapperComponent,
        DropdownItemComponent,
        NgIf,
        DecimalPipe,
    ],
})
export class InvoiceListEntryComponent {
  @Input()
  invoice!: FirestoreInvoice;

  @Output()
  editInvoice = new EventEmitter<FirestoreInvoice>();

  @Output()
  deleteInvoice = new EventEmitter<FirestoreInvoice>();

  @Output()
  downloadInvoice = new EventEmitter<FirestoreInvoice>();
}
