import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { AsyncPipe, formatDate, NgIf } from '@angular/common';
import { CreateWorkComponent } from '../create-work/create-work.component';
import { CalendarComponent } from '../../../components/calendar/calendar/calendar.component';
import { WorkTimerComponent } from '../work-timer/work-timer.component';
import { DocumentCardComponent } from '../../document/list/document-card/document-card.component';
import { HeaderMetaActionComponent } from '../../../components/container/header-meta-action/header-meta-action.component';
import { ContainerComponent } from '../../../components/container/container.component';
import { SidebarNavigationComponent } from '../../../components/container/sidebar-navigation/sidebar-navigation.component';
import { SidebarNavigationEntryComponent } from '../../../components/container/sidebar-navigation/sidebar-navigation-entry/sidebar-navigation-entry.component';
import { RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FirestoreUser } from 'commons';
import { firstValueFrom } from 'rxjs';
import { SessionStateService } from '../../../services/session-state.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  standalone: true,
  imports: [
    ContainerComponent,
    HeaderMetaActionComponent,
    NgIf,
    DocumentCardComponent,
    WorkTimerComponent,
    CalendarComponent,
    CreateWorkComponent,
    AsyncPipe,
    SidebarNavigationComponent,
    SidebarNavigationEntryComponent,
    RouterOutlet,
  ],
})
export class HomePage implements OnInit {
  showTimer = false;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private sessionState: SessionStateService
  ) {}

  async ngOnInit(): Promise<void> {
    const user = this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user));
    this.showTimer = (await firstValueFrom(user)).data.enableTimer;
  }

  today(): string {
    return formatDate(new Date(), 'd. MMMM y', this.locale);
  }
}
