<form
  class="form-container pl-3"
  [formGroup]="customerForm"
  (ngSubmit)="createOrUpdateCustomer(customerForm.value)"
  autocomplete="off"
  novalidate
  *ngIf="customerForm">
  <app-slide-over>
    <app-slide-over-header
      (closeSlideOver)="closeSlideOver()"
      [formDirty]="customerForm.dirty"
      [title]="customerType === 'individual' ? 'Person' : 'Firma'">
    </app-slide-over-header>

    <app-slide-over-content>
      <div class="px-4 sm:px-6">
        <ng-container *ngIf="customerType == 'company'">
          <div class="space-y-6 pt-6 pb-5" formGroupName="company">
            <div class="space-y-1">
              <app-input
                label="Kurzname"
                class="mt-4"
                [errors]="
                  customerForm.get('company.displayName')?.touched
                    ? customerForm.get('company.displayName')?.errors
                    : null
                "
                ><input appTailwindInput formControlName="displayName" type="text"
              /></app-input>
            </div>
            <div class="space-y-1">
              <app-input label="Firmenname (Rechtsgültig)" class="mt-4"
                ><input appTailwindInput formControlName="legalName" type="text"
              /></app-input>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="customerType == 'individual'">
          <div class="space-y-6 pt-6 pb-5" formGroupName="individual">
            <div class="space-y-1">
              <app-input
                label="Vorname"
                class="mt-4"
                [errors]="
                  customerForm.get('individual.firstName')?.touched
                    ? customerForm.get('individual.firstName')?.errors
                    : null
                "
                ><input appTailwindInput formControlName="firstName" type="text"
              /></app-input>
            </div>
            <div class="space-y-1">
              <app-input
                label="Nachname"
                class="mt-4"
                [errors]="
                  customerForm.get('individual.lastName')?.touched
                    ? customerForm.get('individual.lastName')?.errors
                    : null
                "
                ><input appTailwindInput formControlName="lastName" type="text"
              /></app-input>
            </div>
          </div>
        </ng-container>

        <div class="mt-6 grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6" formGroupName="address">
          <div class="sm:col-span-6">
            <app-input label="Adresszeile 1">
              <input type="text" appTailwindInput [formControlName]="'addressLine1'" />
            </app-input>
          </div>
          <div class="sm:col-span-6">
            <app-input label="Adresszeile 2">
              <input type="text" appTailwindInput [formControlName]="'addressLine2'" />
            </app-input>
          </div>
          <div class="sm:col-span-2">
            <app-input label="Plz">
              <input type="text" appTailwindInput [formControlName]="'zipCode'" />
            </app-input>
          </div>
          <div class="sm:col-span-4">
            <app-input label="Ort">
              <input type="text" appTailwindInput [formControlName]="'city'" />
            </app-input>
          </div>
          <div class="sm:col-span-2">
            <app-input
              label="Land (Code)"
              [errors]="
                customerAddressForm.controls['countryCode']?.touched
                  ? customerAddressForm.controls['countryCode']?.errors
                  : null
              ">
              <input type="text" appTailwindInput [formControlName]="'countryCode'" />
            </app-input>
          </div>
        </div>
      </div>
    </app-slide-over-content>

    <app-slide-over-footer>
      <span class="inline-flex rounded-md shadow-sm">
        <button appTailwindButton color="secondary" type="button" (click)="closeSlideOver()">
          Abbrechen
        </button>
      </span>
      <span class="inline-flex rounded-md shadow-sm">
        <button appTailwindButton type="submit">Speichern</button>
      </span>
    </app-slide-over-footer>
  </app-slide-over>
</form>
