import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  Comment,
  FirestoreComment,
  FirestoreEntity,
  FirestoreOrgaUser,
  FirestoreUser,
} from 'commons';
import { CommentService } from '../../../services/comment.service';
import { map, Observable } from 'rxjs';
import { OrgaUserService } from '../../../services/orga-user.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AsyncPipe } from '@angular/common';
import { CommentListComponent } from '../../../components/comment-components/comment-list/comment-list.component';
import { SessionStateService } from '../../../services/session-state.service';
import { filter } from 'rxjs/operators';
import { arrayRemove } from '@angular/fire/firestore';

@Component({
  selector: 'app-comment-section',
  templateUrl: './comment-section.component.html',
  standalone: true,
  imports: [CommentListComponent, AsyncPipe],
})
export class CommentSectionComponent<T> implements OnInit, OnChanges {
  @Input() parentEntity!: FirestoreEntity<T>;
  @Input() set padded(value: any) {
    this._padded = coerceBooleanProperty(value);
  }

  _padded = false;

  comments!: Observable<FirestoreComment[]>;
  orgaUsers!: Observable<FirestoreOrgaUser[]>;
  loggedInUserId!: Observable<string>;

  constructor(
    private commentService: CommentService,
    private orgaUserService: OrgaUserService,
    private sessionState: SessionStateService
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(): void {
    this.init();
  }

  init() {
    this.comments = this.commentService.getAllComments<T>(this.parentEntity);
    this.orgaUsers = this.orgaUserService.getAllOrgaUsers();
    this.loggedInUserId = this.sessionState.getUser().pipe(
      filter((user): user is FirestoreUser => !!user),
      map((user) => user.id)
    );
  }

  async createNewComment(comment: Partial<Comment>) {
    await this.commentService.createComment(this.parentEntity, comment);
  }

  deleteComment(comment: FirestoreComment) {
    this.commentService.deleteComment(comment);
  }

  markAsReacted(reaction: { comment: FirestoreComment; userId: string }) {
    this.commentService.updateComment(reaction.comment.docRef.path, {
      // @ts-ignore
      notReactedUserIds: arrayRemove(reaction.userId),
    });
  }
}
