"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.test = exports.getInterval = exports.createDateRange = void 0;
const date_fns_1 = require("date-fns");
function createDateRange(startDate, endDate, createWorkForWeekendDays) {
    return (0, date_fns_1.eachDayOfInterval)({
        start: (0, date_fns_1.parseISO)(startDate),
        end: (0, date_fns_1.parseISO)(endDate),
    }).filter((day) => (createWorkForWeekendDays ? true : !(0, date_fns_1.isWeekend)(day)));
}
exports.createDateRange = createDateRange;
function getInterval(dateRange) {
    switch (dateRange.type) {
        case 'month':
            return {
                start: (0, date_fns_1.startOfMonth)(dateRange.month),
                end: (0, date_fns_1.endOfMonth)(dateRange.month),
            };
        case 'year':
            return {
                start: (0, date_fns_1.startOfYear)(dateRange.year),
                end: (0, date_fns_1.endOfYear)(dateRange.year),
            };
        case 'range':
            return dateRange.interval;
        default:
            throw Error('no interval possible');
    }
}
exports.getInterval = getInterval;
function test() {
    //const hans: DateRange;
}
exports.test = test;
