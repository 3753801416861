import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FirestoreProject } from 'commons';
import { AsyncPipe, NgIf } from '@angular/common';
import { ProjectService } from '../../../../services/project.service';
import { CommentSectionComponent } from '../../../comments/comment-section/comment-section.component';

@Component({
  selector: 'app-project-comments',
  templateUrl: './project-comments.component.html',
  standalone: true,
  imports: [NgIf, CommentSectionComponent, AsyncPipe],
  styles: `
    :host {
      @apply flex justify-center w-full;
    }
  `,
})
export class ProjectCommentsComponent implements OnInit {
  project!: Observable<FirestoreProject>;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService
  ) {}

  async ngOnInit() {
    this.project = this.projectService.getProject(this.route.parent?.snapshot.params['projectId']);
  }
}
