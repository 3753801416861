import { Component, Input } from '@angular/core';
import { NgIconWrapperComponent } from '../../../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgClass, NgIf } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { icons } from '../../../../icon.config';

@Component({
  selector: 'app-sidebar-navigation-entry',
  templateUrl: './sidebar-navigation-entry.component.html',
  standalone: true,
  imports: [RouterLinkActive, RouterLink, NgClass, NgIf, NgIconWrapperComponent],
})
export class SidebarNavigationEntryComponent {
  @Input() routerLink!: string;
  @Input() iconName: keyof typeof icons = 'heroFolder';
  @Input() iconOpenName: keyof typeof icons = 'heroFolderOpen';
}
