import { inject, Injectable } from '@angular/core';
import { SessionStateService } from './session-state.service';

@Injectable({
  providedIn: 'root',
})
export class LastEditorService {
  private sessionState = inject(SessionStateService);

  async hydrate<T>(data: T) {
    const lastEditor = await this.sessionState.getLastEditor();
    return {
      ...data,
      lastEditor,
    };
  }
}
