<div class="min-h-screen bg-gray-50 flex flex-col py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
      Wähle die Organisation für die du arbeiten möchtest
    </h2>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
        <div *ngIf="organisations$ | async as orgas; else loading">
          <div
            class="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden cursor-pointer"
            (click)="setOrganisation(organisation.id)"
            *ngFor="let organisation of orgas">
            <div class="p-4">
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <svg
                    class="h-6 w-6 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    *ngIf="organisation.id !== selectedOrganisationId">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M 21 12 a 9 9 0 1 1 -18 0 a 9 9 0 0 1 18 0 z" />
                  </svg>
                  <app-ng-icon
                    *ngIf="organisation.id === selectedOrganisationId"
                    class="text-2xl text-green-400"
                    strokeWidth="2"
                    name="heroCheckCircle"></app-ng-icon>
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm leading-5 font-medium text-gray-900">
                    {{ organisation.name }}
                    <span class="font-small text-gray-600">({{ organisation.id }})</span>
                  </p>
                  <p
                    class="mt-1 text-sm leading-5 text-gray-500"
                    *ngIf="organisation.role !== 'not authorized by jessie'; else notAuthorized">
                    Rolle: {{ organisation.role }}
                  </p>
                  <ng-template #notAuthorized>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                      jessie.ai ist zurzeit in Erprobung. <br />
                      Daher werden alle Accounts manuell überprüft. <br />
                      Wir freuen uns auf Early Adopters, bitte melde dich doch mit deiner Login
                      E-Mailadresse bei early&#64;jessie.ai.
                    </p>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #loading> loading</ng-template>
      </div>

      <div class="mt-6">
        <p class="text-xs text-center mb-4">
          Indem Sie jessie.ai nutzen, stimmen Sie unseren
          <a class="underline" target="_blank" href="../assets/jessie_ai_agb.pdf"
            >Allgemeinen Geschäftsbedingungen</a
          >
          zu.
        </p>
        <button
          class="w-full justify-center"
          appTailwindButton
          [disabled]="!selectedOrganisationId"
          (click)="onSubmit()">
          Auswählen
        </button>
      </div>
    </div>
    <div class="flex justify-center text-xs mt-2 text-gray-500">
      <a
        [routerLink]="['/user', 'logout']"
        [relativeTo]="null"
        class="no-underline hover:underline">
        User {{ loggedInUserEmail$ | async }} ausloggen</a
      >
    </div>
  </div>
</div>
