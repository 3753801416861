<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-20">
  <div class="max-w-2xl mx-auto">
    <div
      *ngIf="_actionDisabled; else button"
      (click)="buttonClick.emit()"
      type="button"
      class="relative block w-full border-2 border-dashed rounded-lg p-12 text-center border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
      <app-ng-icon name="heroFolderPlus" class="text-[48px] text-gray-400"></app-ng-icon>
      <span class="mt-2 block text-sm font-medium text-gray-600">
        {{ message }}
      </span>
    </div>
    <ng-template #button>
      <button
        (click)="buttonClick.emit()"
        type="button"
        class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
        <app-ng-icon name="heroFolderPlus" class="text-[48px] text-gray-400"></app-ng-icon>
        <span class="mt-2 block text-sm font-medium text-gray-600">
          {{ message }}
        </span>
      </button>
    </ng-template>
  </div>
</div>
