<div
  class="bg-white shadow overflow-hidden sm:rounded-md"
  *ngIf="invoices && invoices.length > 0; else noInvoices">
  <ul>
    <li
      app-invoice-list-entry
      [invoice]="invoice"
      [ngClass]="{ 'border-t border-gray-200': !isFirst }"
      (editInvoice)="editInvoice.emit(invoice)"
      (deleteInvoice)="deleteInvoice.emit($event)"
      (downloadInvoice)="downloadInvoice.emit($event)"
      *ngFor="let invoice of invoices; first as isFirst"></li>
  </ul>
</div>
<ng-template #noInvoices>
  <app-empty
    message="Noch keine Rechnungen erstellt. Erstelle diese direkt unter einem Projekt!"></app-empty>
</ng-template>
