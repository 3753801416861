import { Component, Input } from '@angular/core';
import { FirestoreOrgaUser, OrgaUserPermission } from 'commons';
import { OrgaUserService } from '../../../../../services/orga-user.service';
import { DropdownItemComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { NgIconWrapperComponent } from '../../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DropdownButtonComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../../../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-orga-user-card',
  templateUrl: './orga-user-card.component.html',
  standalone: true,
  imports: [
    NgIf,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    NgIconWrapperComponent,
    DropdownItemComponent,
  ],
})
export class OrgaUserCardComponent {
  @Input()
  orgaUser!: FirestoreOrgaUser;
  @Input()
  role!: OrgaUserPermission;

  constructor(private orgaUserService: OrgaUserService) {}

  deleteOrgaUser(): void {
    if (this.orgaUser.data.role !== 'owner') {
      this.orgaUserService.deleteOrgaUser(this.orgaUser);
    }
  }
}
