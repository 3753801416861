import { Component, Input } from '@angular/core';
import { FirestoreCustomer } from 'commons';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'li[app-customer-list-entry]',
    templateUrl: './customer-list-entry.component.html',
    standalone: true,
    imports: [
        RouterLink,
        NgIf,
        NgIconWrapperComponent,
    ],
})
export class CustomerListEntryComponent {
  @Input()
  customer!: FirestoreCustomer;
}
