import { Component, Input } from '@angular/core';
import { Entities } from 'commons';
import { NgIconWrapperComponent } from './ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-entity-icon',
    templateUrl: './entity-icon.component.html',
    standalone: true,
    imports: [NgIf, NgIconWrapperComponent],
})
export class EntityIconComponent {
  @Input()
  entity!: Entities;
}
