<app-container [sidebar]="true">
  <app-header-meta-action title="Projekte">
    <button appTailwindButton (click)="createProject()">Neues Projekt erstellen</button>
  </app-header-meta-action>

  <app-sidebar-navigation>
    <app-sidebar-navigation-entry [routerLink]="'all'"> Projekte</app-sidebar-navigation-entry>
    <app-sidebar-navigation-entry
      iconName="heroClock"
      iconOpenName="heroClock"
      [routerLink]="'work'">
      Stunden</app-sidebar-navigation-entry
    >
    <app-sidebar-navigation-entry
      iconName="heroChartPie"
      iconOpenName="heroClock"
      [routerLink]="'data'">
      Daten</app-sidebar-navigation-entry
    >
  </app-sidebar-navigation>

  <router-outlet></router-outlet>
</app-container>
