import { Component, EventEmitter, Input, Output } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-empty',
    templateUrl: './empty.component.html',
    standalone: true,
    imports: [NgIf, NgIconWrapperComponent],
})
export class EmptyComponent {
  @Input() message!: string;
  _actionDisabled = false;
  @Input() set disableAction(value: any) {
    this._actionDisabled = coerceBooleanProperty(value);
  }
  @Output()
  buttonClick = new EventEmitter();
}
