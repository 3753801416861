import { Component, OnInit } from '@angular/core';
import { toNumber } from 'lodash';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { FirestoreOrga, FirestoreUser, Orga } from 'commons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PricingListItemComponent } from './pricing-list-item.component';
import { SettingsCardComponent } from '../settings/settings-card/settings-card.component';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TailwindButtonDirective } from '../../components/button/tailwind-button.directive';
import { SessionStateService } from '../../services/session-state.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  standalone: true,
  imports: [
    TailwindButtonDirective,
    RouterLink,
    NgIf,
    SettingsCardComponent,
    PricingListItemComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class SubscriptionComponent implements OnInit {
  successPlanUser = '1';
  successPlanPrice = 19;

  enterprisePlanUser = '5';
  enterprisePlanPrice = 39;

  basicPlanLink = '';
  successPlanLink = '';
  enterprisePlanLink = '';

  orgaId = '';
  orga!: Orga;

  emailAddress = '';

  stripeDashboardlink = environment.customerPortalLink;
  dashboardSuffix = '';

  constructor(private sessionState: SessionStateService) {}

  async ngOnInit() {
    this.orgaId = await firstValueFrom(this.sessionState.getOrgaId());
    this.emailAddress = (
      await firstValueFrom(
        this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user))
      )
    ).data.email;
    this.orga = (
      await firstValueFrom(
        this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga))
      )
    ).data;
    const emailAdress = this.orga.subscription?.email;

    this.dashboardSuffix = emailAdress ? '?prefilled_email=' + emailAdress : '';

    this.recalculate();
  }

  recalculate() {
    this.basicPlanLink = hydrateLink(
      environment.productLinks.starter,
      this.orgaId,
      this.emailAddress
    );
    this.successPlanPrice = 10 + toNumber(this.successPlanUser) * 9;
    this.successPlanLink = hydrateLink(
      // @ts-ignore
      environment.productLinks.business[this.successPlanUser],
      this.orgaId,
      this.emailAddress
    );

    this.enterprisePlanPrice = -1 + toNumber(this.enterprisePlanUser) * 8;
    this.enterprisePlanLink = hydrateLink(
      // @ts-ignore
      environment.productLinks.enterprise[this.enterprisePlanUser],
      this.orgaId,
      this.emailAddress
    );
  }
}

function hydrateLink(url: string, orgaId: string, emailAddress: string) {
  return url + '?client_reference_id=' + orgaId + '&prefilled_email=' + emailAddress;
}
