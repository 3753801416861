<div
  *ngIf="!(projectSlideOver.isOpen() || positionSlideOver.isOpen())"
  class="fixed z-10 inset-0"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true">
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:px-6 lg:px-8">
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
      >&#8203;</span
    >
    <div
      class="inline-block max-w-7xl w-full align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
      <p class="text-lg leading-6 font-medium text-gray-900">Arbeitszeit erfassen</p>
      <div class="absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          (click)="closeForm.emit()"
          class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          <span class="sr-only">Close</span>
          <app-ng-icon class="text-2xl" name="heroXMark"></app-ng-icon>
        </button>
      </div>

      <div class="px-0 mt-4">
        <app-step>
          <li
            app-step-item
            (stepClicked)="goToStep('chooseProject')"
            stepTitle="Schritt 1"
            [stepSubTitle]="this.selectedProject.value?.data?.name || 'Projekt wählen'"
            [progress]="currentStep$.value === 'chooseProject' ? 'current' : 'completed'"></li>
          <li
            app-step-item
            (stepClicked)="goToStep('choosePosition')"
            stepTitle="Schritt 2"
            [stepSubTitle]="this.selectedPosition.value?.name || 'Position wählen'"
            [progress]="
              currentStep$.value === 'chooseProject'
                ? 'upcoming'
                : currentStep$.value === 'choosePosition'
                  ? 'current'
                  : 'completed'
            "></li>
          <li
            app-step-item
            stepTitle="Schritt 3"
            stepSubTitle="Dauer und Beschreibung eingeben"
            [progress]="
              currentStep$.value === 'chooseTimeDescription' ? 'current' : 'upcoming'
            "></li>
        </app-step>

        <div class="mt-8 h-80 overflow-y-auto p-1" [ngSwitch]="currentStep$.value">
          <div class="flex flex-col justify-between h-full" *ngSwitchCase="'chooseProject'">
            <ng-container *ngIf="projects$ | async as projects">
              <ul class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                <li
                  class="col-span-1 flex shadow-sm border border-gray-200 rounded-md"
                  *ngFor="let project of projects">
                  <button (click)="selectProject(project)" class="w-full">
                    <app-choose-cards
                      [title]="project.data.name"
                      [subTitle]="project.data.customerName"
                      [selected]="selectedProject.value === project"></app-choose-cards>
                  </button>
                </li>
              </ul>
              <div *ngIf="!projects?.length" class="text-center mt-10">
                <h3 class="mt-2 text-sm font-medium text-gray-900">
                  {{ showAll.value ? 'Noch keine Projekte?' : 'Keine Favoriten?' }}
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  {{
                    showAll.value
                      ? 'Erstelle direkt hier ein Projekt'
                      : 'Markiere deine wichtigsten Projekte mit einem Stern, damit sie hier erscheinen.'
                  }}
                </p>
              </div>
            </ng-container>

            <app-divider-button
              *ngIf="!showAll.value"
              buttonText="alle anzeigen"
              (buttonClick)="showAllProjects()"></app-divider-button>
            <app-divider-button
              *ngIf="showAll.value"
              buttonText="neues Projekt erstellen"
              (buttonClick)="createProject()">
              <app-ng-icon class="text-gray-400" strokeWidth="3" name="heroPlus"></app-ng-icon>
            </app-divider-button>
          </div>

          <div class="flex flex-col justify-between h-full" *ngSwitchCase="'choosePosition'">
            <ng-container *ngIf="positions$ | async as positions">
              <ul class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                <li
                  class="col-span-1 flex shadow-sm border border-gray-200 rounded-md"
                  *ngFor="let position of positions">
                  <button (click)="selectPosition(position)" class="w-full">
                    <app-choose-cards
                      [title]="position.name"
                      [subTitle]="position.description"
                      [selected]="selectedPosition.value === position"></app-choose-cards>
                  </button>
                </li>
              </ul>
              <div *ngIf="!positions?.length" class="text-center mt-10">
                <h3 class="mt-2 text-sm font-medium text-gray-900">Da ist noch nichts</h3>
                <p class="mt-1 text-sm text-gray-500">Erstelle direkt hier eine neue Position</p>
              </div>
            </ng-container>

            <app-divider-button
              buttonText="neues Position erstellen"
              (buttonClick)="createPosition()">
              <app-ng-icon class="text-gray-400" strokeWidth="3" name="heroPlus"></app-ng-icon>
            </app-divider-button>
          </div>

          <form
            *ngSwitchCase="'chooseTimeDescription'"
            [formGroup]="workForm"
            (ngSubmit)="submitForm(workForm.value)"
            autocomplete="off"
            novalidate>
            <div class="space-y-1 pt-6 pb-5">
              <div class="space-y-1">
                <label for="description" class="block text-sm font-medium leading-5 text-gray-900">
                  Beschreibung
                </label>
                <div class="relative rounded-md shadow-sm">
                  <textarea
                    appTailwindInput
                    id="description"
                    rows="4"
                    class="form-input block w-full sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                    formControlName="description"></textarea>
                </div>
              </div>
              <div>
                <label class="block text-sm font-medium leading-5 text-gray-900 mb-1"
                  >Arbeitszeit</label
                >
                <div class="flex">
                  <input
                    placeholder="Stunden"
                    appTailwindInput
                    class="input mr-2"
                    formControlName="hours"
                    type="text"
                    pattern="[0-9]*$" />
                  <input
                    placeholder="Minuten"
                    appTailwindInput
                    formControlName="minutes"
                    type="text"
                    pattern="[0-9]*$" />
                </div>
              </div>
            </div>

            <span class="inline-flex justify-end w-full">
              <button appTailwindButton type="submit" [disabled]="!workForm.valid">
                Speichern
              </button>
            </span>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
