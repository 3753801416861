import { Component } from '@angular/core';
import { MenuEntry } from '../components/shell/menuEntry';
import { map, Observable } from 'rxjs';
import firebase from 'firebase/compat';

import {
  CustomerSlideOverComponent,
  CustomerSlideOverService,
} from './customers/customer-slide-over/customer-slide-over.component';

import { RouterOutlet } from '@angular/router';
import { createUserDisplayName, createUserInitials, FirestoreOrga, FirestoreUser } from 'commons';
import { filter } from 'rxjs/operators';
import {
  InvoiceSlideOverComponent,
  InvoiceSlideOverService,
} from './finance/invoices/invoice-slide-over/invoice-slide-over.component';
import {
  DocumentSlideOverComponent,
  DocumentSlideOverService,
} from './document/document-slide-over/document-slide-over.component';
import { FormatSecondsPipe } from '../pipes/format-seconds.pipe';
import { SecondsPassedPipe } from '../pipes/seconds-passed.pipe';
import { AsyncPipe, NgIf } from '@angular/common';
import { DarkNavShellComponent } from '../components/shell/dark-nav-shell.component';
import { SessionStateService } from '../services/session-state.service';
import {
  ProjectSlideOverComponent,
  ProjectSlideOverService,
} from './projects/slide-overs/project-slide-over.component';
import {
  WorkSlideOverComponent,
  WorkSlideOverService,
} from './projects/slide-overs/work-slide-over.component';
import {
  PositionSlideOverComponent,
  PositionSlideOverService,
} from './projects/components/positions/position-slide-over/position-slide-over.component';
import { WorkTimerService } from './home/work-timer/work-timer.service';

@Component({
  selector: 'app-navigation-shell',
  templateUrl: './navigation-shell.component.html',
  standalone: true,
  imports: [
    DarkNavShellComponent,
    RouterOutlet,
    NgIf,
    ProjectSlideOverComponent,
    WorkSlideOverComponent,
    CustomerSlideOverComponent,
    PositionSlideOverComponent,
    DocumentSlideOverComponent,
    InvoiceSlideOverComponent,
    AsyncPipe,
    SecondsPassedPipe,
    FormatSecondsPipe,
  ],
})
export class NavigationShellComponent {
  navMenuEntries: MenuEntry[] = [
    {
      routerLink: '/home',
      displayName: 'Home',
    },
    {
      routerLink: '/project',
      displayName: 'Projekte',
    },
    {
      routerLink: '/customer',
      displayName: 'Firmen und Personen',
    },
    {
      routerLink: '/finance',
      displayName: 'Finanzen',
    },
    {
      routerLink: '/document',
      displayName: 'Dokumente',
    },
  ];
  profileMenuEntries: MenuEntry[] = [
    {
      routerLink: '/settings',
      displayName: 'Einstellungen',
    },
    {
      routerLink: '/user/orga',
      displayName: 'Organisation wechseln',
    },
    {
      routerLink: '/user/logout',
      displayName: 'Ausloggen',
    },
  ];
  navMenuAccountantEntries: MenuEntry[] = [
    {
      routerLink: '/document',
      displayName: 'Dokumente',
    },
  ];
  profileMenuAccountantEntries: MenuEntry[] = [
    {
      routerLink: '/user/orga',
      displayName: 'Organisation wechseln',
    },
    {
      routerLink: '/user/logout',
      displayName: 'Ausloggen',
    },
  ];

  showHeader$: Observable<boolean> | undefined;
  userEmailAddress$: Observable<string> | undefined;
  userDisplayName$: Observable<string> | undefined;
  userInitials$: Observable<string> | undefined;
  navMenu$: Observable<MenuEntry[]> | undefined;
  profileMenu$: Observable<MenuEntry[]> | undefined;
  timer$: Observable<firebase.firestore.Timestamp | null> | undefined;
  orgaName$: Observable<string> | undefined;
  orgaId$: Observable<string> | undefined;

  constructor(
    public projectSlideOver: ProjectSlideOverService,
    public workSlideOver: WorkSlideOverService,
    public customerSlideOver: CustomerSlideOverService,
    public positionSlideOver: PositionSlideOverService,
    public documentSlideOver: DocumentSlideOverService,
    public invoiceSlideOver: InvoiceSlideOverService,
    private sessionState: SessionStateService,
    private timerService: WorkTimerService
  ) {
    this.timer$ = this.timerService
      .getRunningTimer()
      .pipe(map((timer) => (timer ? timer.startTime : null)));

    const user = this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user));

    this.userDisplayName$ = user.pipe(
      map((fsUser) => {
        if (fsUser.data.firstName && fsUser.data.lastName) {
          return createUserDisplayName(fsUser.data.firstName, fsUser.data.lastName);
        } else {
          return 'Bitte setze deinen Namen in den Einstellungen';
        }
      })
    );

    this.userInitials$ = user.pipe(
      filter((fsUser) => !!fsUser.data.firstName && !!fsUser.data.lastName),
      map((settings) => createUserInitials(settings.data.firstName, settings.data.lastName))
    );

    this.navMenu$ = this.sessionState.getOrgaUserRole().pipe(
      map((role) => {
        if (role === 'accountant') {
          return this.navMenuAccountantEntries;
        } else {
          return this.navMenuEntries;
        }
      })
    );

    this.profileMenu$ = this.sessionState.getOrgaUserRole().pipe(
      map((role) => {
        if (role === 'accountant') {
          return this.profileMenuAccountantEntries;
        } else {
          return this.profileMenuEntries;
        }
      })
    );

    this.userEmailAddress$ = user.pipe(map((user) => user.data.email));
    const orga = this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga));
    this.orgaName$ = orga.pipe(map((orga) => orga.data?.name));
    this.orgaId$ = this.sessionState.getOrgaId();
  }
}
