<button class="flex w-full text-left hover:bg-gray-50" (click)="modifyWork.emit()">
  <div class="px-4 pb-4 pt-2 md:py-4 sm:px-6 flex flex-row items-center gap-5 flex-1 min-w-0">
    <div class="text-lg font-semibold text-blue-600 w-14 flex-shrink-0 text-right">
      {{ workHours }}
    </div>

    <div class="flex-grow min-w-0 flex flex-col">
      <div class="text-sm font-medium text-blue-600 truncate">
        {{ projectName }} | {{ positionName }}
      </div>

      <p *ngIf="description" class="text-sm text-gray-500 text-wrap">
        {{ description }}
      </p>
      <div class="flex flex-wrap gap-2">
        <p class="flex items-center text-sm text-gray-500 mt-2">
          <app-ng-icon name="heroUsers"></app-ng-icon>
          &NonBreakingSpace;{{ userDisplayName }}
        </p>
        <span
          *ngIf="!billable"
          class="md:hidden self-start inline-flex px-2.5 py-0.5 text-sm rounded-md font-medium leading-5 bg-gray-100 text-gray-800 mt-2 whitespace-nowrap">
          Nicht verrechenbar
        </span>
      </div>
    </div>

    <span
      *ngIf="!billable"
      class="hidden self-start md:inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium leading-5 bg-gray-100 text-gray-800 whitespace-nowrap">
      Nicht verrechenbar
    </span>
  </div>
  <!--  <app-dropdown-minimal-menu>-->
  <!--    <app-dropdown-button>-->
  <!--      <app-ng-icon name="heroEllipsisVerticalSolid" class="text-3xl" strokeWidth="3"></app-ng-icon>-->
  <!--    </app-dropdown-button>-->
  <!--    <app-dropdown-item (menuClick)="modifyWork.emit()"> Bearbeiten </app-dropdown-item>-->
  <!--    <app-dropdown-item *ngIf="!billed" (menuClick)="deleteWork.emit()"> Löschen </app-dropdown-item>-->
  <!--  </app-dropdown-minimal-menu>-->
</button>
