<div
  class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center">
  <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
  <div class="fixed inset-0 transition-opacity">
    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
  </div>

  <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
  <div
    class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full sm:p-6"
    role="dialog"
    aria-modal="true"
    aria-labelledby="modal-headline">
    <div>
      <div
        class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-{{ color }}-100">
        <app-ng-icon
          class="text-{{ color }}-600 text-4xl"
          strokeWidth="1"
          [name]="icon"></app-ng-icon>
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
          {{ title }}
        </h3>
        <div class="mt-2">
          <p class="text-sm leading-5 text-gray-500">
            {{ description }}
          </p>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-6">
      <span class="flex w-full rounded-md shadow-sm">
        <button
          (click)="actionClicked.emit()"
          type="button"
          class="inline-flex justify-center w-full px-4 py-2 border border-gray-300 text-base leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150">
          {{ actionText }}
        </button>
      </span>
    </div>
  </div>
</div>
