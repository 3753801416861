import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TailwindInputDirective } from '../input/tailwind-input.directive';
import { SelectAllDirective } from '../../directives/selectAll.directive';
import { NgIf } from '@angular/common';
import { CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';

@Component({
    selector: 'app-dropdown-minimal-menu',
    styles: [
        `
      :host {
        @apply flex hover:bg-gray-100;
      }
    `,
    ],
    templateUrl: './dropdown-minimal-menu.component.html',
    standalone: true,
    imports: [
        CdkOverlayOrigin,
        CdkConnectedOverlay,
        NgIf,
        SelectAllDirective,
        TailwindInputDirective,
    ],
})
export class DropdownMinimalMenuComponent {
  @Input()
  dropdownHeader!: string;
  @Input()
  hasSearch = false;

  @Output()
  searchValueChanged = new EventEmitter<string>();

  isOpen = false;

  click(): void {
    this.isOpen = false;
  }

  searchValue(event: any) {
    if (event.target) {
      this.searchValueChanged.emit(event.target.value ?? '');
    }
  }

  menuOpen(event: Event) {
    this.isOpen = true;
    this.searchValueChanged.emit('');
    event.stopPropagation();
    event.preventDefault();
  }
}
