import { Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TailwindInputDirective } from './tailwind-input.directive';
import { ValidationErrors } from '@angular/forms';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    standalone: true,
    imports: [NgIf, NgIconWrapperComponent],
})
export class InputComponent {
  // todo samuel: duplicate code with currency input component

  @Input()
  label?: string;

  @Input()
  enhanced = false;

  hasErrors = false;
  _errors: ValidationErrors = {};
  _inputDirectives!: TailwindInputDirective[];

  @Input()
  set errors(errors: ValidationErrors | null | undefined) {
    this._errors = errors ?? {};
    this.setError();
  }

  @ContentChildren(TailwindInputDirective, { descendants: true }) set inputDirective(
    inputDirectives: QueryList<TailwindInputDirective>
  ) {
    this._inputDirectives = inputDirectives.toArray();
    this.setError();
  }

  setError() {
    this.hasErrors = Object.keys(this._errors).length > 0;
    if (this._inputDirectives) {
      this._inputDirectives.forEach((iD) => (iD.error = this.hasErrors));
    }
  }
}
