import { Component, OnInit } from '@angular/core';
import { FirestoreOrga, Orga } from 'commons';
import { firstValueFrom, Observable } from 'rxjs';
import { OrgaService } from '../../../services/orga.service';
import { filter, map } from 'rxjs/operators';
import { LastEditorService } from '../../../services/last-editor.service';
import { OrgaBankingCardComponent } from './orga-banking-card.component';
import { OrgaInvoiceCardComponent } from './orga-invoice-card.component';
import { OrgaAddressCardComponent } from './orga-address-card.component';
import { OrgaNameCardComponent } from './orga-name-card.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';

@Component({
  selector: 'app-orga-settings',
  templateUrl: './orga-settings.component.html',
  standalone: true,
  imports: [
    NgIf,
    OrgaNameCardComponent,
    OrgaAddressCardComponent,
    OrgaInvoiceCardComponent,
    OrgaBankingCardComponent,
    AsyncPipe,
  ],
})
export class OrgaSettingsComponent implements OnInit {
  orga$ = this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga));
  hasPermission!: Observable<boolean>;

  constructor(
    private orgaService: OrgaService,
    private sessionState: SessionStateService,
    private lastEditor: LastEditorService
  ) {}

  ngOnInit(): void {
    this.hasPermission = this.sessionState.getOrgaUserRole().pipe(map((role) => role === 'owner'));
  }

  async settingsChanged(partialOrga: Partial<Orga>) {
    const orgaId = await firstValueFrom(this.sessionState.getOrgaId());
    this.orgaService
      .update(orgaId, await this.lastEditor.hydrate(partialOrga))
      .then(() => console.log('done'));
  }
}
