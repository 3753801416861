import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[appTabOld]',
    standalone: true,
})
export class TabOldDirective {
  @Input('appTabOld')
  title!: string;
}
