<div class="flex-1 flex items-center justify-between truncate">
  <div class="flex-shrink-0 ml-2">
    <svg
      class="h-6 w-6 text-gray-500"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      *ngIf="!selected">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M 21 12 a 9 9 0 1 1 -18 0 a 9 9 0 0 1 18 0 z" />
    </svg>
    <app-ng-icon *ngIf="selected" class="text-2xl" name="heroCheckCircle"></app-ng-icon>
  </div>
  <div class="flex-1 px-4 py-2 text-sm">
    <div class="text-gray-900 font-medium text-left hover:text-gray-600 truncate">
      {{ title }}
    </div>
    <p class="text-gray-500 truncate text-left">{{ subTitle }}</p>
  </div>
</div>
