<app-container [sidebar]="true">
  <app-header-meta-action [title]="'Finanzen'"> </app-header-meta-action>

  <app-sidebar-navigation>
    <app-sidebar-navigation-entry [routerLink]="'outstandingPayments'"
      >Ausstehende Zahlungen</app-sidebar-navigation-entry
    >
    <app-sidebar-navigation-entry [routerLink]="'expenseRevenue'"
      >Einnahmen / Ausgaben</app-sidebar-navigation-entry
    >
  </app-sidebar-navigation>

  <router-outlet></router-outlet>
</app-container>
