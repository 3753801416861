<app-settings-card
  heading="Namen"
  description="Wir unterscheiden zwischen dem Namen deiner Organisation wie er in
          jessie.ai verwendet wird und dem rechtsgültigen Firmennamen. Der Name
          der Organisation kann eine Kurzform sein."
  (save)="saveSettings()"
  [disabled]="disabled">
  <form [formGroup]="nameForm">
    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
      <div class="sm:col-span-6">
        <app-input
          label="Name deiner Organisation in Jessie.ai"
          [errors]="nameForm.get('name')?.errors">
          <input type="text" name="name" appTailwindInput [formControlName]="'name'" />
        </app-input>
      </div>
      <div class="sm:col-span-6">
        <app-input label="Rechtsgültiger Firmenname" [errors]="nameForm.get('legalName')?.errors">
          <input type="text" name="legalName" appTailwindInput [formControlName]="'legalName'" />
        </app-input>
      </div>
      <div class="sm:col-span-6">
        <app-input label="Logo für die Rechnung" [errors]="selectedImageError">
          <label for="logo" class="custom-file-upload"> Logo auswählen </label>
          <input
            type="file"
            name="logo"
            id="logo"
            accept=".jpg,.png"
            appTailwindInput
            (change)="onFileSelected($event)"
            style="display: none" />
        </app-input>
      </div>
      <div *ngIf="nameForm.get('logo64')?.value">
        <img [src]="nameForm.get('logo64')?.value" alt="Firmenlogo für Rechnung" />
      </div>
    </div>
  </form>
</app-settings-card>
