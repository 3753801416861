<a
  [routerLink]="['project/details', project.id]"
  [relativeTo]="null"
  class="p-4 sm:py-6flex-grow block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
  <div class="flex items-center gap-4">
    <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <div class="text-sm leading-5 font-medium text-blue-600 truncate">
          {{ project.data.name }}
          <span class="ml-1 font-normal text-gray-500">
            {{ project.data.customerName }}
          </span>
        </div>
      </div>
    </div>
    <div class="ml-5 flex-shrink-0">
      <app-ng-icon
        class="text-gray-400 text-sm"
        strokeWidth="3"
        name="heroChevronRight"></app-ng-icon>
    </div>
  </div>
</a>
