<app-container [sidebar]="true">
  <app-header-meta-action title="Einstellungen">
    <a appTailwindButton color="secondary" href="mailto:support@jessie.ai"
      >Fehler oder Verbesserung melden</a
    >
  </app-header-meta-action>
  <app-sidebar-navigation heading="Übergreifende Einstellungen">
    <app-sidebar-navigation-entry [routerLink]="'account'">Name </app-sidebar-navigation-entry>
  </app-sidebar-navigation>
  <app-sidebar-navigation heading="Einstellungen der aktuellen Organisation">
    <app-sidebar-navigation-entry [routerLink]="'organisation'"
      >Allgemein
    </app-sidebar-navigation-entry>
    <app-sidebar-navigation-entry [routerLink]="'user'"
      >Mitarbeiter:innen
    </app-sidebar-navigation-entry>
    <app-sidebar-navigation-entry [routerLink]="'document'"
      >Dokumente
    </app-sidebar-navigation-entry>
    <app-sidebar-navigation-entry *appHideWhenNotInRole="['owner']" [routerLink]="'subscription'"
      >Subscription
    </app-sidebar-navigation-entry>
  </app-sidebar-navigation>
  <router-outlet></router-outlet>
</app-container>
