<app-container *ngIf="project$ | async as project" [subpage]="true">
  <app-header-meta-action [title]="project.data.name" [subTitle]="project.data.customerName">
    <div class="flex items-center gap-4">
      <app-favorite
        [favorite]="project.data.favorite"
        (stateChange)="favoriteChanged($event)"></app-favorite>
      <button appTailwindButton color="secondary" (click)="editProject()">
        Projekt bearbeiten
      </button>
      <button appTailwindButton (click)="createInvoice()">Rechnung erstellen</button>
    </div>
  </app-header-meta-action>

  <div class="mt-4">
    <app-tab-group>
      <a
        appTab
        [active]="structure.isActive"
        [routerLink]="['structure']"
        routerLinkActive
        #structure="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        >Projektstruktur</a
      >
      <a
        appTab
        [active]="activity.isActive"
        [routerLink]="['activity']"
        routerLinkActive
        #activity="routerLinkActive"
        >Stunden</a
      >
      <a
        appTab
        [active]="invoice.isActive"
        [routerLink]="['invoice']"
        routerLinkActive
        #invoice="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        >Rechnungen</a
      >
      <a
        appTab
        [active]="document.isActive"
        [routerLink]="['documents']"
        routerLinkActive
        #document="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        >Dokumente</a
      ><a
        appTab
        [active]="comments.isActive"
        [routerLink]="['comments']"
        routerLinkActive
        #comments="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        *appHideWhenNotInPlan="['business', 'enterprise']"
        >Kommentare <small>({{ project.data.comments?.count ?? 0 }})</small></a
      ><a
        appTab
        [active]="history.isActive"
        [routerLink]="['history']"
        routerLinkActive
        #history="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
        *appHideWhenNotInPlan="['business', 'enterprise']"
        >Änderungen</a
      >
    </app-tab-group>
    <div class="pt-4">
      <router-outlet></router-outlet>
    </div>
  </div>
</app-container>
