<section class="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
  <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="relative">
      <blockquote class="mt-8">
        <div class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
          <p>
            Es ist was schiefgelaufen. Versuche es nochmals durch neuladen oder aus- und wieder
            einloggen. Wenn nichts hilft wende dich an unseren Support.
          </p>
        </div>
      </blockquote>
    </div>
  </div>
</section>
