import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { FirestoreOrga, SubscriptionPlan } from 'commons';
import { firstValueFrom } from 'rxjs';
import { SessionStateService } from '../services/session-state.service';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[appHideWhenNotInPlan]',
  standalone: true,
})
export class HideWhenNotInPlanDirective implements OnChanges {
  @Input('appHideWhenNotInPlan')
  plansInWhichTheDirectiveShowsTheHost: SubscriptionPlan[] = [];
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sessionState: SessionStateService
  ) {}

  private async shouldDisplay(plans: SubscriptionPlan[]) {
    const orga = await firstValueFrom(
      this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga))
    );
    return (
      orga.data.subscription?.plan &&
      this.plansInWhichTheDirectiveShowsTheHost.includes(orga.data.subscription?.plan)
    );
  }

  async ngOnChanges() {
    if (await this.shouldDisplay(this.plansInWhichTheDirectiveShowsTheHost)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
