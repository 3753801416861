<div class="flex shadow-sm rounded-md">
  <div
    class="flex-shrink-0 flex items-center justify-center w-16 text-white text-sm leading-5 font-medium rounded-l-md"
    [class.bg-pink-600]="!orgaUser.data.onboardingCompleted"
    [class.bg-green-600]="orgaUser.data.onboardingCompleted">
    <div *ngIf="orgaUser.data.onboardingCompleted">{{ orgaUser.data.userInitials }}</div>
    <div *ngIf="!orgaUser.data.onboardingCompleted">?</div>
  </div>
  <div
    class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
    <div class="flex-1 px-4 py-2 text-sm leading-5 truncate">
      <div
        class="text-gray-900 font-medium hover:text-gray-600 transition ease-in-out duration-150">
        <div *ngIf="orgaUser.data.onboardingCompleted">{{ orgaUser.data.userDisplayName }}</div>
        <div *ngIf="!orgaUser.data.onboardingCompleted">Onboarding läuft...</div>
      </div>
      <p class="text-gray-500">{{ orgaUser.data.email }}</p>
      <p class="text-gray-500">Level: {{ orgaUser.data.role }}</p>
    </div>
    <div class="flex-shrink-0 pr-2" *ngIf="orgaUser.data.role !== 'owner' && role === 'owner'">
      <app-dropdown-minimal-menu>
        <app-dropdown-button>
          <app-ng-icon
            name="heroEllipsisVerticalSolid"
            class="text-3xl"
            strokeWidth="3"></app-ng-icon>
        </app-dropdown-button>
        <app-dropdown-item (menuClick)="deleteOrgaUser()">Mitarbeiter löschen </app-dropdown-item>
      </app-dropdown-minimal-menu>
    </div>
  </div>
</div>
