<div
  class="flex w-60 lg:w-80 rounded-full border bg-white p-2 text-gray-400 relative"
  [ngClass]="filter.type === 'notSet' ? 'border-gray-300' : 'border-green-600 text-gray-600'">
  <ng-container *ngIf="filter.type === 'month' || filter.type === 'year'">
    <div class="flex flex-grow pr-4 tracking-wide">
      <button (click)="decrement()" class="ml-1.5 h-6 w-6 rounded-full bg-gray-100">
        <app-ng-icon strokeWidth="3" name="heroArrowLeft"></app-ng-icon>
      </button>
      <div
        class="flex-grow text-center"
        [class.text-gray-600]="!isTodayInCurrentPeriod"
        [class.text-blue-700]="isTodayInCurrentPeriod">
        {{ displayText }}
      </div>
      <button (click)="increment()" class="h-6 w-6 rounded-full bg-gray-100">
        <app-ng-icon strokeWidth="3" name="heroArrowRight"></app-ng-icon>
      </button>
    </div>

    <app-dropdown-minimal-menu class="mr-1">
      <app-dropdown-button>
        <app-ng-icon class="text-2xl" strokeWidth="2" name="heroXCircle"></app-ng-icon>
      </app-dropdown-button>
      <app-dropdown-item (menuClick)="enableFilter('month')">nach Monat </app-dropdown-item>
      <app-dropdown-item (menuClick)="enableFilter('year')">nach Jahr </app-dropdown-item>
      <app-dropdown-item (menuClick)="resetFilter()">Alles </app-dropdown-item>
    </app-dropdown-minimal-menu>

    <!--    <icon-filter></icon-filter>-->
  </ng-container>
  <ng-container *ngIf="filter.type === 'notSet'">
    <div class="flex flex-grow pr-4">
      <div class="flex-grow pl-4">Datum</div>
    </div>

    <app-dropdown-minimal-menu class="mr-1">
      <app-dropdown-button>
        <app-ng-icon class="text-xl" name="heroFunnelSolid"></app-ng-icon>
      </app-dropdown-button>
      <app-dropdown-item (menuClick)="enableFilter('month')">nach Monat </app-dropdown-item>
      <app-dropdown-item (menuClick)="enableFilter('year')">nach Jahr </app-dropdown-item>
    </app-dropdown-minimal-menu>
  </ng-container>
  <button
    *ngIf="filter.type !== 'notSet' && !isTodayInCurrentPeriod"
    class="absolute left-0 right-0 -bottom-4 text-xs"
    (click)="goToCurrentPeriod()">
    {{ currentPeriodButtonText }}
  </button>
</div>
