<app-container [sidebar]="true">
  <app-header-meta-action [title]="today()"></app-header-meta-action>

  <app-sidebar-navigation>
    <app-sidebar-navigation-entry
      iconName="heroCalendar"
      iconOpenName="heroCalendarDays"
      [routerLink]="'calendar'">
      Kalender
    </app-sidebar-navigation-entry>
    <app-sidebar-navigation-entry
      *ngIf="showTimer"
      iconName="heroPlayCircle"
      iconOpenName="heroPauseCircle"
      [routerLink]="'timer'">
      Timer
    </app-sidebar-navigation-entry>
    <app-sidebar-navigation-entry
      iconName="heroClock"
      iconOpenName="heroClock"
      [routerLink]="'work'">
      Meine Stunden</app-sidebar-navigation-entry
    >
    <app-sidebar-navigation-entry
      iconName="heroChatBubbleBottomCenter"
      iconOpenName="heroChatBubbleBottomCenterText"
      [routerLink]="'comments'">
      Mentions</app-sidebar-navigation-entry
    >
  </app-sidebar-navigation>

  <router-outlet></router-outlet>
</app-container>
