import { Component, ContentChild, Input } from '@angular/core';
import { TailwindInputDirective } from './tailwind-input.directive';
import { ValidationErrors } from '@angular/forms';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-currency-input',
    templateUrl: './currency-input.component.html',
    standalone: true,
    imports: [NgIf, NgIconWrapperComponent],
})
export class CurrencyInputComponent {
  @Input()
  label?: string;

  @Input()
  enhanced = false;

  hasErrors = false;
  _errors: ValidationErrors = {};
  _inputDirective!: TailwindInputDirective;

  @Input()
  set errors(errors: ValidationErrors | null | undefined) {
    this._errors = errors ?? {};
    this.setError();
  }

  @ContentChild(TailwindInputDirective) set inputDirective(inputDirective: TailwindInputDirective) {
    this._inputDirective = inputDirective;
    this.setError();
  }

  setError() {
    this.hasErrors = Object.keys(this._errors).length > 0;
    if (this._inputDirective) {
      this._inputDirective.error = this.hasErrors;
    }
  }
}
