<form
  class="form-container pl-3"
  [formGroup]="positionForm"
  (ngSubmit)="createOrUpdatePosition(positionForm.value)"
  autocomplete="off"
  novalidate>
  <app-slide-over>
    <app-slide-over-header
      *ngIf="positionSlideOver.getMode() === 'new'; else editMode"
      (closeSlideOver)="closeSlideOver()"
      [formDirty]="positionForm.dirty"
      title="Neue Projektposition erstellen"
      [leadText]="
        'Mit Positionen gibst du deinem Projekt Struktur. Positionen sind einzelne Arbeitspakete welche das Projekt unterteilen.'
      ">
    </app-slide-over-header>
    <ng-template #editMode>
      <app-slide-over-header
        [formDirty]="positionForm.dirty"
        (closeSlideOver)="closeSlideOver()"
        title="Projektposition">
      </app-slide-over-header>
    </ng-template>

    <app-slide-over-content>
      <div class="px-4 divide-y divide-gray-200 sm:px-6">
        <div class="space-y-6 pt-6 pb-5">
          <div class="space-y-1">
            <app-input
              label="Name der Projektposition"
              class="mt-4"
              [errors]="
                positionForm.controls['name']?.touched
                  ? positionForm.controls['name']?.errors
                  : null
              "
              ><input appTailwindInput type="text" [formControlName]="'name'"
            /></app-input>
          </div>
          <div class="space-y-1">
            <app-input
              label="Beschreibung"
              class="mt-4"
              [errors]="
                positionForm.controls['description']?.touched
                  ? positionForm.controls['description']?.errors
                  : null
              ">
              <div class="relative rounded-md shadow-sm">
                <textarea
                  appTailwindInput
                  id="description"
                  rows="4"
                  type="text"
                  class="form-input block w-full sm:text-sm sm:leading-5 transition ease-in-out duration-150"
                  formControlName="description"></textarea>
              </div>
            </app-input>
          </div>

          <fieldset class="space-y-2 pb-5">
            <legend class="text-sm leading-5 font-medium text-gray-900">
              Art der Projektposition
            </legend>
            <div class="space-y-5">
              <div class="relative flex items-start">
                <div class="absolute flex items-center h-5">
                  <input
                    id="positionType_fix"
                    aria-describedby="positionType_fix_description"
                    type="radio"
                    [value]="positionType.fixedPrice"
                    name="positionType"
                    class="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                    formControlName="positionType" />
                </div>
                <div class="pl-7 text-sm leading-5">
                  <label for="positionType_fix" class="font-medium text-gray-900"> Fixpreis </label>
                  <p id="positionType_fix_description" class="text-gray-500">
                    Die Position wird zum angebenen Preis verrechnet. Du kannst Arbeiten darauf
                    buchen, aber diese erscheinen nicht auf der Rechnung.
                  </p>
                </div>
              </div>
              <div>
                <div class="relative flex items-start">
                  <div class="absolute flex items-center h-5">
                    <input
                      id="positionType_timeAndMaterial"
                      aria-describedby="positionType_timeAndMaterial_description"
                      type="radio"
                      [value]="positionType.timeAndMaterial"
                      name="positionType"
                      class="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                      formControlName="positionType" />
                  </div>
                  <div class="pl-7 text-sm leading-5">
                    <label for="positionType_timeAndMaterial" class="font-medium text-gray-900">
                      Aufwand
                    </label>
                    <p id="positionType_timeAndMaterial_description" class="text-gray-500">
                      Der Preis der Position wird anhand der darauf gebuchten Arbeiten berechnet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

          <div
            class="space-y-1"
            *ngIf="
              positionForm.get('positionType')?.value === positionType.fixedPrice;
              else timeAndMaterial
            ">
            <app-input
              label="Preis"
              class="mt-4"
              [errors]="
                positionForm.controls['amount']?.touched
                  ? positionForm.controls['amount']?.errors
                  : null
              "
              ><input appTailwindInput formControlName="amount" type="text" pattern="[0-9]*"
            /></app-input>
          </div>

          <ng-template #timeAndMaterial>
            <div class="space-y-1">
              <app-currency-input
                label="Stundensatz"
                class="mt-4"
                [errors]="
                  positionForm.controls['hourlyRate']?.touched
                    ? positionForm.controls['hourlyRate']?.errors
                    : null
                "
                ><input appTailwindInput formControlName="hourlyRate" type="text" pattern="[0-9]*"
              /></app-currency-input>
            </div>
            <div>
              <app-input label="Erwarteter Aufwand" class="mt-4">
                <div class="flex gap-8">
                  <app-input
                    [errors]="
                      positionForm.controls['hours']?.touched
                        ? positionForm.controls['hours']?.errors
                        : null
                    ">
                    <input
                      appTailwindInput
                      placeholder="Stunden"
                      class="input mr-2"
                      formControlName="hours"
                      type="text"
                      pattern="[0-9]*$" />
                  </app-input>
                  <app-input
                    [errors]="
                      positionForm.controls['minutes']?.touched
                        ? positionForm.controls['minutes']?.errors
                        : null
                    ">
                    <input
                      placeholder="Minuten"
                      appTailwindInput
                      formControlName="minutes"
                      type="text"
                      pattern="[0-9]*$" />
                  </app-input>
                </div>
              </app-input>
            </div>
            <div class="space-y-1">
              <app-currency-input label="Kalkulierter Preis" class="mt-4"
                ><input appTailwindInput disabled [value]="getCalculatedAmount()" type="text"
              /></app-currency-input>
            </div>
          </ng-template>

          <div class="divide-y divide-gray-200">
            <div class="space-y-4 pt-6 pb-5">
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input
                    id="comments"
                    type="checkbox"
                    class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                    formControlName="billable" />
                </div>
                <div class="ml-3 text-sm leading-5">
                  <label for="comments" class="font-medium text-gray-700">Verrechenbar</label>
                  <p class="text-gray-500">
                    Bestimme ob die Position dem Kunden in Rechnung gestellt werden kann oder nicht.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-slide-over-content>

    <app-slide-over-footer>
      <span class="inline-flex rounded-md shadow-sm">
        <button appTailwindButton color="secondary" type="button" (click)="closeSlideOver()">
          Abbrechen
        </button>
      </span>
      <span class="inline-flex rounded-md shadow-sm">
        <button appTailwindButton type="submit">Speichern</button>
      </span>
    </app-slide-over-footer>
  </app-slide-over>
</form>
