import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatSeconds',
    standalone: true,
})
export class FormatSecondsPipe implements PipeTransform {
  transform(inputSeconds: number | null, ...args: unknown[]): string {
    if (inputSeconds === undefined || inputSeconds === null) {
      return '';
    }
    return new Date(inputSeconds * 1000).toISOString().substr(11, 8);
  }
}
