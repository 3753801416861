import { inject, Injectable } from '@angular/core';
import { FirestoreOrga, Orga } from 'commons';
import { Observable } from 'rxjs';
import { FIRESTORE_SERVICE } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class OrgaService {
  private rootFirestoreService = inject(FIRESTORE_SERVICE);

  async update(id: string, orga: Partial<Orga>) {
    return this.rootFirestoreService.updateDoc<Orga>(`orga/${id}`, orga);
  }

  getOrga(orgaId: string): Observable<FirestoreOrga> {
    return this.rootFirestoreService.getDoc<Orga>(`orga/${orgaId}`);
  }
}
