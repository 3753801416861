import { Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { Comment, FirestoreOrgaUser } from 'commons';
import { TailwindButtonDirective } from '../../button/tailwind-button.directive';
import { MentionModule } from 'angular-mentions';
import { TailwindInputDirective } from '../../input/tailwind-input.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from '../../input/input.component';
import { NgIconWrapperComponent } from '../../icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'app-new-comment',
  templateUrl: './new-comment.component.html',
  styles: [
    `
      :host {
        ::ng-deep {
          .mention-active > a {
            background-color: rgb(59 130 246) !important;
          }
        }
      }
    `,
  ],
  standalone: true,
  imports: [
    InputComponent,
    ReactiveFormsModule,
    TailwindInputDirective,
    FormsModule,
    MentionModule,
    TailwindButtonDirective,
    NgIconWrapperComponent,
  ],
})
export class NewCommentComponent implements OnChanges {
  @Input() orgaUsers!: FirestoreOrgaUser[];
  @Output() createNewComment = new EventEmitter<Partial<Comment>>();

  @HostListener('document:keydown.enter', ['$event'])
  onEnterKeydownHandler($event: KeyboardEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.create();
  }

  userList: { id: string; displayName: string }[] = [];
  comment = '';

  ngOnChanges() {
    if (this.orgaUsers) {
      this.userList = this.orgaUsers
        .filter((user) => user.data.userId && ['standard', 'owner'].includes(user.data.role))
        .map((orgaUser) => ({
          id: orgaUser.data.userId,
          displayName: orgaUser.data.userDisplayName ?? orgaUser.data.email ?? 'no userDisplayName',
        }));
    }
  }

  create() {
    const mentionedNames = getMentionedNames(
      this.comment,
      this.userList.map((user) => user.displayName)
    );

    const mentions = this.userList
      .filter((user) => mentionedNames.includes(user.displayName))
      .reduce((prev, cur) => ({ ...prev, [cur.id]: cur.displayName }), {});

    const mentionIds = this.userList
      .filter((user) => mentionedNames.includes(user.displayName))
      .map((user) => user.id);

    this.createNewComment.emit({
      commentText: this.comment,
      mentions,
      notReactedUserIds: mentionIds,
    });
    this.comment = '';
  }
}

function getMentionedNames(text: string, allNames: string[]): string[] {
  // Convert the list of all names into a regex pattern, joined by the pipe (|) symbol
  const pattern = allNames.map((name) => `@${name}`).join('|');
  const regex = new RegExp(pattern, 'g');

  // Match all mentioned names in the text
  const matches = text.match(regex);

  // If there are no matches, return an empty array
  if (!matches) {
    return [];
  }

  // Remove the "@" prefix from each matched name
  return matches.map((name) => name.slice(1));
}
