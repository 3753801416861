import { Component, Input } from '@angular/core';
import { NgIconWrapperComponent } from '../../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-choose-cards',
    templateUrl: './choose-cards.component.html',
    styles: [':host { max-width: 100%}'],
    standalone: true,
    imports: [NgIf, NgIconWrapperComponent],
})
export class ChooseCardsComponent {
  @Input()
  selected!: boolean;
  @Input()
  title!: string;
  @Input()
  subTitle!: string;
}
