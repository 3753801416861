import { inject, Injectable } from '@angular/core';

import { formatISO } from 'date-fns';
import { firstValueFrom, map, Observable } from 'rxjs';
import {
  BaseWork,
  FirestoreProject,
  FirestoreWork,
  getInterval,
  Position,
  Work,
  WorkEntity,
  WorkFilter,
} from 'commons';
import { LastEditorService } from './last-editor.service';
import { FIRESTORE_SERVICE, ORGA_FIRESTORE_SERVICE } from './firestore.service';
import {
  collection,
  doc,
  Firestore,
  orderBy,
  QueryConstraint,
  where,
  writeBatch,
} from '@angular/fire/firestore';
import { SessionStateService } from './session-state.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkService {
  private rootFirestoreService = inject(FIRESTORE_SERVICE);
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);

  private sessionState = inject(SessionStateService);
  private lastEditor = inject(LastEditorService);

  firestore = inject(Firestore);

  getWork(projectId: string, positionId: string, workId: string) {
    return this.orgaFirestoreService.getDoc<Work>(`/project/${projectId}/work/${workId}`);
  }

  getWorkByDocRef(workDocRef: string): Observable<FirestoreWork> {
    return this.rootFirestoreService.getDoc<Work>(workDocRef);
  }

  getAllWorkBetweenDate(from: Date, to: Date): Observable<FirestoreWork[]> {
    return this.sessionState
      .getOrgaId()
      .pipe(
        switchMap((orgaId) =>
          this.orgaFirestoreService.getDocsByCollectionGroup<Work>(
            'work',
            where('orgaId', '==', orgaId),
            where('date', '>=', formatISO(from, { representation: 'date' })),
            where('date', '<=', formatISO(to, { representation: 'date' }))
          )
        )
      );
  }

  getAllWorkForPosition(projectId: string, positionId: string): Observable<FirestoreWork[]> {
    return this.orgaFirestoreService.getDocs<Work>(
      `project/${projectId}/work`,
      where('positionId', '==', positionId)
    );
  }

  getAllWorkForPositionBetweenDate(
    projectId: string,
    positionId: string,
    from: Date,
    to: Date
  ): Observable<FirestoreWork[]> {
    return this.orgaFirestoreService.getDocs<Work>(
      `project/${projectId}/work`,
      where('positionId', '==', positionId),
      where('date', '>=', formatISO(from, { representation: 'date' })),
      where('date', '<=', formatISO(to, { representation: 'date' }))
    );
  }

  getAllBillableWorkForPositionBetweenDate(
    projectId: string,
    positionId: string,
    from: Date,
    to: Date
  ): Observable<FirestoreWork[]> {
    return this.orgaFirestoreService.getDocs<Work>(
      `project/${projectId}/work`,
      where('positionId', '==', positionId),
      where('date', '>=', formatISO(from, { representation: 'date' })),
      where('date', '<=', formatISO(to, { representation: 'date' })),
      where('billed', '==', false)
    );
  }

  getAllWorkForUser(userId: string, from: Date, to: Date): Observable<FirestoreWork[]> {
    return this.sessionState
      .getOrgaId()
      .pipe(
        switchMap((orgaId) =>
          this.orgaFirestoreService.getDocsByCollectionGroup<Work>(
            'work',
            where('userId', '==', userId),
            where('orgaId', '==', orgaId),
            where('date', '>=', formatISO(from, { representation: 'date' })),
            where('date', '<=', formatISO(to, { representation: 'date' }))
          )
        )
      );
  }

  getAllWorkForProject(underProjectId: string): Observable<FirestoreWork[]> {
    return this.sessionState
      .getOrgaId()
      .pipe(
        switchMap((orgaId) =>
          this.orgaFirestoreService.getDocsByCollectionGroup<Work>(
            'work',
            where('projectId', '==', underProjectId),
            where('orgaId', '==', orgaId)
          )
        )
      );
  }

  getWorkByFilter(filter: WorkFilter): Observable<FirestoreWork[]> {
    return this.sessionState.getOrgaId().pipe(
      map((orgaId) => {
        const filterQuery: QueryConstraint[] = [where('orgaId', '==', orgaId)];
        if (filter.project) {
          filterQuery.push(where('projectId', '==', filter.project.id));
        }
        if (filter.position) {
          filterQuery.push(where('positionId', '==', filter.position.id));
        }
        if (filter.user) {
          filterQuery.push(where('userId', '==', filter.user.id));
        }
        if (filter.date.type !== 'notSet') {
          const interval = getInterval(filter.date);
          filterQuery.push(
            where('date', '>=', formatISO(interval.start, { representation: 'date' })),
            where('date', '<=', formatISO(interval.end, { representation: 'date' }))
          );
        }
        filterQuery.push(orderBy('date', 'asc'));
        return filterQuery;
      }),
      switchMap((filterQuery) =>
        this.orgaFirestoreService.getDocsByCollectionGroup<Work>('work', ...filterQuery)
      )
    );
  }

  getAllWorkForProjectBetween(
    underProjectId: string,
    from: Date,
    to: Date
  ): Observable<FirestoreWork[]> {
    return this.orgaFirestoreService.getDocs<Work>(
      `project/${underProjectId}/work`,
      where('date', '>=', formatISO(from, { representation: 'date' })),
      where('date', '<=', formatISO(to, { representation: 'date' }))
    );
  }

  getAllBillableWorkForProjectBetweenDate2(
    underProjectId: string,
    from: Date,
    to: Date
  ): Observable<FirestoreWork[]> {
    return this.orgaFirestoreService.getDocs<Work>(
      `project/${underProjectId}/work`,
      where('date', '>=', from),
      where('date', '<=', to),
      where('positionBillable', '==', true)
    );
  }

  async createWorks(works: WorkEntity[]) {
    const orgaId = await firstValueFrom(this.sessionState.getOrgaId());
    const batch = writeBatch(this.firestore);
    await Promise.all(
      works.map(async (work) =>
        batch.set(
          doc(collection(this.firestore, `orga/${orgaId}/project/${work.projectId}/work`)),
          await this.lastEditor.hydrate(work)
        )
      )
    );
    return batch.commit();
  }

  updateWork(work: FirestoreWork, update: Partial<Work>): Promise<void> {
    return this.updateWorkInternal(update, work.docRef.path);
  }

  async updateWorkInternal(work: Partial<Work>, docRefPath: string): Promise<void> {
    return this.rootFirestoreService.updateDoc<Work>(
      docRefPath,
      await this.lastEditor.hydrate(work)
    );
  }

  async deleteWork(work: FirestoreWork): Promise<unknown> {
    // do a update to insert lastEditor, else a delete would fail
    await this.updateWorkInternal({}, work.docRef.path);
    return this.rootFirestoreService.deleteDoc(work.docRef.path);
  }
}

export function createBaseWork(
  work: {
    description: string;
    hours: number;
    minutes: number;
  },
  user: {
    userId: string;
    userDisplayName: string;
  },
  orgaId: string,
  project: FirestoreProject,
  position: Position
): BaseWork {
  return {
    orgaId,
    userId: user.userId,
    userDisplayName: user.userDisplayName,

    description: work.description,
    workMinutes: work.hours * 60 + work.minutes,

    positionId: position.id,
    positionDisplayName: position.name,
    positionBillable: position.billable,
    positionType: position.positionType,

    projectId: project.id,
    projectDisplayName: project.data.name,
    customerId: project.data.customerId,
    customerName: project.data.customerName,

    billed: false,
  };
}
