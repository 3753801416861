import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateRange, WorkFilter } from 'commons';
import { NgIf } from '@angular/common';
import { SimplePillComponent } from '../../../../../components/filterpills/simple-pill.component';
import { DateFilterPillComponent } from '../../../../../components/filterpills/date-filter-pill.component';

@Component({
  selector: 'app-work-table-filter',
  templateUrl: './work-table-filter.component.html',
  standalone: true,
  imports: [SimplePillComponent, DateFilterPillComponent, NgIf],
})
export class WorkTableFilterComponent {
  @Input()
  filter: WorkFilter = {
    project: null,
    position: null,
    user: null,
    date: { type: 'notSet' },
  };

  @Input()
  projectFilterValues: { id: string; displayText: string }[] = [];
  @Input()
  positionFilterValues: { id: string; displayText: string }[] = [];
  @Input()
  userFilterValues: { id: string; displayText: string }[] = [];

  @Input()
  showFilter: ('project' | 'position' | 'user')[] = [];

  @Output()
  filterChanged = new EventEmitter<WorkFilter>();

  dateChanged(dateRange: DateRange) {
    this.filterChanged.emit({
      ...this.filter,
      date: dateRange,
    });
  }

  projectChanged(filter: { id: string; displayText: string } | null) {
    this.filterChanged.emit({
      ...this.filter,
      project: filter,
    });
  }
  positionChanged(filter: { id: string; displayText: string } | null) {
    this.filterChanged.emit({
      ...this.filter,
      position: filter,
    });
  }
  userChanged(filter: { id: string; displayText: string } | null) {
    this.filterChanged.emit({
      ...this.filter,
      user: filter,
    });
  }
}
