import { Component, Input } from '@angular/core';
import { FormatMinutesPipe } from '../../../../../pipes/format-minutes.pipe';

@Component({
    selector: 'tr[app-project-stats-row]',
    templateUrl: './project-stats-row.component.html',
    standalone: true,
    imports: [FormatMinutesPipe],
})
export class ProjectStatsRowComponent {
  @Input()
  projectName!: string;

  @Input()
  totalMinutes!: number;

  @Input()
  billableMinutes!: number;

  @Input()
  unbillableMinutes!: number;
}
