<form
  *ngIf="!showConfirmation"
  class="form-container pl-3"
  [formGroup]="orgaUserForm"
  (ngSubmit)="createOrgaUser(orgaUserForm.value)"
  autocomplete="off"
  novalidate>
  <app-slide-over>
    <app-slide-over-header
      (closeSlideOver)="closeSlideOver()"
      title="Mitarbeiter einladen"
      [formDirty]="orgaUserForm.dirty"
      [leadText]="
        'Jessie verschickt <i>keine</i> E-Mail an den neuen Mitarbeiter. Er kann sich jedoch direkt mit dieser E-Mail Adresse einloggen und sieht deine Organisation.'
      ">
    </app-slide-over-header>

    <app-slide-over-content>
      <div class="px-4 divide-y divide-gray-200 sm:px-6 overflow-y-auto">
        <div class="space-y-6 pt-6 pb-5">
          <div class="space-y-1">
            <app-input label="E-Mail Adresse" class="mt-4"
              ><input appTailwindInput formControlName="email" type="text"
            /></app-input>
          </div>
          <div class="divide-y divide-gray-200">
            <fieldset class="space-y-2 pb-5">
              <legend class="text-sm leading-5 font-medium text-gray-900">Permission Level</legend>
              <div class="space-y-5">
                <div class="relative flex items-start" *ngIf="showAccountantRole | async">
                  <div class="absolute flex items-center h-5">
                    <input
                      id="orgaUserPermission.accountant"
                      aria-describedby="positionType_fix_description"
                      type="radio"
                      value="accountant"
                      name="role"
                      class="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                      formControlName="role" />
                  </div>
                  <div class="pl-7 text-sm leading-5">
                    <label for="orgaUserPermission.accountant" class="font-medium text-gray-900">
                      Buchhaltung
                    </label>
                    <p id="orgaUserPermission.accountant_description" class="text-gray-500">
                      Buchhalter:innen haben nur Zugang zu Dokumenten welche als
                      Buchhaltungsrelevant ausgezeichnet wurden.
                    </p>
                  </div>
                </div>
                <div class="relative flex items-start" *ngIf="showEmployeeRole | async">
                  <div class="absolute flex items-center h-5">
                    <input
                      id="orgaUserPermission.standard"
                      aria-describedby="positionType_timeAndMaterial_description"
                      type="radio"
                      value="standard"
                      name="role"
                      class="form-radio h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                      formControlName="role" />
                  </div>
                  <div class="pl-7 text-sm leading-5">
                    <label for="orgaUserPermission.standard" class="font-medium text-gray-900">
                      Mitarbeiter
                    </label>
                    <p id="orgaUserPermission.standard_description" class="text-gray-500">
                      Mitarbeiter haben Zugang zu Projekten, Kunden, Dokumenten und Organisation.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </app-slide-over-content>

    <app-slide-over-footer>
      <span class="inline-flex rounded-md shadow-sm">
        <button appTailwindButton color="secondary" type="button" (click)="closeSlideOver()">
          Abbrechen
        </button>
      </span>
      <span class="inline-flex rounded-md shadow-sm">
        <button appTailwindButton type="submit" [disabled]="!orgaUserForm.valid">Speichern</button>
      </span>
    </app-slide-over-footer>
  </app-slide-over>
</form>

<app-dialog
  *ngIf="showConfirmation"
  icon="heroExclamationCircle"
  color="green"
  title="Neuer User angelegt"
  description="Jessie verschickt keine E-Mail an den neuen Mitarbeiter. Er kann sich jedoch direkt mit dieser E-Mail Adresse einloggen und sieht deine Organisation."
  actionText="Ok"
  (actionClicked)="closeSlideOver()"></app-dialog>
