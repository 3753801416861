<div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img
      style="filter: invert(100%)"
      class="mx-auto h-12 w-auto"
      src="/assets/jessie-logo.png"
      alt="Workflow" />
    <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
      Logge Dich hier ein
    </h2>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form [formGroup]="loginForm" autocomplete="on">
        <app-input
          label="Email Adresse"
          [errors]="
            loginForm.controls['mailAddress']?.touched
              ? loginForm.controls['mailAddress']?.errors
              : null
          ">
          <input
            formControlName="mailAddress"
            type="email"
            required
            autocomplete="email"
            appTailwindInput />
        </app-input>

        <div class="mt-6">
          <button
            class="w-full justify-center"
            (click)="login()"
            appTailwindButton
            [disabled]="disableButton">
            Anmelden
          </button>
        </div>
      </form>
    </div>
  </div>

  <app-dialog
    *ngIf="showErrorDialog"
    icon="heroExclamationCircle"
    color="red"
    [title]="error"
    description=""
    actionText="Zurück zum Login"
    (actionClicked)="closeModal()"></app-dialog>

  <app-dialog
    *ngIf="showWaitDialog"
    title="Magic Link verschickt"
    description="Check Deinen E-Mail Eingang und klicke auf den Login Link!"
    actionText="Zurück zum Login"
    (actionClicked)="closeModal()"></app-dialog>
</div>
