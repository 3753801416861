import { inject, Injectable } from '@angular/core';
import { FirestoreHistory, History } from 'commons';
import { Observable } from 'rxjs';
import { ORGA_FIRESTORE_SERVICE } from './firestore.service';
import { limit, where } from '@angular/fire/firestore';
import { SessionStateService } from './session-state.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private orgaFirestoreService = inject(ORGA_FIRESTORE_SERVICE);
  private sessionState = inject(SessionStateService);

  getHistory(id: string): Observable<FirestoreHistory[]> {
    return this.sessionState
      .getOrgaId()
      .pipe(
        switchMap((orgaUserId) =>
          this.orgaFirestoreService.getDocsByCollectionGroup<History>(
            'history',
            where('orgaId', '==', orgaUserId),
            where('id', '==', id),
            where('lastEditor', '!=', false),
            limit(100)
          )
        )
      );
  }
}
