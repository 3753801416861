import { Component, HostListener, Injectable, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import {
  createDateRange,
  createUserDisplayName,
  FirestoreProject,
  FirestoreUser,
  FirestoreWork,
  Position,
  WorkEntity,
} from 'commons';
import { SlideOverComponent } from '../../../components/slide-overs/slide-over/slide-over.component';
import { SlideOverService } from '../../../components/slide-overs/slide-over/slide-over-service';
import { SlideOverHeaderComponent } from '../../../components/slide-overs/slide-over/slide-over-header/slide-over-header.component';
import { SlideOverContentComponent } from '../../../components/slide-overs/slide-over/slide-over-content/slide-over-content.component';
import { NgIf } from '@angular/common';
import { ToggleComponent } from '../../../components/toggle/toggle.component';
import { InputComponent } from '../../../components/input/input.component';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { SlideOverFooterComponent } from '../../../components/slide-overs/slide-over/slide-over-footer/slide-over-footer.component';
import { DropdownMinimalMenuComponent } from '../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { DropdownButtonComponent } from '../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DropdownItemComponent } from '../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { createBaseWork, WorkService } from '../../../services/work.service';
import { SessionStateService } from '../../../services/session-state.service';
import { format, formatISO, parseISO } from 'date-fns';

export const hoursOrMinutesAreRequired: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const hours = control.get('hours');
  const minutes = control.get('minutes');

  return hours?.value || minutes?.value ? null : { eitherHoursOrMinutesAreMissing: true };
};

@Injectable({
  providedIn: 'root',
})
export class WorkSlideOverService extends SlideOverService<
  FirestoreWork,
  { project: FirestoreProject; position: Position },
  void
> {}

@Component({
  selector: 'app-work-slide-over',
  templateUrl: './work-slide-over.component.html',
  standalone: true,
  imports: [
    SlideOverComponent,
    SlideOverHeaderComponent,
    SlideOverContentComponent,
    ReactiveFormsModule,
    NgIf,
    ToggleComponent,
    InputComponent,
    TailwindInputDirective,
    SlideOverFooterComponent,
    DropdownMinimalMenuComponent,
    DropdownButtonComponent,
    NgIconWrapperComponent,
    DropdownItemComponent,
    TailwindButtonDirective,
  ],
})
export class WorkSlideOverComponent implements OnInit, OnDestroy {
  workForm!: FormGroup;
  extendedForm = false;
  isShowExtendedFormEnabled = true;

  title!: string;

  private subscriptions = new Subscription();

  @HostListener('document:keydown.enter', ['$event'])
  onEnterKeydownHandler(event: KeyboardEvent) {
    this.submitForm(this.workForm.value);
  }

  constructor(
    private workService: WorkService,
    private sessionState: SessionStateService,
    public workSlideOver: WorkSlideOverService
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.initForm();
    this.title = this.workSlideOver.getMode() === 'new' ? 'Neue Arbeit' : 'Arbeit bearbeiten';
  }

  initForm(): void {
    if (this.workSlideOver.getMode() == 'edit') {
      let work = this.workSlideOver.getEntity().data;
      this.extendedForm = false;
      this.isShowExtendedFormEnabled = false;
      this.workForm = new FormGroup(
        {
          date: new FormControl(format(work ? parseISO(work.date) : new Date(), 'yyyy-MM-dd'), [
            Validators.required,
          ]),
          description: new FormControl(work.description || '', [Validators.maxLength(500)]),
          hours: new FormControl(Math.floor(work.workMinutes / 60) || '', [Validators.max(10000)]),
          minutes: new FormControl(work.workMinutes % 60 || '', [Validators.max(59)]),
          billed: new FormControl(work.billed),
        },
        { validators: [hoursOrMinutesAreRequired] }
      );
    } else {
      this.workForm = this.extendedForm
        ? new FormGroup(
            {
              startDate: new FormControl('', [Validators.required]),
              endDate: new FormControl('', [Validators.required]),
              description: new FormControl('', [Validators.maxLength(500)]),
              hours: new FormControl('', [Validators.max(10000)]),
              minutes: new FormControl('', [Validators.max(59)]),
              billed: new FormControl(''),
              createWorkForWeekendDays: new FormControl(false),
            },
            { validators: [hoursOrMinutesAreRequired] }
          )
        : new FormGroup(
            {
              date: new FormControl(format(new Date(), 'yyyy-MM-dd'), [Validators.required]),
              description: new FormControl('', [Validators.maxLength(500)]),
              hours: new FormControl('', [Validators.max(10000)]),
              minutes: new FormControl('', [Validators.max(59)]),
              billed: new FormControl(''),
            },
            { validators: [hoursOrMinutesAreRequired] }
          );
    }

    this.disableEnableFields();
    this.subscriptions.add(
      this.workForm.get('billed')?.valueChanges.subscribe(() => this.disableEnableFields())
    );
  }

  disableEnableFields() {
    if (this.workForm.get('billed')?.value) {
      this.workForm.get('date')?.disable();
      this.workForm.get('hours')?.disable();
      this.workForm.get('minutes')?.disable();
    } else {
      this.workForm.get('date')?.enable();
      this.workForm.get('hours')?.enable();
      this.workForm.get('minutes')?.enable();
    }
  }

  closeSlideOver() {
    this.workSlideOver.closeSlideOver();
  }

  submitForm(form: any) {
    if (this.workSlideOver.getMode() === 'edit') {
      const work = this.workSlideOver.getEntity();
      this.workService
        .updateWork(work, {
          description: form.description,
          workMinutes: form.billed
            ? work.data.workMinutes
            : Number(form.hours) * 60 + Number(form.minutes),
          date: form.billed ? work.data.date : form.date,
          billed: form.billed,
        })
        .then(() => this.closeSlideOver());
    }
    if (this.workSlideOver.getMode() === 'new') {
      this.getAllWorkEntries(form)
        .pipe()
        .subscribe({
          next: (works) => this.workService.createWorks(works),
          error: (e) => console.error(e),
          complete: () => this.closeSlideOver(),
        });
    }
  }

  getAllWorkEntries(form: any): Observable<WorkEntity[]> {
    return combineLatest([
      this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user)),
      this.sessionState.getOrgaId(),
    ]).pipe(
      first(),
      map(([fsUser, orgaId]) => ({
        orgaId,
        userId: fsUser.id,
        userDisplayName: createUserDisplayName(fsUser.data.firstName, fsUser.data.lastName),
      })),
      map((user) =>
        createBaseWork(
          {
            description: form.description,
            hours: Number(form.hours),
            minutes: Number(form.minutes),
          },
          user,
          user.orgaId,
          this.workSlideOver.getNewMeta().project,
          this.workSlideOver.getNewMeta().position
        )
      ),
      mergeMap((baseWork) => {
        if (this.extendedForm) {
          return of(
            createDateRange(form.startDate, form.endDate, form.createWorkForWeekendDays).map(
              (day) => ({
                ...baseWork,
                date: formatISO(day, { representation: 'date' }),
              })
            )
          );
        } else {
          return of([
            {
              ...baseWork,
              date: form.date,
            },
          ]);
        }
      })
    );
  }

  showExtendedForm(extendedForm: boolean) {
    this.extendedForm = extendedForm;
    this.initForm();
  }

  deleteWork() {
    this.workService.deleteWork(this.workSlideOver.getEntity());
    this.closeSlideOver();
  }
}
