import { inject, Injectable } from '@angular/core';

import { combineLatest, EMPTY, Observable, of, shareReplay } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { Admin, FirestoreAdmin, FirestoreSystem, System } from 'commons';
import { FIRESTORE_SERVICE } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private rootFirestoreService = inject(FIRESTORE_SERVICE);

  private errorService = inject(ErrorService);

  system$: Observable<FirestoreSystem> = this.getSystem();
  admin$: Observable<FirestoreAdmin> = this.getAdmin();

  checkIfSchemaVersionIsCorrect(orgaSchemaVersion: number): Observable<boolean> {
    return combineLatest([this.getSystemSchemaVersion(), of(orgaSchemaVersion)]).pipe(
      map(([systemSchemaVersion, orgaSchemaVersion]) => systemSchemaVersion === orgaSchemaVersion),
      distinctUntilChanged()
    );
  }

  isSystemOpen(): Observable<boolean> {
    return this.admin$.pipe(
      map((admin) => !admin.data.maintenanceMode),
      shareReplay(1),
      catchError((error) => {
        this.errorService.handleError('isSystemOpen', error);
        return of(false);
      })
    );
  }

  private getSystemSchemaVersion(): Observable<number> {
    return this.system$.pipe(
      map((system) => system.data.schemaVersion),
      catchError((error) => {
        this.errorService.handleError('getSystemSchemaVersion', error);
        return EMPTY;
      })
    );
  }

  private getAdmin(): Observable<FirestoreAdmin> {
    return this.rootFirestoreService.getDoc<Admin>('system/admin').pipe(shareReplay(1));
  }

  private getSystem(): Observable<FirestoreSystem> {
    return this.rootFirestoreService.getDoc<System>('system/system').pipe(shareReplay(1));
  }
}
