import { Component } from '@angular/core';
import { DocumentCardComponent } from '../../document/list/document-card/document-card.component';
import { WorkTimerComponent } from '../work-timer/work-timer.component';
import { CreateWorkComponent } from '../create-work/create-work.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { CalendarComponent } from '../../../components/calendar/calendar/calendar.component';

@Component({
  selector: 'app-timer-page',
  standalone: true,
  imports: [
    DocumentCardComponent,
    WorkTimerComponent,
    CreateWorkComponent,
    CalendarComponent,
    AsyncPipe,
    NgIf,
  ],
  templateUrl: './timer.page.html',
})
export class TimerPage {}
