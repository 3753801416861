import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Comment, FirestoreComment } from 'commons/dist/entities/comment';
import { FirestoreOrgaUser } from 'commons';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommentComponent } from '../comment/comment.component';
import { NgFor } from '@angular/common';
import { NewCommentComponent } from '../new-comment/new-comment.component';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styles: [
    `
      :host {
        @apply flex flex-col h-full;
      }
    `,
  ],
  standalone: true,
  imports: [NewCommentComponent, NgFor, CommentComponent],
})
export class CommentListComponent {
  @Input() comments!: FirestoreComment[];
  @Input() orgaUsers!: FirestoreOrgaUser[];
  @Input() loggedInUserId!: string;
  @Input() set padded(value: any) {
    this._padded = coerceBooleanProperty(value);
  }

  @Output() createNewComment = new EventEmitter<Partial<Comment>>();
  @Output() deleteComment = new EventEmitter<FirestoreComment>();
  @Output() markAsReacted = new EventEmitter<{ comment: FirestoreComment; userId: string }>();

  @HostBinding('class.padded')
  _padded = false;

  trackById(index: number, item: any): any {
    return item.id;
  }
}
