import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-email-landing-page',
  templateUrl: './email-landing-page.component.html',
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, RouterLink],
})
export class EmailLandingPageComponent implements OnInit {
  loginForm!: FormGroup;
  mustConfirmEmail = false;
  mailAddress = new FormControl('');
  url!: string;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  async ngOnInit() {
    await this.login();

    this.loginForm = new FormGroup({
      mailAddress: this.mailAddress,
    });
  }

  async login() {
    const signInResult = await this.authService.signInWithUrl(this.router.url);
    switch (signInResult) {
      case 'NoEmail':
        this.mustConfirmEmail = true;
        break;
      case 'ExpiredLink':
      case 'InvalidEmail':
      case 'WrongEmail':
      case 'UserDisabled':
      case 'NoValidSignInUrl':
        console.log('sign in failed with ', signInResult);
        await this.router.navigate(['/user/login']);
        break;
      case 'SignInDone':
        console.log('sign in successful');
        await this.router.navigate(['home']);
        break;
    }
  }

  async setLoginEmail(email: string | null | undefined) {
    if (!email) {
      return;
    }
    await this.authService.signInEmail(email);
    await this.login();
  }
}
