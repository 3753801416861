import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private router: Router) {}

  handleError(message: string, error: any) {
    console.error(message, error);
    if (error?.name === 'FirebaseError') {
      switch (error?.code) {
        case 'permission-denied': {
          this.router.navigate(['/user/login']);
          break;
        }
      }
    }
  }
}
