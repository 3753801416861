import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HistoryComponent } from '../../../history/history.component';

@Component({
  selector: 'app-project-history',
  templateUrl: './project-history.component.html',
  standalone: true,
  imports: [HistoryComponent],
})
export class ProjectHistoryComponent {
  projectId!: string;

  constructor(private route: ActivatedRoute) {
    this.projectId = this.route.parent?.snapshot.params['projectId'];
  }
}
