<app-slide-over>
  <app-slide-over-header
    (closeSlideOver)="closeSlideOver()"
    [formDirty]="projectForm.dirty"
    [title]="title"
    [leadText]="'hm, ja was schreiben wir hier? eventuell auch nichts. Aber mach mal!'">
  </app-slide-over-header>

  <app-slide-over-content>
    <form [formGroup]="projectForm">
      <div class="px-4 divide-y divide-gray-200 sm:px-6">
        <div class="space-y-6 pt-6 pb-5">
          <div>
            <app-input
              label="Name"
              class="mt-4"
              [errors]="projectForm.get('name')?.touched ? projectForm.get('name')?.errors : null"
              ><input appTailwindInput type="text" formControlName="name" appSelectAll
            /></app-input>
          </div>

          <div>
            <app-input label="Kunde">
              <ng-select
                class="custom"
                [items]="customers$ | async"
                bindLabel="displayName"
                formControlName="customer">
              </ng-select>
            </app-input>
          </div>
          <div class="pt-4">
            <app-toggle label="Aktiv" formControlName="isActive"></app-toggle>
          </div>
        </div>
      </div>
    </form>
  </app-slide-over-content>

  <app-slide-over-footer>
    <span class="inline-flex rounded-md shadow-sm">
      <button appTailwindButton color="secondary" type="button" (click)="closeSlideOver()">
        Abbrechen
      </button>
    </span>
    <span class="inline-flex rounded-md shadow-sm">
      <button appTailwindButton [disabled]="false" (click)="save()">Speichern</button>
    </span>
  </app-slide-over-footer>
</app-slide-over>
