import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-tab-group',
    templateUrl: './tab-group.component.html',
    standalone: true,
})
export class TabGroupComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
