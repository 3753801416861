<div *ngIf="(positions$ | async)?.length === 0; else weHavePositions">
  <app-empty
    (buttonClick)="createPosition()"
    message="Mit Positionen gibst du deinem Projekt Struktur. Positionen sind einzelne Arbeitspakete um deine Stunden zu erfassen."></app-empty>
</div>

<ng-template #weHavePositions>
  <div class="flex flex-col md:flex-row-reverse gap-4">
    <div *ngIf="totals$ | async as total" class="min-w-max">
      <dl
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-5 sm:shadow sm:rounded-lg px-4 py-5 bg-gray-400/5">
        <div>
          <dt class="text-sm font-medium text-gray-500">Total gearbeitet (h)</dt>
          <dd class="mt-1 font-semibold tracking-tight text-gray-900">
            {{ total.totalWorkedMinutes | formatWork }}
          </dd>
        </div>

        <div>
          <dt class="text-sm font-medium text-gray-500">Total Umsatz excl. MwSt (CHF)</dt>
          <dd class="mt-1 font-semibold tracking-tight text-gray-900">
            {{ total.totalExclVat | number: '1.2-2' : 'de-CH' }}
          </dd>
        </div>

        <div>
          <dt class="text-sm font-medium text-gray-500">Total Umsatz (CHF)</dt>
          <dd class="mt-1 font-semibold tracking-tight text-gray-900">
            {{ total.total | number: '1.2-2' : 'de-CH' }}
          </dd>
        </div>

        <div>
          <dt class="text-sm font-medium text-gray-500">In Rechnung gestellt (CHF)</dt>
          <dd class="mt-1 font-semibold tracking-tight text-gray-900">
            {{ totalBilled | async | number: '1.2-2' : 'de-CH' }}
          </dd>
        </div>
      </dl>
    </div>

    <div class="flex flex-col gap-4 w-full">
      <app-position-card
        [position]="position"
        [projectId]="(project$ | async)?.id!"
        (createWork)="createWork(position)"
        (modifyPosition)="modifyPosition(position)"
        *ngFor="let position of positions$ | async">
      </app-position-card>
    </div>
  </div>
  <app-divider-button buttonText="Position" (buttonClick)="createPosition()">
    <app-ng-icon class="text-gray-400" strokeWidth="3" name="heroPlus"></app-ng-icon>
  </app-divider-button>
</ng-template>
