import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgIf } from '@angular/common';
import { filter } from 'rxjs/operators';
import { FirestoreUser } from 'commons';
import { SessionStateService } from '../../services/session-state.service';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styles: [
    `
      :host {
        @apply flex;
      }
    `,
  ],
  standalone: true,
  imports: [NgIf, NgIconWrapperComponent],
})
export class FavoriteComponent implements OnInit, OnChanges {
  userId!: string;
  activated = false;

  @Input()
  favorite: Record<string, boolean> = {};

  @Output()
  stateChange = new EventEmitter<boolean>();

  constructor(private sessionState: SessionStateService) {}

  async ngOnInit() {
    this.userId = await firstValueFrom(
      this.sessionState.getUser().pipe(
        filter((user): user is FirestoreUser => !!user),
        map((user) => user.id)
      )
    );
    this.setCurrentValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setCurrentValue();
  }

  setCurrentValue() {
    this.activated = !!this.favorite && this.userId in this.favorite && this.favorite[this.userId];
  }

  click() {
    this.stateChange.emit(!this.activated);
  }
}
