import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { NgIcon } from '@ng-icons/core';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';

@Component({
  selector: 'app-settings-card',
  templateUrl: './settings-card.component.html',
  standalone: true,
  imports: [NgIf, NgIcon, NgIconWrapperComponent],
})
export class SettingsCardComponent {
  @Input()
  heading!: string;
  @Input()
  description!: string;
  @Input()
  disabled = false;
  @Input()
  showFooter = true;
  @Input()
  showSaveButton = true;

  @Output()
  save = new EventEmitter();

  isSaving = false;
  prepareSave() {
    setTimeout(() => (this.isSaving = true), 300);
    this.save.emit();
    setTimeout(() => (this.isSaving = false), 2000);
  }
}
