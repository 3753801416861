import { Component, Input } from '@angular/core';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, NgClass, RouterLink],
})
export class ContainerComponent {
  @Input() sidebar = false;
  @Input() subpage = false;

  constructor(public route: ActivatedRoute) {}
}
