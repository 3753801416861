import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Document, FirestoreDocument, FirestoreEntity } from 'commons';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  standalone: true,
  imports: [NgIf],
})
export class DocumentListComponent {
  @Input()
  documents!: FirestoreEntity<Document>[];

  @Output()
  editDocument = new EventEmitter<FirestoreDocument>();

  constructor() {}

  edit(document: FirestoreDocument) {
    this.editDocument.emit(document);
  }
}
