<app-dark-nav-shell
  [navMenuEntries]="(navMenu$ | async)!"
  [profileMenuEntries]="(profileMenu$ | async)!"
  [userDisplayName]="(userDisplayName$ | async)!"
  [userEmailAddress]="(userEmailAddress$ | async)!"
  [userInitials]="(userInitials$ | async)!"
  [orgaName]="(orgaName$ | async)!"
  [orgaId]="(orgaId$ | async)!"
  [timerRunningTime]="timer$ | async | secondsPassed | formatSeconds">
  <router-outlet></router-outlet>
</app-dark-nav-shell>

<app-project-slide-over *ngIf="projectSlideOver.isOpen()"></app-project-slide-over>
<app-work-slide-over *ngIf="workSlideOver.isOpen()"></app-work-slide-over>
<app-customer-slide-over *ngIf="customerSlideOver.isOpen()"></app-customer-slide-over>
<app-position-slide-over *ngIf="positionSlideOver.isOpen()"></app-position-slide-over>
<app-document-slide-over *ngIf="documentSlideOver.isOpen()"></app-document-slide-over>
<app-invoice-slide-over *ngIf="invoiceSlideOver.isOpen()"></app-invoice-slide-over>
