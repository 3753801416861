import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
    selector: 'li[app-step-item]',
    templateUrl: './step-item.component.html',
    styles: [':host { @apply md:flex-1}'],
    standalone: true,
    imports: [NgSwitch, NgSwitchCase],
})
export class StepItemComponent {
  @Input()
  stepTitle = '';
  @Input()
  stepSubTitle = '';
  @Input()
  progress: StepProgress = 'upcoming';

  @Output()
  stepClicked = new EventEmitter();
}

export type StepProgress = 'completed' | 'current' | 'upcoming';
