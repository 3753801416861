<app-settings-card
  heading="Profil"
  description="Einstellungen an dieser Stelle sind sofort in in allen Organisationen wo du arbeitest ersichtlich."
  (save)="saveSettings()">
  <form [formGroup]="profileForm">
    <div class="mt-6 grid grid-cols-4 gap-6">
      <div class="col-span-4 sm:col-span-2">
        <app-input label="Vorname" [errors]="profileForm.get('firstName')?.errors ?? {}">
          <input
            type="text"
            name="first-name"
            id="first-name"
            autocomplete="given-name"
            [formControlName]="'firstName'"
            appTailwindInput />
        </app-input>
      </div>

      <div class="col-span-4 sm:col-span-2">
        <app-input label="Nachname" [errors]="profileForm.get('lastName')?.errors">
          <input
            type="text"
            name="last-name"
            id="last-name"
            autocomplete="family-name"
            [formControlName]="'lastName'"
            appTailwindInput />
        </app-input>
      </div>
      <app-toggle
        [formControlName]="'enableTimer'"
        label="Timerfunktion aktivieren"
        [displayShort]="true"></app-toggle>
    </div>
  </form>
</app-settings-card>
