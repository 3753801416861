<div class="shadow sm:rounded-lg flex flex-col bg-white divide-y divide-gray-200">
  <div class="flex flex-row self-center">
    <ng-container *ngIf="months$ | async as months">
      <app-month-calendar
        [month]="months[0]"
        [work]="(this.work$ | async)!"
        (selectDate)="selectDate($event)"
        [selectedDate]="(selectedDate | async)!"
        class="hidden 2xl:block"></app-month-calendar>
      <app-month-calendar
        [month]="months[1]"
        [work]="(this.work$ | async)!"
        (selectDate)="selectDate($event)"
        [selectedDate]="(selectedDate | async)!"
        class="hidden md:block"></app-month-calendar>
      <app-month-calendar
        [month]="months[2]"
        [work]="(this.work$ | async)!"
        (selectDate)="selectDate($event)"
        [selectedDate]="(selectedDate | async)!"></app-month-calendar>
    </ng-container>
  </div>

  <div class="md:py-8 py-4 md:px-16 sm:px-4 bg-gray-50 rounded-b">
    <ng-container *ngIf="selectedDateWorkItems$ | async as workItems">
      <div
        class="w-full flex flex-col items-center text-center pb-2"
        [class.border-b]="workItems?.length">
        <p tabindex="0" class="focus:outline-none text-base font-bold text-gray-800">
          {{ selectedDate | async | date: 'dd. MMM' }}
        </p>
        <p *ngIf="workItems?.length" class="text-sm text-gray-600">
          {{ selectedDateWorkItemsSum$ | async }}h
        </p>
      </div>
      <div *ngIf="workItems?.length" class="mt-5 bg-white shadow overflow-hidden sm:rounded-md">
        <ul role="list" class="divide-y divide-gray-200">
          <li
            app-calendar-work-entry
            *ngFor="let work of workItems"
            [projectName]="work.data.projectDisplayName"
            [projectId]="work.data.projectId"
            [description]="work.data.description"
            [positionName]="work.data.positionDisplayName"
            [userDisplayName]="work.data.userDisplayName"
            [billable]="work.data.positionBillable"
            [billed]="work.data.billed"
            [workHours]="(work.data.workMinutes / 60).toFixed(2)"
            (deleteWork)="deleteWork(work)"
            (modifyWork)="modifyWork(work)"></li>
        </ul>
      </div>

      <div *ngIf="!workItems?.length" class="text-center">
        <h3 class="mt-2 text-sm font-medium text-gray-900">Da ist noch nichts</h3>
        <p class="mt-1 text-sm text-gray-500">Erfasse hier Arbeit für diesen Tag.</p>
      </div>
    </ng-container>

    <div class="mt-6 text-center">
      <button appTailwindButton (click)="createWork.emit(selectedDate.value)">
        Arbeit erfassen
      </button>
    </div>
  </div>
</div>

<div class="absolute top-0 right-0 m-8 flex items-center">
  <button
    aria-label="calendar backward"
    (click)="previousMonth()"
    class="hover:text-gray-400 text-gray-800 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
    <app-ng-icon class="text-xl" name="heroChevronLeft"></app-ng-icon>
  </button>
  <button
    aria-label="calendar forward"
    (click)="nextMonth()"
    class="hover:text-gray-400 ml-3 text-gray-800 border border-transparent rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
    <app-ng-icon class="text-xl" name="heroChevronRight"></app-ng-icon>
  </button>
</div>
