import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Orga } from 'commons';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { InputComponent } from '../../../components/input/input.component';
import { SettingsCardComponent } from '../settings-card/settings-card.component';

@Component({
    selector: 'app-orga-banking-card',
    templateUrl: './orga-banking-card.component.html',
    standalone: true,
    imports: [
        SettingsCardComponent,
        ReactiveFormsModule,
        InputComponent,
        TailwindInputDirective,
    ],
})
export class OrgaBankingCardComponent implements OnInit {
  @Input()
  orga!: Orga;

  @Input()
  disabled = false;

  @Output()
  bankDetailsChanged = new EventEmitter<Partial<Orga>>();

  bankDetailForm!: FormGroup;

  ngOnInit(): void {
    this.bankDetailForm = new FormGroup({
      name: new FormControl(this.orga.bankDetails?.name),
      iban: new FormControl(this.orga.bankDetails?.iban),
      zipCode: new FormControl(this.orga.bankDetails?.zipCode),
      city: new FormControl(this.orga.bankDetails?.city),
    });
  }

  saveSettings() {
    if (!this.bankDetailForm.invalid) {
      this.bankDetailsChanged.emit({
        bankDetails: this.bankDetailForm.value,
      });
    }
  }
}
