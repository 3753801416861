import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    standalone: true,
})
export class CardComponent {
  @Output()
  closeCard = new EventEmitter();
}
