import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownItemComponent } from '../dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { DropdownButtonComponent } from '../dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../dropdown-minimal-menu/dropdown-minimal-menu.component';
import { NgIconWrapperComponent } from '../icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-simple-pill',
    templateUrl: './simple-pill.component.html',
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgIconWrapperComponent,
        DropdownMinimalMenuComponent,
        DropdownButtonComponent,
        NgFor,
        DropdownItemComponent,
    ],
})
export class SimplePillComponent {
  @Input()
  label = '';
  @Input()
  filter: { id: string; displayText: string } | null = null;

  @Input()
  filterValues: { id: string; displayText: string }[] = [];

  @Output()
  filterChanged = new EventEmitter<{
    id: string;
    displayText: string;
  } | null>();

  resetFilter() {
    this.filterChanged.emit(null);
  }

  enableFilter(val: { id: string; displayText: string }) {
    this.filterChanged.emit(val);
  }
}
