<td class="px-10 py-4 whitespace-nowrap text-xs font-medium text-blue-900">
  {{ positionName }}
</td>
<td class="px-6 py-4 whitespace-nowrap text-xs text-blue-500">
  {{ totalMinutes | formatMinutes }}
</td>
<td class="px-6 py-4 whitespace-nowrap text-xs text-blue-500">
  {{ billableMinutes | formatMinutes }}
</td>
<td class="px-6 py-4 whitespace-nowrap text-xs text-blue-500">
  {{ unbillableMinutes | formatMinutes }}
</td>
