import { Component } from '@angular/core';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ProjectListEntryComponent } from '../components/project-list-entry/project-list-entry.component';
import { WorkTableComponent } from '../components/work/work-table/work-table.component';
import { WorkViewComponent } from '../components/work/work-view.component';

@Component({
  selector: 'app-work',
  templateUrl: './work.page.html',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    ProjectListEntryComponent,
    EmptyComponent,
    AsyncPipe,
    WorkTableComponent,
    WorkViewComponent,
  ],
})
export class WorkPage {}
