import { Component } from '@angular/core';
import { DocumentCardComponent } from '../../document/list/document-card/document-card.component';
import { WorkTimerComponent } from '../work-timer/work-timer.component';
import { CreateWorkComponent } from '../create-work/create-work.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { DocumentService } from '../../../services/document.service';
import { CalendarComponent } from '../../../components/calendar/calendar/calendar.component';
import { WorkViewComponent } from '../../projects/components/work/work-view.component';
import { filter, switchMap } from 'rxjs/operators';
import { FirestoreOrgaUser } from 'commons';
import { EmptyStateComponent } from '../../../components/empty/empty-state.component';

@Component({
  selector: 'app-my-comment-page',
  standalone: true,
  imports: [
    DocumentCardComponent,
    WorkTimerComponent,
    CreateWorkComponent,
    CalendarComponent,
    AsyncPipe,
    NgIf,
    WorkViewComponent,
    EmptyStateComponent,
  ],
  templateUrl: './my-comments.page.html',
})
export class MyCommentsPage {
  documents = this.sessionService.getOrgaUser().pipe(
    filter((orgaUser): orgaUser is FirestoreOrgaUser => !!orgaUser),
    switchMap((user) => this.documentService.getDocumentsWithNotReactedComments(user?.data.userId))
  );

  constructor(
    private documentService: DocumentService,
    private sessionService: SessionStateService
  ) {}

  async ngOnInit() {}
}
