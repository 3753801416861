<app-container>
  <app-header-meta-action title="Firmen und Personen">
    <app-dropdown-minimal-menu>
      <app-dropdown-button>
        <button appTailwindButton type="button">Firma oder Person anlegen</button>
      </app-dropdown-button>
      <app-dropdown-item (menuClick)="createCustomer('company')">Firma anlegen</app-dropdown-item>
      <app-dropdown-item (menuClick)="createCustomer('individual')"
        >Person anlegen
      </app-dropdown-item>
    </app-dropdown-minimal-menu>
  </app-header-meta-action>

  <!--  <app-wide-list [listEntries]="customers$ | async"></app-wide-list>-->
  <ng-container *ngIf="customers$ | async as customers">
    <div
      class="mt-2 bg-white shadow overflow-hidden sm:rounded-md"
      *ngIf="customers.length > 0; else noCustomers">
      <ul>
        <li
          app-customer-list-entry
          [customer]="customer"
          [ngClass]="{ 'border-t border-gray-200': !isFirst }"
          *ngFor="let customer of customers; first as isFirst"></li>
      </ul>
    </div>
    <ng-template #noCustomers>
      <app-empty
        message="Lege deine erste Firma an."
        (buttonClick)="createCustomer('company')"></app-empty>
      <app-empty
        message="Oder eine Person?"
        (buttonClick)="createCustomer('individual')"></app-empty>
    </ng-template>
  </ng-container>
</app-container>
