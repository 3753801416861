<section aria-labelledby="payment-details-heading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 sm:p-6">
      <div>
        <div class="flex items-center gap-2">
          <h2 id="payment-details-heading" class="text-lg leading-6 font-medium text-gray-900">
            {{ heading }}
          </h2>
          <app-ng-icon *ngIf="disabled" name="heroLockClosed"> </app-ng-icon>
        </div>
        <p *ngIf="description" class="mt-1 text-sm text-gray-500">
          {{ description }}
        </p>
      </div>

      <ng-content></ng-content>
    </div>
    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6" *ngIf="showFooter">
      <button
        *ngIf="showSaveButton"
        type="button"
        [attr.disabled]="disabled ? '' : null"
        (click)="prepareSave()"
        class="relative bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:opacity-50 disabled:cursor-not-allowed">
        Speichern
        <p *ngIf="isSaving" class="absolute left-0 -ml-24 text-green-600">Gespeichert</p>
      </button>
    </div>
  </div>
</section>
