import { Component, OnInit } from '@angular/core';
import { FirestoreUser, User } from 'commons';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { UserProfileCardComponent } from './user-profile-card.component';
import { NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  standalone: true,
  imports: [NgIf, UserProfileCardComponent],
})
export class UserSettingsComponent implements OnInit {
  user!: FirestoreUser;

  constructor(
    private userService: UserService,
    private sessionState: SessionStateService
  ) {}

  async ngOnInit() {
    this.user = await firstValueFrom(
      this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user))
    );
  }

  profileChanged(user: Partial<User>) {
    this.userService.update(this.user.id, user).then(() => console.log('done'));
  }
}
