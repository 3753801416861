import { Component } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { PositionSlideOverService } from '../positions/position-slide-over/position-slide-over.component';
import { firstValueFrom, Observable, shareReplay } from 'rxjs';
import { FirestoreProject } from 'commons';
import { AsyncPipe, NgIf } from '@angular/common';
import { ContainerComponent } from '../../../../components/container/container.component';
import { HeaderMetaActionComponent } from '../../../../components/container/header-meta-action/header-meta-action.component';
import { FavoriteComponent } from '../../../../components/favorite/favorite.component';
import { TailwindButtonDirective } from '../../../../components/button/tailwind-button.directive';
import { TabGroupComponent } from '../../../../components/tab/tab-group.component';
import { TabDirective } from '../../../../components/tab/tab.directive';
import { HideWhenNotInPlanDirective } from '../../../../directives/hide-when-not-in-plan.directive';
import { ProjectService } from '../../../../services/project.service';
import { InvoiceSlideOverService } from '../../../finance/invoices/invoice-slide-over/invoice-slide-over.component';
import { ProjectSlideOverService } from '../../slide-overs/project-slide-over.component';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  standalone: true,
  imports: [
    NgIf,
    ContainerComponent,
    HeaderMetaActionComponent,
    FavoriteComponent,
    TailwindButtonDirective,
    TabGroupComponent,
    TabDirective,
    RouterLinkActive,
    RouterLink,
    HideWhenNotInPlanDirective,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class ProjectViewComponent {
  project$: Observable<FirestoreProject>;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private projectSlideOver: ProjectSlideOverService,
    private positionSlideOver: PositionSlideOverService,
    private invoiceSlideOver: InvoiceSlideOverService
  ) {
    this.project$ = this.projectService
      .getProject(this.route.snapshot.params['projectId'])
      .pipe(shareReplay(1));
  }

  async editProject() {
    this.projectSlideOver.edit(await firstValueFrom(this.project$));
  }

  async createPosition() {
    const project = await firstValueFrom(this.project$);
    this.positionSlideOver.new({
      project,
    });
  }

  async favoriteChanged(activated: boolean) {
    const project = await firstValueFrom(this.project$);
    this.projectService.toggleFavorite(project, activated);
  }

  async createInvoice() {
    const project = await firstValueFrom(this.project$);
    this.invoiceSlideOver.new({
      project,
    });
  }
}
