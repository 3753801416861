import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { SessionStateService } from './session-state.service';

@Injectable({
  providedIn: 'root',
})
export class AccountantGuard {
  constructor(
    private router: Router,
    private sessionState: SessionStateService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.sessionState.getOrgaUserRole().pipe(
      map((role) => {
        if (role === 'accountant') {
          return this.router.createUrlTree(['/document/accounting']);
        } else {
          return true;
        }
      })
    );
  }
}
