import { Component, Inject, LOCALE_ID } from '@angular/core';
import { DocumentCardComponent } from '../../document/list/document-card/document-card.component';
import { WorkTimerComponent } from '../work-timer/work-timer.component';
import { CreateWorkComponent } from '../create-work/create-work.component';
import { filter } from 'rxjs/operators';
import { FirestoreDocument, FirestoreUser } from 'commons';
import { firstValueFrom, Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { DocumentService } from '../../../services/document.service';
import { CalendarComponent } from '../../../components/calendar/calendar/calendar.component';

@Component({
  selector: 'app-calendar-page',
  standalone: true,
  imports: [
    DocumentCardComponent,
    WorkTimerComponent,
    CreateWorkComponent,
    CalendarComponent,
    AsyncPipe,
    NgIf,
  ],
  templateUrl: './calendar.page.html',
})
export class CalendarPage {
  createWorkOnDate!: Date;
  isWorkEntryOpen = false;
  showTimer = false;

  documents?: Observable<FirestoreDocument[]>;
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private sessionState: SessionStateService,
    private documentService: DocumentService
  ) {}

  async ngOnInit() {
    const user = this.sessionState.getUser().pipe(filter((user): user is FirestoreUser => !!user));
    this.showTimer = (await firstValueFrom(user)).data.enableTimer;
    const userId = (await firstValueFrom(user)).id;
    this.documents = this.documentService.getDocumentsWithNotReactedComments(userId);
  }

  createWork(date: Date) {
    this.createWorkOnDate = date;
    this.isWorkEntryOpen = true;
  }

  closeWorkEntry() {
    this.isWorkEntryOpen = false;
  }
}
