import { ChangeDetectorRef, Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[appTailwindInput]',
  standalone: true,
})
export class TailwindInputDirective {
  _error = false;

  set error(error: boolean) {
    this._error = error;
    this.cdr.markForCheck();
  }

  @HostBinding('class') get classes() {
    const baseClasses =
      'shadow-sm focus:ring-blue-500 focus:border-blue-500 inline-block w-full sm:text-sm border-gray-300 rounded-md disabled:bg-gray-50';
    const errorClasses = 'ring-1 ring-inset ring-red-400 focus:ring-red-500 focus:border-red-500';
    return baseClasses + ' ' + (this._error ? errorClasses : '');
  }

  constructor(private cdr: ChangeDetectorRef) {}
}
