<ng-container [ngSwitch]="color">
  <span
    *ngSwitchCase="'grey'"
    class="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 whitespace-nowrap"
    >{{ displayName }}</span
  >
  <span
    *ngSwitchCase="'red'"
    class="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 whitespace-nowrap"
    >{{ displayName }}</span
  >
  <span
    *ngSwitchCase="'yellow'"
    class="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20 whitespace-nowrap"
    >{{ displayName }}</span
  >
  <span
    *ngSwitchCase="'green'"
    class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 whitespace-nowrap"
    >{{ displayName }}</span
  >
  <span
    *ngSwitchCase="'blue'"
    class="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 whitespace-nowrap"
    >{{ displayName }}</span
  >
  <span
    *ngSwitchCase="'indigo'"
    class="inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 whitespace-nowrap"
    >{{ displayName }}</span
  >
  <span
    *ngSwitchCase="'purple'"
    class="inline-flex items-center rounded-full bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10 whitespace-nowrap"
    >{{ displayName }}</span
  >
  <span
    *ngSwitchCase="'pink'"
    class="inline-flex items-center rounded-full bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10 whitespace-nowrap"
    >{{ displayName }}</span
  >
</ng-container>
