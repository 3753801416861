<ng-container *ngIf="projects$ | async as projects">
  <div class="lg:-mt-9">
    <div>
      <div class="flex space-x-4 justify-end" aria-label="Tabs">
        <button
          class="px-3 py-1 text-sm rounded-md"
          [ngClass]="
            filter$.value === 'active'
              ? 'bg-blue-100 text-blue-700'
              : 'text-gray-500 hover:text-gray-700'
          "
          (click)="filter$.next('active')">
          Aktive
        </button>

        <button
          class="px-3 py-1 text-sm rounded-md"
          [ngClass]="
            filter$.value === 'inactive'
              ? 'bg-blue-100 text-blue-700'
              : 'text-gray-500 hover:text-gray-700'
          "
          (click)="filter$.next('inactive')">
          Inaktive
        </button>

        <button
          class="px-3 py-1 text-sm rounded-md"
          [ngClass]="
            filter$.value === 'all'
              ? 'bg-blue-100 text-blue-700'
              : 'text-gray-500 hover:text-gray-700'
          "
          (click)="filter$.next('all')">
          Alle
        </button>
      </div>
    </div>
  </div>

  <div
    class="mt-2 bg-white shadow overflow-hidden sm:rounded-md"
    *ngIf="projects.length > 0; else noProjects">
    <ul>
      <li
        app-project-list-entry
        [project]="project"
        [ngClass]="{ 'border-t border-gray-200': !isFirst }"
        *ngFor="let project of projects; first as isFirst"></li>
    </ul>
  </div>
</ng-container>
<ng-template #noProjects>
  <app-empty (buttonClick)="createProject()" message="Erstelle dein erstes Projekt"></app-empty>
</ng-template>
