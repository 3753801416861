import { Component } from '@angular/core';

import { map } from 'rxjs';
import { DocumentService } from '../../services/document.service';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { SidebarNavigationEntryComponent } from '../../components/container/sidebar-navigation/sidebar-navigation-entry/sidebar-navigation-entry.component';
import { SidebarNavigationComponent } from '../../components/container/sidebar-navigation/sidebar-navigation.component';
import { TailwindButtonDirective } from '../../components/button/tailwind-button.directive';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { HeaderMetaActionComponent } from '../../components/container/header-meta-action/header-meta-action.component';
import { ContainerComponent } from '../../components/container/container.component';
import { SessionStateService } from '../../services/session-state.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  standalone: true,
  imports: [
    ContainerComponent,
    HeaderMetaActionComponent,
    NgIf,
    TailwindButtonDirective,
    SidebarNavigationComponent,
    SidebarNavigationEntryComponent,
    RouterLink,
    NgFor,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class DocumentComponent {
  archiveFolder = this.documentService.getFolders();
  isAccountant = this.sessionState.getOrgaUserRole().pipe(map((role) => role === 'accountant'));

  constructor(
    private documentService: DocumentService,
    private sessionState: SessionStateService,
    private router: Router
  ) {}

  async uploadFile(event: FileList | Event) {
    let fileList: FileList | null;

    if (event instanceof Event) {
      fileList = (event.target as HTMLInputElement).files;
    } else {
      fileList = event;
    }
    if (fileList) {
      for (const file of Array.from(fileList)) {
        await this.documentService.uploadDocument(file);
      }
      this.router.navigate(['document', 'inbox']);
    }
  }
}
