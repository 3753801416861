import { ChangeDetectorRef, NgZone, Pipe, PipeTransform } from '@angular/core';
import { differenceInSeconds } from 'date-fns';
import firebase from 'firebase/compat';

@Pipe({
    name: 'secondsPassed',
    pure: false,
    standalone: true,
})
export class SecondsPassedPipe implements PipeTransform {
  private currentTimer!: number | null;

  constructor(private cdRef: ChangeDetectorRef, private ngZone: NgZone) {}

  transform(date: firebase.firestore.Timestamp | null, ...args: unknown[]): number | null {
    if (!date) {
      return null;
    }
    this.createTimer();
    return differenceInSeconds(new Date(), date.toDate());
  }

  ngOnDestroy(): void {
    this.removeTimer();
  }

  private createTimer() {
    if (this.currentTimer) {
      return;
    }

    const timeToUpdate = 1000;

    this.currentTimer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.currentTimer = null;
          this.ngZone.run(() => this.cdRef.markForCheck());
        }, timeToUpdate);
      } else {
        return null;
      }
    });
  }

  private removeTimer() {
    if (this.currentTimer) {
      window.clearTimeout(this.currentTimer);
      this.currentTimer = null;
    }
  }
}
