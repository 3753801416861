<div class="flex items-center" *ngIf="!displayShort; else shortToggle">
  <button
    [disabled]="disabled"
    (click)="toggle()"
    type="button"
    aria-pressed="false"
    aria-labelledby="toggleLabel"
    [class]="value ? 'bg-blue-600' : 'bg-gray-200'"
    class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
    <span class="sr-only">Use setting</span>
    <span
      aria-hidden="true"
      [class]="value ? 'translate-x-5' : 'translate-x-0'"
      class="inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
  </button>
  <span class="ml-3">
    <span class="text-sm font-medium text-gray-900">{{ label }}</span>
    <span class="text-sm text-gray-500">{{ additionalLabel }}</span>
  </span>
</div>

<ng-template #shortToggle>
  <div class="flex items-center">
    <button
      [disabled]="disabled"
      (click)="toggle()"
      type="button"
      aria-pressed="false"
      class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
      <span class="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        [class]="value ? 'bg-blue-600' : 'bg-gray-200'"
        class="absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"></span>
      <span
        aria-hidden="true"
        [class]="value ? 'translate-x-5' : 'translate-x-0'"
        class="absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"></span>
    </button>
    <span class="ml-3" id="toggleLabel">
      <span class="text-sm font-medium text-gray-700">{{ label }}</span>
      <span class="text-sm text-gray-500">{{ additionalLabel }}</span>
    </span>
  </div>
</ng-template>
