import { Component, OnInit } from '@angular/core';
import { ArchiveFolder, Category, FirestoreArchive, FirestoreOrga, Orga } from 'commons';
import { firstValueFrom, Observable } from 'rxjs';
import { OrgaService } from '../../../services/orga.service';
import { DocumentService } from '../../../services/document.service';
import { filter, map } from 'rxjs/operators';
import { LastEditorService } from '../../../services/last-editor.service';
import { DocumentMailCardComponent } from './document-mail-card.component';
import { DocumentCategoryCardComponent } from './document-category-card.component';
import { DocumentFolderCardComponent } from './document-folder-card.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';

@Component({
  selector: 'app-document-settings',
  templateUrl: './document-settings.component.html',
  standalone: true,
  imports: [
    NgIf,
    DocumentFolderCardComponent,
    DocumentCategoryCardComponent,
    DocumentMailCardComponent,
    AsyncPipe,
  ],
})
export class DocumentSettingsComponent implements OnInit {
  orga$ = this.sessionState.getOrga().pipe(filter((orga): orga is FirestoreOrga => !!orga));
  hasPermission$!: Observable<boolean>;
  archive$!: Observable<FirestoreArchive>;
  categories$!: Observable<Category[]>;

  constructor(
    private orgaService: OrgaService,
    private sessionState: SessionStateService,
    private documentService: DocumentService,
    private lastEditor: LastEditorService
  ) {}

  ngOnInit(): void {
    this.categories$ = this.orga$.pipe(map((x) => Object.values(x.data.categories || {})));
    this.hasPermission$ = this.sessionState.getOrgaUserRole().pipe(map((role) => role === 'owner'));
    this.archive$ = this.documentService.getArchive();
  }

  async settingsChanged(partialOrga: Partial<Orga>) {
    const orgaId = await firstValueFrom(this.sessionState.getOrgaId());
    this.orgaService
      .update(orgaId, await this.lastEditor.hydrate(partialOrga))
      .then(() => console.log('done'));
  }

  updateArchive(event: { folders: ArchiveFolder[]; folderSort: string[] }) {
    this.documentService
      .updateArchiveFolders(event)
      .then(() => console.log('archive update done'))
      .catch((error) => console.log(error));
  }

  async updateCategories(categories: Category[]) {
    const orgaId = await firstValueFrom(this.sessionState.getOrgaId());
    const categoryRecords = categories.reduce((prev, cur) => {
      return {
        ...prev,
        [cur.id]: cur,
      };
    }, {});
    return this.orgaService.update(
      orgaId,
      await this.lastEditor.hydrate({ categories: categoryRecords })
    );
  }
}
