import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreInvoice } from 'commons';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { InvoicesComponent } from '../../../finance/invoices/invoices.component';
import { InvoiceService } from '../../../../services/invoice.service';
import { InvoiceSlideOverService } from '../../../finance/invoices/invoice-slide-over/invoice-slide-over.component';

@Component({
  selector: 'app-project-invoice',
  templateUrl: './project-invoice.component.html',
  standalone: true,
  imports: [InvoicesComponent, AsyncPipe],
})
export class ProjectInvoiceComponent {
  invoices$: Observable<FirestoreInvoice[]>;
  constructor(
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private invoiceSlideOver: InvoiceSlideOverService
  ) {
    const projectId = this.route.parent?.snapshot.params['projectId'];
    this.invoices$ = invoiceService
      .getInvoicesForProject(projectId)
      .pipe(
        map((invoices) => invoices.sort((a, b) => b.data.invoiceNumber - a.data.invoiceNumber))
      );
  }

  editInvoice(invoice: FirestoreInvoice) {
    this.invoiceSlideOver.edit(invoice);
  }

  deleteInvoice(invoice: FirestoreInvoice) {
    this.invoiceService.deleteInvoice(invoice);
  }

  downloadInvoice(invoice: FirestoreInvoice) {
    this.invoiceService.downloadInvoice(invoice);
  }
}
