import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Document, FirestoreEntity } from 'commons';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { where } from '@angular/fire/firestore';
import { DocumentListComponent } from '../../../document/list/document-list/document-list.component';
import { DocumentListItemComponent } from '../../../document/list/document-list-item/document-list-item.component';
import { EmptyComponent } from '../../../../components/empty/empty.component';
import { DocumentService } from '../../../../services/document.service';
import { DocumentSlideOverService } from '../../../document/document-slide-over/document-slide-over.component';

@Component({
  selector: 'app-project-documents',
  templateUrl: './project-documents.component.html',
  standalone: true,
  imports: [
    NgIf,
    DocumentListComponent,
    NgFor,
    DocumentListItemComponent,
    EmptyComponent,
    AsyncPipe,
  ],
})
export class ProjectDocumentsComponent {
  projectId!: string;

  documents$: Observable<FirestoreEntity<Document>[]>;

  constructor(
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private slideOver: DocumentSlideOverService
  ) {
    this.projectId = this.route.parent?.snapshot.params['projectId'];

    this.documents$ = this.documentService.getAllDocuments(
      where(`linkedEntities.${this.projectId}.id`, '==', this.projectId)
    );
  }

  editDocument(document: FirestoreEntity<Document>) {
    this.slideOver.edit([document]);
  }
}
