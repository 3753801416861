import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { allCategoryColors, Category } from 'commons';
import { TailwindButtonDirective } from '../../../components/button/tailwind-button.directive';
import { DropdownItemComponent } from '../../../components/dropdown-minimal-menu/dropdown-item/dropdown-item.component';
import { NgIconWrapperComponent } from '../../../components/icons/ng-icon-wrapper/ng-icon-wrapper.component';
import { DropdownButtonComponent } from '../../../components/dropdown-minimal-menu/dropdown-button/dropdown-button.component';
import { DropdownMinimalMenuComponent } from '../../../components/dropdown-minimal-menu/dropdown-minimal-menu.component';
import { TailwindInputDirective } from '../../../components/input/tailwind-input.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputComponent } from '../../../components/input/input.component';
import { CategoryBadgeComponent } from '../../../components/category-badge/category-badge.component';
import { NgIf, NgFor } from '@angular/common';
import { SettingsCardComponent } from '../settings-card/settings-card.component';

@Component({
    selector: 'app-document-category-card',
    templateUrl: './document-category-card.component.html',
    standalone: true,
    imports: [
        SettingsCardComponent,
        NgIf,
        NgFor,
        CategoryBadgeComponent,
        InputComponent,
        ReactiveFormsModule,
        TailwindInputDirective,
        FormsModule,
        DropdownMinimalMenuComponent,
        DropdownButtonComponent,
        NgIconWrapperComponent,
        DropdownItemComponent,
        TailwindButtonDirective,
    ],
})
export class DocumentCategoryCardComponent implements OnChanges {
  @Input()
  categories: Category[] = [];

  @Input()
  disabled = false;

  @Output()
  updateCategories = new EventEmitter<Category[]>();

  @ViewChild('inputElement')
  set inputComponent(input: ElementRef) {
    if (input) {
      setTimeout(() => input.nativeElement.select());
    }
  }

  editId: string | null = null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categories) {
      this.sort();
    }
  }

  saveSettings() {
    this.updateCategories.emit(this.categories);
  }

  addNewCategory() {
    const id = crypto.randomUUID();
    this.categories.push({
      id,
      displayName: 'neue Kategorie',
      color: allCategoryColors[Math.floor(Math.random() * allCategoryColors.length)],
    });
    this.sort();
    this.saveSettings();
  }

  deleteCategory(id: string) {
    const index = this.categories.findIndex((value) => value.id === id);
    if (index > -1) {
      this.categories.splice(index, 1);
    }
    this.saveSettings();
  }

  protected readonly allCategoryColors = allCategoryColors;

  private sort() {
    this.categories.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }

  editCategory(id: string | null) {
    this.editId = id;
    if (id === null) {
      this.sort();
      this.saveSettings();
    }
  }
}
