import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FirestoreDocument } from 'commons';
import { DocumentService } from './document.service';
import { DocumentSlideOverService } from '../pages/document/document-slide-over/document-slide-over.component';

@Injectable({
  providedIn: 'root',
})
export class DocumentHelperService {
  constructor(
    private router: Router,
    private documentService: DocumentService,
    private documentSlideOver: DocumentSlideOverService
  ) {}

  editDocument(document: FirestoreDocument): void {
    this.documentSlideOver.edit([document]);
  }

  deleteDocument(document: FirestoreDocument) {
    this.documentService.deleteDocument(document);
  }
}
