import { Component, Inject, LOCALE_ID } from '@angular/core';
import { DocumentCardComponent } from '../../document/list/document-card/document-card.component';
import { WorkTimerComponent } from '../work-timer/work-timer.component';
import { CreateWorkComponent } from '../create-work/create-work.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SessionStateService } from '../../../services/session-state.service';
import { CalendarComponent } from '../../../components/calendar/calendar/calendar.component';
import { map } from 'rxjs';
import { WorkViewComponent } from '../../projects/components/work/work-view.component';

@Component({
  selector: 'app-my-work-page',
  standalone: true,
  imports: [
    DocumentCardComponent,
    WorkTimerComponent,
    CreateWorkComponent,
    CalendarComponent,
    AsyncPipe,
    NgIf,
    WorkViewComponent,
  ],
  templateUrl: './my-work.page.html',
})
export class MyWorkPage {
  userId = this.sessionState.getOrgaUser().pipe(map((orgaUser) => orgaUser?.data.userId));
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private sessionState: SessionStateService
  ) {}

  async ngOnInit() {}
}
