import { flatten } from 'flat';
import { FirestoreEntity } from 'commons';
import { DocumentReference, QueryDocumentSnapshot } from '@angular/fire/firestore';

export function hydrate<T>(doc: QueryDocumentSnapshot<T>): FirestoreEntity<T> {
  const data = doc.data();
  const id = doc.id;
  const docRef: DocumentReference<T> = doc.ref;
  return { id, docRef, data };
}

export function flattenObject(obj: unknown, prefix?: string) {
  const flat: { [s: string]: unknown } = flatten(obj);
  return Object.fromEntries(
    Object.entries(flat).map(([key, value]) => [prefix ? `${prefix}.${key}` : key, value])
  );
}
