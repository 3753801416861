import { Component, Input } from '@angular/core';
import { PositionType } from 'commons';
import { NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
    selector: 'app-position-type-badge',
    templateUrl: './position-type-badge.component.html',
    standalone: true,
    imports: [NgSwitch, NgSwitchCase],
})
export class PositionTypeBadgeComponent {
  PositionType = PositionType;

  @Input()
  positionType!: PositionType;
}
