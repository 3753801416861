import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatMinutes',
    standalone: true,
})
export class FormatMinutesPipe implements PipeTransform {
  transform(inputMinutes: number, ...args: unknown[]): string {
    const hours = Math.floor(inputMinutes / 60);
    const minutes = inputMinutes % 60;

    return (hours ? `${hours}h ` : '') + (minutes ? `${minutes}m` : '');
  }
}
