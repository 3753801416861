import { Component } from '@angular/core';
import { FirestoreOrga } from 'commons';
import { filter } from 'rxjs';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { SessionStateService } from '../../services/session-state.service';

@Component({
  selector: 'app-subscription-pending',
  templateUrl: './subscription-pending.component.html',
  standalone: true,
})
export class SubscriptionPendingComponent {
  constructor(
    private sessionState: SessionStateService,
    private router: Router
  ) {
    this.sessionState
      .getOrga()
      .pipe(
        filter((orga): orga is FirestoreOrga => !!orga),
        filter(
          (orga): orga is FirestoreOrga =>
            !!orga.data.subscription &&
            (orga.data.subscription.status === 'active' ||
              orga.data.subscription.status === 'trialing')
        ),

        first()
      )
      .subscribe(() => this.router.navigateByUrl('/'));
  }
}
