import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-sidebar-navigation',
    templateUrl: './sidebar-navigation.component.html',
    standalone: true,
    imports: [NgIf],
})
export class SidebarNavigationComponent {
  @Input()
  heading = '';
}
