<a
  [routerLink]="customer.id"
  class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
  <div class="px-4 py-4 flex items-center gap-2 sm:px-6">
    <div class="text-blue-400">
      <app-ng-icon
        *ngIf="customer.data.type === 'company'; else person"
        class="text-lg"
        name="heroBuildingOfficeSolid"></app-ng-icon>
      <ng-template #person>
        <app-ng-icon class="text-lg" name="heroUserSolid"></app-ng-icon>
      </ng-template>
    </div>
    <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <div class="text-sm leading-5 font-medium text-blue-600 truncate">
          {{ customer.data.displayName }}
          <span class="ml-1 font-normal text-gray-500">
            {{ customer.data.address.city }}
          </span>
        </div>
      </div>
    </div>
    <div class="ml-5 flex-shrink-0">
      <app-ng-icon
        class="text-gray-400 text-sm"
        strokeWidth="3"
        name="heroChevronRight"></app-ng-icon>
    </div>
  </div>
</a>
